import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
// import { ViewOrderSummaryService } from "../../../../Services/OrderServices";
import Swal from "sweetalert2";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { CTAButton } from "../../../../Components/CTAButton";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { useReactToPrint } from "react-to-print";

export const ViewOrderSummaryPage = () => {
  const location = useLocation();

  const componentRef = React.useRef();

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const { postRequest } = ApiResponseHook();

  useEffect(() => {
    getOrderSummaryData();
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const printOrderSummary = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "orders",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const getOrderSummaryData = async () => {
    let payload = {
      from: location?.state?.data?.from,
      to: location?.state?.data?.to,
    };

    let res = await postRequest(configURL.view_order_summary, payload);

    let reversedData = res.data.data?.sort((a, b) => b.orderno - a.orderno);

    if (res.status === 201 || res.status === 200) {
      // setData(filteredData);

      const filteredData = reversedData.filter((item) => {
        return item.status === "1";
      });
      setData(filteredData);
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404 ||
      res?.response?.status === 500
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  // const printOrderSummary = () => {
  //   // Get the content of the div with the id "toprintscreen"
  //   var printContent = document.getElementById("ordersummary").innerHTML;

  //   // Create a new window for printing
  //   var printWindow = window.open("", "", "width=600,height=600");

  //   // Write the content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write("<html><head><title>Print</title></head><body>");
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   printWindow.print();

  //   printWindow.close();
  // };

  const getOnlyDateFromDateTime = (date_) => {
    const parts = date_.split(" ");

    const datePart = parts[0];

    return datePart;
  };

  return (
    <>
      <div>
        <BreadCrumb
          routename="View Orders"
          isBack={true}
          gotoBackFun={gotoback}
        />
      </div>

      <div className="ordersummary" id="ordersummary">
        <h5 className="my-4">View Orders</h5>

        <table ref={componentRef}>
          <thead>
            <tr>
              <th>Customer Number</th>
              <th>Email</th>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Total Items</th>
              <th>Grand Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{item?.custno}</td>
                <td>{item?.email}</td>
                <td>{item?.orderno}</td>
                <td> {getOnlyDateFromDateTime(item?.ordate)}</td>
                <td>{item?.totalitem}</td>
                <td>£{item?.grand_total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="my-2">
        <CTAButton
          name="Print"
          handleClick={printOrderSummary}
          id="submitbtn"
        />
      </div>
    </>
  );
};
