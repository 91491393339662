import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../Assets/Styles/common.scss";
import {
  AddUpdateContactUsService,
  GetAllContactUsService,
} from "../../../Services/CMServices";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const ContactUsAndTerms = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  const [contactUs, setContactUs] = useState([]);
  const [contactForm, setContactForm] = useState({
    contact_info: "",
    terms_conditions: "",
  });

  const [contactInfo, setContactInfo] = useState("");
  const [termsConditions, setTermsConditions] = useState("");

  useEffect(() => {
    getcontactDetails();
  }, []);

  const getcontactDetails = async () => {
    // let res = await GetAllContactUsService();
    let res = await getRequest(configURL.get_all_contact_details);
    if (res?.status === 200 || res?.status === 201) {
      setContactUs(res?.data?.data);
      setContactInfo(res?.data?.data[0]?.contact_info);
      setTermsConditions(res?.data?.data[0]?.terms_conditions);
      setContactForm({
        contact_info: res?.data?.data[0]?.contact_info,
        terms_conditions: res?.data?.data[0]?.terms_conditions,
      });
    } else {
      console.log("error getting the response for contact us", res);
    }
  };

  const handleUpdateTermsAndConditions = async () => {
    let formData = new FormData();

    formData.append("contact_info", contactInfo);
    formData.append("terms_conditions", termsConditions);
    // let res = await AddUpdateContactUsService(formData);
    let res = await postRequest(configURL.add_contactus_terms, formData);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the contact us and terms and conditions`,
      });
      getcontactDetails();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      let errorObj = res?.response?.data?.error;

      const firstValue = errorObj[Object.keys(errorObj)[0]];
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error : ${firstValue}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Contact Us and Terms and Conditions" />
      </div>

      <div className="my-3">
        <h5 className="subHeading">Contact Us and Terms and Conditions</h5>
      </div>

      <div className="mt-3">
        <label htmlFor="">Contact Us</label>
        <CKEditor
          editor={ClassicEditor}
          // data={contactUs[0]?.contact_info}
          // data={contactForm?.contact_info}

          data={contactInfo}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          // onChange={(event, editor) => {
          //   const data = editor.getData();
          //   setContactForm({
          //     ...contactForm,
          //     contact_info: data,
          //   });
          //   // console.log({ event, editor, data });
          // }}

          onChange={(event, editor) => {
            const data = editor.getData();
            setContactInfo(data);
            // console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            // console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            // console.log("Focus.", editor);
          }}
        />
      </div>

      <div className="mt-3">
        <label htmlFor="">Terms and Conditions</label>
        <CKEditor
          editor={ClassicEditor}
          // data={contactUs[0]?.terms_conditions}
          // data={contactForm?.terms_conditions}

          data={termsConditions}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          // onChange={(event, editor) => {
          //   const data = editor.getData();
          //   setContactForm({
          //     ...contactForm,
          //     terms_conditions: data,
          //   });
          //   // console.log({ event, editor, data });
          // }}

          onChange={(event, editor) => {
            const data = editor.getData();
            setTermsConditions(data);
          }}
          onBlur={(event, editor) => {
            // console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            // console.log("Focus.", editor);
          }}
        />
      </div>

      <div className="buttonsDiv mt-3">
        <button className="editbutton" onClick={handleUpdateTermsAndConditions}>
          Submit
        </button>
      </div>
    </div>
  );
};
