import {
  getRequest,
  postRequest,
  putRequest,
  deleteReqest,
  patchRequest,
} from "./MainBaseServices";

import axios from "axios";

import { configURL } from "./configuration";

export const GetAllCategoriesService = async () => {
  try {
    let response = await getRequest(configURL.get_all_categories);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:20 ~ GetAllCategoriesService ~ error:",
      error
    );
    return error;
  }
};

// get all groups based on categories
export const GetAllGroupsBasedOnCategories = async (id) => {
  try {
    let response = await getRequest(
      `${configURL.get_all_groups_basedon_categories}/${id}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:31 ~ GetAllGroupsBasedOnCategories ~ error:",
      error
    );
    return error;
  }
};

// get all subcategories based on the group/category

export const GetAllSubcategoriesBasedOnGroupCategory = async (id) => {
  try {
    let response = await getRequest(
      `${configURL.get_allsubcategories_basedon_group}/${id}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:49 ~ GetAllSubcategoriesBasedOnGroupCategory ~ error:",
      error
    );
    return error;
  }
};

export const EditCategoryService = async (payload) => {
  try {
    let response = await postRequest(configURL.edit_category_data, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:31 ~ EditCategoryService ~ error:",
      error
    );
    return error;
  }
};

export const DeleteCategoryService = async (id) => {
  try {
    let response = await getRequest(`${configURL.delete_category}/${id}/`);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:43 ~ DeleteCategoryService ~ error:",
      error
    );
    return error;
  }
};

export const AddNewCategory = async (payload) => {
  try {
    let response = await postRequest(configURL.add_new_category, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:56 ~ AddNewCategory ~ error:",
      error
    );
    return error;
  }
};

export const GetAllGroupService = async () => {
  try {
    let response = await getRequest(configURL.get_all_groups);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:69 ~ GetAllGroupService ~ error:",
      error
    );
    return error;
  }
};

export const DeleteGroupFromCategoryService = async (catId, groupName) => {
  // console.log("🚀 ~ file: CategoryServices.js:79 ~ DeleteGroupFromCategoryService ~ groupName:", groupName)
  // console.log("🚀 ~ file: CategoryServices.js:79 ~ DeleteGroupFromCategoryService ~ catId:", catId)
  try {
    let response = await getRequest(
      `${configURL.delete_group}/${catId}/${groupName}/`
    );

    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:82 ~ DeleteGroupFromCategoryService ~ error:",
      error
    );
    return error;
  }
};

export const AddNewGroupService = async (payload) => {
  try {
    let response = await postRequest(configURL.add_new_group, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:100 ~ AddNewGroupService ~ error:",
      error
    );
  }
};

export const AddNewSubCategoriesService = async (payload) => {
  try {
    let response = await postRequest(configURL.add_new_subCategory, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:112 ~ AddNewSubCategoriesService ~ error:",
      error
    );
    return error;
  }
};

export const GetAllSubCategories = async () => {
  try {
    let response = await getRequest(configURL.get_all_subCategory);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:125 ~ GetAllSubCategories ~ error:",
      error
    );
    return error;
  }
};

export const EditSubCategoryService = async (payload) => {
  try {
    let response = await postRequest(configURL.edit_sub_category, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:138 ~ EditSubCategoryService ~ error:",
      error
    );
    return error;
  }
};

export const DeleteSubCategoriesService = async (id) => {
  try {
    // let response = await getRequest(`${configURL}/${id}/`);
    let response = await getRequest(`${configURL.delete_subCategories}/${id}/`);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:151 ~ DeleteSubCategories ~ error:",
      error
    );
    return error;
  }
};

// !!!!!!!!!!! get all hidden subcategories !!!!!!!!!!!!!

export const GetAllHiddenSubcategories = async (catid, groupname) => {
  try {
    let response = await getRequest(
      `${configURL.get_all_hiddensubcategories_lsit}/${catid}/${groupname}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:201 ~ GetAllHiddenSubcategories ~ error:",
      error
    );
    return error;
  }
};

// !!!!!!!!!!!!! get groups list to add to the categoriies back !!!!!!!!!!!!!!!

export const GetAllListOfGroupsToAddToCategory = async (id) => {
  try {
    let response = await getRequest(
      `${configURL.get_all_groups_toadd_category_list}/${id}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:218 ~ GetAllListOfGroupsToAddToCategory ~ error:",
      error
    );
    return error;
  }
};

// !!!!!!!!!!!!!!! show subcategories to categories !!!!!!!!!!!!

export const ShowSubcategoriesToCategories = async (payload) => {
  try {
    let response = await postRequest(
      configURL.show_subcategories_to_categories,
      payload
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:235 ~ ShowSubcategoriesToCategories ~ error:",
      error
    );
    return error;
  }
};

// !!!!!!!!!!!!!!!!!!!!!!!! hide sub categories to categories !!!!!!!!!!!!!!

export const HideSubcategoriesToCategoriesService = async (payload) => {
  try {
    let response = await postRequest(
      configURL.hide_subcategory_to_categories,
      payload
    );

    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:253 ~ HideSubcategoriesToCategoriesService ~ error:",
      error
    );
    return error;
  }
};

// !!!!!!!!!!!!!!! add group to cat !!!!!!!!!!!!!

export const AddGroupToCategoryService = async (catid, groupName) => {
  try {
    let response = await getRequest(
      `${configURL.add_group_to_category}/${catid}/${groupName}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:272 ~ AddGroupToCategoryService ~ error:",
      error
    );
    return error;
  }
};

export const GetAllSubcategoriesBasedOnGroupIdAndCategoryId = async (
  catid,
  groupid
) => {
  try {
    let response = await getRequest(
      `${configURL.get_all_subcategories_pm}/${catid}/${groupid}/`
    );

    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: CategoryServices.js:287 ~ GetAllSubcategoriesBasedOnGroupIdAndCategoryId ~ error:",
      error
    );
    return error;
  }
};
