import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import "./styles.scss";
import { useFetcher, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import Chart from "react-apexcharts";
import {
  CategoryPerformanceByItemSoldService,
  GetCategoryPerformanceBySaleService,
  // GetGraphsForOrderByMonth,
} from "../../../../Services/OrderServices";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { configURL } from "../../../../Services/configuration";

export const GraphicalSalesAnalysis = ({ toshowBreadcrumb }) => {
  const { getRequest } = ApiResponseHook();

  const navigate = useNavigate();
  const gotoOrders = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  const [monthsGraphsData, setMonthsGraphsData] = useState({
    months: [],
    vales: [],
  });

  const chartstate = {};
  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "No. orders",
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getAllGraphsByMonth();
  }, []);

  const getAllGraphsByMonth = async () => {
    const labelsArray = [];
    const valuesArray = [];
    // let res = await GetGraphsForOrderByMonth();
    let res = await getRequest(configURL.get_all_graphs_by_month);

    if (res?.status === 200 || res?.status === 201) {
      res?.data?.data?.forEach((item) => {
        labelsArray.push(item.labels);
        valuesArray.push(item.values);
      });
      setState({
        ...state,
        options: {
          xaxis: {
            categories: labelsArray,
          },
        },
        series: [
          {
            data: valuesArray,
          },
        ],
      });

      setMonthsGraphsData({
        ...monthsGraphsData,
        months: labelsArray,
        vales: valuesArray,
      });
    } else {
      console.log("error getting all the graphs====>", res);
    }
  };

  return (
    <div className="graphicalMainDiv">
      {toshowBreadcrumb === "false" && <></>}
      {toshowBreadcrumb !== "false" && (
        <>
          <div className="breadcrumbDiv">
            <BreadCrumb
              routename="Graphical Sales Analysis"
              isBack={true}
              gotoBackFun={gotoOrders}
            />
          </div>
        </>
      )}

      {/* cahrts div */}
      <div className="mt-4">
        <h6>Graph showing number of orders by month.</h6>
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="100%"
            height="300"
          />
        </div>
      </div>

      {/* catgeory performance by items sold.. */}
      <div className="my-2">
        <CategoryPerformanceComponent />
      </div>

      {/* category performance by sales */}

      <div className="my-2">
        <CatgoryPerformanceBySalesComponent />
      </div>
    </div>
  );
};

const CategoryPerformanceComponent = () => {
  const { getRequest } = ApiResponseHook();

  const [state, setState] = useState({
    series: [
      {
        name: "Actual",
        data: [],
      },
      {
        name: "Expected",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "bar",
      },
      xaxis: {
        labels: {
          rotate: -20, // Rotate the labels if needed (e.g., 45 degrees)
          trim: false, // Prevent label text trimming
          style: {
            fontSize: "12px", // Adjust font size as needed
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#00E396", "#775DD0"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual", "Expected"],
        markers: {
          fillColors: ["#00E396", "#775DD0"],
        },
      },
      yaxis: {
        title: {
          text: "Number of items sold",
        },
      },
    },
  });

  useEffect(() => {
    getCatgoryDataByItemSold();
  }, []);

  const getCatgoryDataByItemSold = async () => {
    // let res = await CategoryPerformanceByItemSoldService();
    let res = await getRequest(configURL.category_performance_items_sold);
    if (res?.status === 200 || res?.status === 201) {
      const actualData = [];
      const expectedData = [];

      res?.data?.data?.forEach((item) => {
        actualData.push({
          x: item.category_name,
          y: item.item_count_now,
        });

        expectedData.push({
          x: item.category_name,
          y: item.item_count,
        });
      });

      setState((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: actualData,
          },
          {
            ...prevState.series[1],
            data: expectedData,
          },
        ],
      }));
    } else {
      console.log("error getting the categories data---->", res);
    }
  };

  return (
    <>
      <div>
        <h6>Graph for category performance by items sold</h6>
        <Chart
          options={state.options}
          series={state.series}
          type="bar"
          height={500}
        />
      </div>
    </>
  );
};

const CatgoryPerformanceBySalesComponent = () => {
  const { getRequest } = ApiResponseHook();

  const [state, setState] = useState({
    series: [
      {
        name: "Actual",
        data: [],
      },
      {
        name: "Expected",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      xaxis: {
        labels: {
          rotate: -20, // Rotate the labels if needed (e.g., 45 degrees)
          // trim: false, // Prevent label text trimming
          style: {
            fontSize: "12px", // Adjust font size as needed
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#00E396", "#775DD0"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual", "Expected"],
        markers: {
          fillColors: ["#00E396", "#775DD0"],
        },
      },
      yaxis: {
        title: {
          text: "Total value",
        },
      },
    },
  });

  useEffect(() => {
    getCategoryBySales();
  }, []);

  const getCategoryBySales = async () => {
    // let res = await GetCategoryPerformanceBySaleService();
    let res = await getRequest(configURL.category_performance_by_sales);

    if (res?.status === 200 || res?.status === 201) {
      const actualData = [];
      const expectedData = [];

      res?.data?.data?.forEach((item) => {
        actualData.push({
          x: item.category_name,
          y: item.price_count_now[0], // Accessing the first element of the array
        });

        expectedData.push({
          x: item.category_name,
          y: item.price_count[0], // Accessing the first element of the array
        });
      });

      setState((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: actualData,
          },
          {
            ...prevState.series[1],
            data: expectedData,
          },
        ],
      }));
    } else {
      console.log("error getting the response", res);
    }
  };

  return (
    <>
      <div>
        <h6>Graphs for category perfomance by sales</h6>
        <Chart
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
};
