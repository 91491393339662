import booksimage from "./books.jpg";
import backgroundImg from "./background.jpg";
import downarrow from "./down-arrow.png";
import uparrow from "./up-arrow.png";
import back from "./back.png";
import editicon from "./editIcon.png";
import deleteIcon from "./deleteicon.png";
import stockBook from "./bookOne.jpg";
import hide from "./private.png";
import show from "./view.png";
import cancel from "./cancel.png";
import more from "./more.png";
import profile from "./profile.png";

export const Images = {
  booksimage: booksimage,
  background: backgroundImg,
  downarrow: downarrow,
  uparrow: uparrow,
  back: back,
  editIcon: editicon,
  deleteIcon: deleteIcon,
  stockBook: stockBook,
  hide: hide,
  show: show,
  cancel: cancel,
  more: more,
  profile: profile,
};
