import React, { useMemo } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";

import "./styles.scss";
import { GlobalFilter } from "../../../../../Utils/Table/GlobalFilter";
import { Images } from "../../../../../Assets/Images/imageslist";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteStrings } from "../../../../../Routes/RouteStrings";

function parseJsonDate(jsonDateString) {
  let dateNumber = Number(jsonDateString);

  let newDate = Date(dateNumber);

  let _date = convert(newDate);

  return _date;
}

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}

export const TransactionTable = ({ transactiondata }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Date",

        accessor: (d) => {
          if (d.trantime) {
            return parseJsonDate(d.trantime);
          } else {
            return `---`;
          }
        },
      },
      {
        Header: "Transaction Result",
        accessor: "status",
      },

      {
        Header: "Card Type",
        accessor: "cardtype",
      },
      {
        Header: "Postage",
        // accessor: "postage",
        accessor: (d) => {
          if (d.postage) {
            return `£${d.postage}`;
          } else {
            return `---`;
          }
        },
      },
      {
        Header: "Price Adjustment",
        // accessor: "adjustment",
        accessor: (d) => {
          if (d.adjustment) {
            return `£${d.adjustment}`;
          } else {
            return `---`;
          }
        },
      },

      {
        Header: "Amount Charged",
        accessor: (d) => {
          if (d.amount) {
            return `£${d.amount}`;
          } else {
            return `---`;
          }
        },
      },
      {
        Header: "Total Amount",
        // accessor: "total",
        accessor: (d) => {
          if (d.total) {
            return `£${d.total}`;
          } else {
            return `---`;
          }
        },
      },
      {
        Header: "Transaction Reference",
        accessor: "txcode",
      },
      {
        Header: "Authorization Code",
        accessor: "authno",
      },
      {
        Header: "Address Check",
        accessor: "address",
      },

      {
        Header: "CVC Check",
        accessor: "cv2",
      },

      {
        Header: "3d Secure Check",
        accessor: "3dstatus",
      },
    ],
    []
  );
  // const data = useMemo(() => props.data);
  const data = useMemo(() => transactiondata);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;

  return (
    <>
      {/* <div className="mt-3 mb-3">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div> */}
      <div className="table_overflow">
        <table {...getTableProps()} className="mt-3 table_">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={columnIndex === 1 ? "second-column-class" : ""}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Images.downarrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        ) : (
                          <img
                            src={Images.uparrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {transactiondata.length === 0 ? (
            <tbody>
              <tr>No transaction details</tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, columnIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            columnIndex === 1 ? "second-column-class" : ""
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {/* <div className="d-flex align-items-center mb-3">
          <div>
            <select
              value={pageSize}
              className="selectTag mb-3"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="" disabled>
                Select
              </option>
              {[5, 10, 15, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="w-100 text-center mt-3 mb-1">
            <button
              className="mx-1 skipToBtn"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="mx-1 actionBtn"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <span className="mx-2 pageNumber">
              <strong>{pageIndex + 1}</strong>{" "}
            </span>
            <button
              className="mx-1 actionBtn"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="skipToBtn"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};
