// import React, { useEffect, useRef, useState } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import Swal from "sweetalert2";
// // import {
// //   AddUpdateSubCategoryContent,
// //   GetAllSubcategoriesContentService,
// // } from "../../../../Services/CMServices";
// import { configURL } from "../../../../Services/configuration";
// import { ApiResponseHook } from "../../../../Services/MainBaseServices";
// import { Test } from "./Test";

// export const EditFormSubCatgories = ({ categoryId, subcategoryId, group }) => {
//   const { getRequest, postRequest } = ApiResponseHook()

//   const [subCategorydata, setSubCategorydata] = useState({
//     header: "",
//     footer: "",
//     meta: "",
//     visible: "",
//   });
//   useEffect(() => {
//     getsubcategoriescontent();
//   }, [subcategoryId]);


//   const getsubcategoriescontent = async () => {
//     // let res = await GetAllSubcategoriesContentService(
//     //   categoryId,
//     //   subcategoryId
//     // );
//     let res = await getRequest(
//       `${configURL.get_all_subcategories_content}/${categoryId}/${subcategoryId}/`
//     );

//     if (res?.status === 200 || res?.status === 201) {
//       setSubCategorydata({
//         ...subCategorydata,
//         header: res?.data?.data[0]?.header,
//         footer: res?.data?.data[0]?.footer,
//         meta: res?.data?.data[0]?.meta,
//         visible: res?.data?.data[0]?.visible,
//       });
//     } else {
//       setSubCategorydata({
//         ...subCategorydata,
//         header: "",
//         footer: "",
//         meta: "",
//         visible: "",
//       });
//     }
//   };

//   const handleRadio = (e) => {
//     // const newValue = e.target.value;
//     // setCheckData([{ visible: newValue }]);

//     setSubCategorydata({
//       ...subCategorydata,
//       visible: e.target.value,
//     });
//   };

//   const handleMetatags = (e) => {
//     setSubCategorydata({
//       ...subCategorydata,
//       meta: e.target.value,
//     });
//   };

//   const addUpdateSubcategoryData = async () => {
//     // console.log("the edited sub category data", subCategorydata);

//     let formData = new FormData();

//     formData.append("catnumber", categoryId);
//     formData.append("group", group);
//     formData.append("subcategory", subcategoryId);
//     formData.append("header", subCategorydata.header);
//     formData.append("footer", subCategorydata.footer);
//     formData.append("meta", subCategorydata.meta);
//     formData.append("visible", subCategorydata.visible);

//     // let res = await AddUpdateSubCategoryContent(formData);
//     let res = await postRequest(
//       configURL.add_update_subcategory_content,
//       formData
//     );

//     if (res.status === 201 || res.status === 200) {
//       Swal.fire({
//         icon: "success",
//         title: "Success",
//         text: `Added the subcategory data`,
//       });
//       getsubcategoriescontent();
//     } else if (
//       res.response.status === 400 ||
//       res.response.status === 401 ||
//       res.response.status === 404
//     ) {
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: `Error ${res.response?.data?.message}`,
//       });
//       console.log("error", res);
//     } else {
//       console.log("errror", res);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: `Error`,
//       });
//     }
//   };

//   return (
//     <div>
//       <div>
//         <div className="my-2">
//           <h5 className="subHeading">Edit Page Layout</h5>
//         </div>
//         <p>Currently Editing the layout for </p>

//         <div className="editedableDiv">
//           <div className="headerdiv w-75">
//             <h5 className="subHeading">Headers</h5>

//             <CKEditor
//               editor={ClassicEditor}
//               data={subCategorydata.header || ""}
//               onReady={(editor) => {
//                 // You can store the "editor" and use when it is needed.
//                 // console.log("Editor is ready to use!", editor);
//               }}
//               onChange={(event, editor) => {
//                 const data = editor.getData();
//                 setSubCategorydata({
//                   ...subCategorydata,
//                   header: data,
//                 });
//                 // console.log({ event, editor, data });
//               }}
//               onBlur={(event, editor) => {
//                 // console.log("Blur.", editor);
//               }}
//               onFocus={(event, editor) => {
//                 // console.log("Focus.", editor);
//               }}
//             />
//           </div>
//           <div className="footerdiv my-2 w-75">
//             <h5 className="subHeading">Footer</h5>

//             <CKEditor
//               editor={ClassicEditor}
//               data={subCategorydata?.footer || ""}
//               onReady={(editor) => {
//                 // You can store the "editor" and use when it is needed.
//                 // console.log("Editor is ready to use!", editor);
//               }}
//               onChange={(event, editor) => {
//                 const data = editor.getData();
//                 setSubCategorydata({
//                   ...subCategorydata,
//                   footer: data,
//                 });
//                 // console.log({ event, editor, data });
//               }}
//               onBlur={(event, editor) => {
//                 // console.log("Blur.", editor);
//               }}
//               onFocus={(event, editor) => {
//                 // console.log("Focus.", editor);
//               }}
//             />
//           </div>

//           <div className="metatagdiv">
//             <h5 className="subHeading mb-2">Metatags</h5>

//             <textarea
//               name="meta"
//               id="meta"
//               cols="30"
//               rows="10"
//               onChange={handleMetatags}
//               value={subCategorydata?.meta || ""}
//               className="inp w-75 p-2"
//             ></textarea>
//           </div>
//         </div>

//         <p className="w-75">
//           Enter a list of words or phrases, separated by a comma and space,
//           which describe this page (recommended maximum words or phrases is 30).
//           This information is used by search engines when they index your page.
//           Having meta tags that are repeated in the text of the page will help
//           your page get higher up search engine results.
//         </p>

//         <div className="my-2">
//           <div class="form-check">
//             <input
//               className="form-check-input"
//               type="radio"
//               name="exampleRadios"
//               id="exampleRadios1"
//               onChange={(e) => handleRadio(e)}
//               checked={subCategorydata.visible === "1"}
//               value="1"
//             />
//             <label class="form-check-label" for="exampleRadios1">
//               Show all categories listing
//             </label>
//           </div>
//           <div class="form-check">
//             <input
//               className="form-check-input"
//               type="radio"
//               name="exampleRadios"
//               id="exampleRadios1"
//               onChange={(e) => handleRadio(e)}
//               checked={subCategorydata.visible === "2"}
//               value="2"
//             />
//             <label class="form-check-label" for="exampleRadios1">
//               Show newest 5 category listing
//             </label>
//           </div>
//           <div class="form-check">
//             <input
//               className="form-check-input"
//               type="radio"
//               name="exampleRadios"
//               id="exampleRadios1"
//               onChange={(e) => handleRadio(e)}
//               checked={subCategorydata.visible === "3"}
//               value="3"
//             />
//             <label class="form-check-label" for="exampleRadios1">
//               Hide category listing
//             </label>
//           </div>

//           <button
//             className="btn editbutton my-2"
//             onClick={addUpdateSubcategoryData}
//           >
//             Finished
//           </button>
//         </div>
//       </div>
//       {/* <Test /> */}
//     </div>
//   );
// };


import React, { useEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { configURL } from '../../../../Services/configuration';
import { ApiResponseHook } from '../../../../Services/MainBaseServices';
import Swal from "sweetalert2";

export const EditFormSubCatgories = (props) => {
  const { getRequest, postRequest } = ApiResponseHook()

  const { categoryId, subcategoryId, group, isCategory } = props
  const [editorContent, setEditorContent] = useState({
    header: "",
    footer: ""
  });
  const [subCategorydata, setSubCategorydata] = useState({
    meta: "",
    visible: "",
  });
  const editorRefs = useRef({});


  useEffect(() => {
    if (isCategory)
      getallcategoriescontent(categoryId)
    else
      getsubcategoriescontent()
  }, [subcategoryId, categoryId]);

  const getsubcategoriescontent = async () => {
    let res = await getRequest(
      `${configURL.get_all_subcategories_content}/${categoryId}/${subcategoryId}/`
    );

    if (res?.status === 200 || res?.status === 201) {
      setEditorContent({
        header: res?.data?.data[0]?.header || '',
        footer: res?.data?.data[0]?.footer || '',
      });
      setSubCategorydata({
        meta: res?.data?.data[0]?.meta,
        visible: res?.data?.data[0]?.visible,
      })
    } else {
      setEditorContent({
        header: "",
        footer: "",
      });
      setSubCategorydata({
        meta: "",
        visible: ""
      })
    }
  };

  const getallcategoriescontent = async (id) => {
    let res = await getRequest(
      `${configURL.get_all_category_content}/${id}/`
    );

    if (res?.status === 200 || res?.status === 201) {
      const respData = res?.data?.data[0];

      setEditorContent({
        header: respData?.category_description || "",
        footer: respData?.category_footer || ""
      });
      setSubCategorydata({
        meta: respData?.meta,
        visible: respData?.visible,
      })
    } else {
      setEditorContent({
        header: "",
        footer: ""
      });
      setSubCategorydata({
        meta: "",
        visible: "",
      })
    }
  };

  const handleEditorChange = (editor, editorName) => {
    const data = editor.getData();
    setEditorContent(prevContent => ({
      ...prevContent,
      [editorName]: data
    }));
  };

  const editors = Object.keys(editorContent).map(editorName => (
    <div key={editorName} className='mb-3'>
      <h5 className='text-capitalize mb-2'>{editorName}</h5>
      <CKEditor
        editor={ClassicEditor}
        data={editorContent[editorName]}
        onChange={(event, editor) => handleEditorChange(editor, editorName)}
        ref={editor => (editorRefs.current[editorName] = editor)}
      />
    </div>
  ));

  const handleRadio = (e) => {
    // const newValue = e.target.value;
    // setCheckData([{ visible: newValue }]);

    setSubCategorydata({
      ...subCategorydata,
      visible: e.target.value,
    });
  };

  const handleMetatags = (e) => {
    setSubCategorydata({
      ...subCategorydata,
      meta: e.target.value,
    });
  };

  const addUpdateSubcategoryData = async () => {
    // console.log("the edited sub category data", subCategorydata);
    let formData = new FormData();
    formData.append("catnumber", categoryId);
    formData.append("group", group);
    formData.append("subcategory", subcategoryId);
    formData.append("header", editorContent.header);
    formData.append("footer", editorContent.footer);
    formData.append("meta", subCategorydata.meta);
    formData.append("visible", subCategorydata.visible);

    // let res = await AddUpdateSubCategoryContent(formData);
    let res = await postRequest(
      configURL.add_update_subcategory_content,
      formData
    );

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Added the subcategory data`,
      });
      getsubcategoriescontent();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      console.log("error", res);
    } else {
      console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const editCategoryInfo = async () => {

    let formData = new FormData();

    formData.append("catnumber", categoryId);
    formData.append("header", editorContent.header);
    formData.append("footer", editorContent.footer);
    formData.append("meta", subCategorydata.meta);
    formData.append("visible", subCategorydata.visible);

    // let res = await AddUpdateCategoryInfoService(formData);
    let res = await postRequest(configURL.edit_category_info, formData);


    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the category data`,
      });
      getallcategoriescontent();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      console.log("error", res);
    } else {
      console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  return (
    <>{categoryId &&
      <div className='mt-5'>
        <>{isCategory ? <div className="mb-3">
          <h5 className="subHeading">Edit Page Layout</h5>
        </div> : null}</>
        <div className=''>{editors}</div>
        <div className="metatagdiv">
          <h5 className="subHeading mb-2">Metatags</h5>

          <textarea
            name="meta"
            id="meta"
            cols="30"
            rows="10"
            onChange={handleMetatags}
            value={subCategorydata?.meta || ""}
            className="inp w-75 p-2"
          ></textarea>
        </div>
        <p className="w-75">
          Enter a list of words or phrases, separated by a comma and space,
          which describe this page (recommended maximum words or phrases is 30).
          This information is used by search engines when they index your page.
          Having meta tags that are repeated in the text of the page will help
          your page get higher up search engine results.
        </p>

        <div className="my-2">
          <div class="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              onChange={(e) => handleRadio(e)}
              checked={subCategorydata.visible === "1"}
              value="1"
            />
            <label class="form-check-label" for="exampleRadios1">
              Show all categories listing
            </label>
          </div>
          <div class="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              onChange={(e) => handleRadio(e)}
              checked={subCategorydata.visible === "2"}
              value="2"
            />
            <label class="form-check-label" for="exampleRadios1">
              Show newest 5 category listing
            </label>
          </div>
          <div class="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              onChange={(e) => handleRadio(e)}
              checked={subCategorydata.visible === "3"}
              value="3"
            />
            <label class="form-check-label" for="exampleRadios1">
              Hide category listing
            </label>
          </div>

          <button
            className="btn editbutton my-2"
            onClick={isCategory ? () => editCategoryInfo() : () => addUpdateSubcategoryData()}
          >
            Finished
          </button>
        </div>
      </div>}
    </>
  )
};