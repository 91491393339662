import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { configURL } from "../../../../Services/configuration";
import "./styles.scss";
import { GlobalFilter } from "../../../../Utils/Table/GlobalFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
// import {
//   DeleteCustomerService,
//   SearchCustomerService,
// } from "../../../../Services/CustomerServices";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { Images } from "../../../../Assets/Images/imageslist";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";

function ServerSidePaginationTable({ fetchCustomersData, getCustomersList }) {
  const inpRef = useRef();
  const { getRequest, postRequest } = ApiResponseHook()

  const location = useLocation();
  const navigate = useNavigate();

  // !STATES
  const [deleteData, setDeleteData] = useState({});
  const [query, setQuery] = useState("");

  const [searchText, setSearchText] = useState("");

  // Define columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Customer No",
        accessor: "custno",
      },
      {
        Header: "Name",
        accessor: (d) => {
          if (d.name === "") {
            return `No Name`;
          } else {
            return `${d.name}`;
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Country",
        accessor: "country_name",
      },
      {
        Header: "Last Visit",
        accessor: (d) => {
          if (
            d.lastvisit === null ||
            d.lastvisit === undefined ||
            d.lastvisit === ""
          ) {
            return `-`;
          } else {
            return `${d.lastvisit}`;
          }
        },
      },
      {
        Header: "Total Spend",
        accessor: (d) => {
          const tempspend = parseFloat(d.tempspend); // Convert the string to a number
          if (!isNaN(tempspend)) {
            return `£${tempspend.toFixed(2)}`;
          } else {
            return `--`;
          }
        },
      },
      {
        Header: "Status",
        accessor: (d) => {
          if (d.status === "1") {
            return `Active`;
          } else {
            return `Inactive`;
          }
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (tableProps) => (
          <div className="d-flex flex-row align-items-center">
            <a
              className="mx-1 viewButton"
              onClick={() => gotoView(tableProps.cell.row.original)}
            >
              View
            </a>
            {/* <button
              className="btn mx-1 editbutton"
              onClick={() => gotoedit(tableProps.cell.row.original)}
            >
              Edit
            </button> */}

            {/* {tableProps.cell.row.original.status === "1" ? (
              <>
                <button
                  className="btn mx-1 deleteButton"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  onClick={() => setDeleteData(tableProps.cell.row.original)}
                >
                  Delete
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn mx-1 deleteButton"
                  data-toggle="modal"
                  data-target="#deleteModal"
                  onClick={() => setDeleteData(tableProps.cell.row.original)}
                >
                  Activate
                </button>
              </>
            )} */}
          </div>
        ),
      },
    ],
    []
  );

  // State to store data and pagination information
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(10);

  // Function to fetch data from the server based on pagination parameters
  const fetchData = React.useCallback(async ({ pageIndex, pageDataSize }) => {
    let resp = await fetchCustomersData(pageIndex + 1, pageDataSize);
    // console.log("🚀 ~ file: ServerSideTable.js:149 ~ fetchData ~ resp:", resp)
    setData(resp?.data?.data?.customers || [])
    setPageCount(Math.ceil(resp?.data?.data?.totalpages) || 0)
    // try {
    //   setData(resp?.data?.data?.customers || []);
    //   // Assuming you know the total count from the server response
    //   setPageCount(Math.ceil(resp?.data?.data?.totalpages));
    //   // setPageCount(Math.ceil(response.headers.get('X-Total-Count') / pageSize));
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  }, []);

  // Create a table instance with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageDataSize }, // Initial page index
      manualPagination: true, // Enable manual pagination
      pageCount, // Pass the total number of pages
    },
    useGlobalFilter,
    usePagination
  );

  // Fetch data when the page index changes
  useEffect(() => {
    fetchData({ pageIndex, pageDataSize });
  }, [fetchData, pageIndex, pageDataSize]);

  const gotoView = (data) => {
    navigate(`/${RouteStrings.viewCustomer}`, { state: { data: data } });
  };

  // const gotoedit = (data) => {
  //   navigate(RouteStrings.editCustomer, { state: { data: data } });
  // };

  // const deleteCustomerFun = async () => {
  //   let response = await DeleteCustomerService(
  //     deleteData?.custno,
  //     deleteData?.status
  //   );

  //   if (response?.status === 200 || response?.status === 201) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success",
  //       text: `${response?.data?.Message}`,
  //     });
  //     document.getElementById("closeDeleteModalCustomer").click();
  //     window.location.reload();
  //     // gotoPage(0);
  //   } else if (
  //     response?.response?.status === 400 ||
  //     response?.response?.status === 404 ||
  //     response?.response?.status === 401
  //   ) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error :  ${response?.response?.data?.Message}`,
  //     });

  //     console.log("error", response);
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error ${response?.response?.statusText}`,
  //     });

  //     console.log("error", response);
  //   }
  // };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setQuery("email");
  };

  const handleChangeName = (e) => {
    setQuery("name");
  };

  const searchFuntion = async () => {
    if (query === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select Email or Name`,
      });
    } else {
      // let response = await SearchCustomerService(`${query}`, searchText);
      let response = await getRequest(
        `${configURL.search_customerData}/${query}/${searchText}/`
      );
      if (
        response?.status === 200 &&
        response?.data?.data?.customers.length > 0
      ) {
        setData(response?.data?.data?.customers);
      } else if (response?.data?.data?.customers?.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error No Customers Found!`,
        });
        // console.log("no customer found", response);
      } else if (
        response?.response?.status === 400 ||
        response?.response?.status === 404 ||
        response?.response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${response?.response?.data?.message}`,
        });
        // console.log("error", response);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error `,
        });
        // console.log("error", response);
      }
    }
  };

  const deleteSearchText = () => {
    inpRef.current.value = "";
    let _name = document.getElementById("nameCheck");
    let _email = document.getElementById("emailCheck");
    _name.checked = false;
    _email.checked = false;
    fetchData({ pageIndex, pageDataSize });
  };

  return (
    <div>
      <div className="d-md-flex align-items-center my-2">
        <div className="common_input_div mb-2 mb-md-0">
          <input
            type="text"
            name="search"
            id="search"
            ref={inpRef}
            className="common_inputfield email_field"
            onChange={handleSearch}
            placeholder="Enter name or email"
          />

          <img
            src={Images.cancel}
            alt="cancel"
            className="cancelIcon"
            onClick={deleteSearchText}
          />
        </div>
        <div className="selectEmailOrNameDiv d-flex align-items-center mx-2">
          <Form>
            <Form.Group>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  name="currentlyBuying"
                  id="emailCheck"
                  label="Email"
                  value="true"
                  onChange={handleChangeEmail}
                />
                <Form.Check
                  type="radio"
                  id="nameCheck"
                  name="currentlyBuying"
                  className="mx-2"
                  label="Name"
                  value="false"
                  onChange={handleChangeName}
                />
              </div>
            </Form.Group>
          </Form>
          <div>
            <button className="btn btn-info rounded-0" onClick={searchFuntion}>
              Search
            </button>
          </div>
        </div>

      </div>
      {/* ------ */}
      <div className="table_overflow">
        <table {...getTableProps()} className="table mb-0">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>


      {/* Pagination controls */}

      <div className="d-md-flex align-items-center mb-3">
        <div className="mt-2 mt-md-0">
          <select
            value={pageDataSize}
            className="selectTag mb-3"
            onChange={(e) => setPageDataSize(Number(e.target.value))}
          >
            <option value="" disabled>
              Select
            </option>
            {[5, 10, 15, 25, 50]?.map((pageDataSize) => (
              <option key={pageDataSize} value={pageDataSize}>
                {pageDataSize}
              </option>
            ))}
          </select>
        </div>
        <div className="w-100 text-center mt-3 mb-1">
          <button
            className="mx-1 skipToBtn"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className="mx-1 actionBtn"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Prev
          </button>
          <span className="mx-2 pageNumber">
            {/* Page{" "} */}
            <strong>
              {pageIndex + 1}
              {/* of {pageOptions.length} */}
            </strong>{" "}
          </span>
          <button
            className="mx-1 actionBtn"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className="skipToBtn"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </div>

      {/* Delete Modal */}

      {/* <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                {deleteData.status === "1" ? (
                  <> Delete Customer </>
                ) : (
                  <>Activate the customer</>
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            

              {deleteData.status === "1" ? (
                <> Are you sure you want to delete the customer? </>
              ) : (
                <>Are you sure, you want to activate the customer?</>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                data-dismiss="modal"
                id="closeDeleteModalCustomer"
              >
                Close
              </button>
              {deleteData?.status === "1" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-danger rounded-0"
                    onClick={deleteCustomerFun}
                  >
                    Delete
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-info rounded-0"
                    onClick={deleteCustomerFun}
                  >
                    Activate
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ServerSidePaginationTable;
