import {
  getRequest,
  postRequest,
  putRequest,
  deleteReqest,
} from "./BaseServices";
import axios from "axios";

import { configURL } from "./configuration";

export const adminLoginService = async (payload) => {
  const response = await postRequest(configURL.auth_login, payload);
  if (response) {
    return response;
  } else {
    // console.log("auth service error -- login");
    return response;
  }
};

export const getAllCountriesService = async () => {
  const response = await getRequest(configURL.get_countries);
  if (response) {
    return response;
  } else {
    // console.log("auth sercice error -- getallcountries");
  }
};

export const verifyOTPService = async (payload) => {
  const response = await postRequest(configURL.verify_otp, payload);
  if (response) {
    return response;
  } else {
    // console.log("verify otp error---", response);
    return response;
  }
};

export const forgotPasswordService = async (payload) => {
  const response = await postRequest(configURL.forgot_password, payload);
  if (response) {
    return response;
  } else {
    // console.log("forgot password error --", response);
    return response;
  }
};

export const resetPasswordService = async (payload, token) => {
  const response = await postRequest(
    `${configURL.reset_password}/${token}/`,
    payload
  );
  if (response) {
    return response;
  } else {
    // console.log("resetpassword error-", response);
    return response;
  }
};

export const ResendOtpService = async (email) => {
  try {
    let response = await getRequest(`${configURL.resend_otp}/${email}/`);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: AuthServices.js:67 ~ ResendOtpService ~ error:",
    //   error
    // );
    return error;
  }
};

// export const adminLoginService = async () => {
//   try {
//     const response = await
//   } catch (error) {
//     console.log("🚀 ~ file: AuthServices.js:34 ~ adminLoginService ~ error:", error)
//   }
// }
