import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { CTAButton } from "../../../../Components/CTAButton";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { OrdersTable } from "./OrdersTable";
import {
  DeleteCustomerService,
  GetCustomerById,
} from "../../../../Services/CustomerServices";
import Swal from "sweetalert2";
import { Images } from "../../../../Assets/Images/imageslist";
import "./styles.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import PrintPage from "./PrintPage";
import { useReactToPrint } from "react-to-print";
import { FunctionalComponentWithHook } from "./FunctionalComponentWithHook";

export const ViewCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getRequest } = ApiResponseHook();

  // !useEffect
  useEffect(() => {
    getCustomerDataById();
  }, []);

  const generateLabel = (e) => {
    e.preventDefault();
  };

  // !STATES
  const [customerData, setCustomerData] = useState({});

  const [orders, setOrderes] = useState([]);

  const [show, setShow] = useState(false);

  // const [row, setRow] = useState(0);
  // const [column, setColumn] = useState(0);

  const [values, setValues] = useState({
    row: 0,
    col: 0,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // !--- functions ---
  const getCustomerDataById = async () => {
    // let response = await GetCustomerById(location?.state?.data?.custno);
    let response = await getRequest(
      `${configURL.get_customer_byId}/${location?.state?.data?.custno}/`
    );

    if (response?.status === 200) {
      setCustomerData(response?.data?.data[0]?.customer[0]);
      // setOrderes(response?.data?.data[0]?.orders);

      const filteredData = response?.data?.data[0]?.orders.filter((item) => {
        return item.status === "1";
      });
      setOrderes(filteredData);
    } else if (
      response?.response?.status === 400 ||
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.statusText}`,
      });
      // console.log("error", response);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      // console.log("error", response);
    }
  };

  const goBack = () => {
    navigate(`/${RouteStrings.customerManagement}`);
  };

  const deleteCustomerFun = async () => {
    // let response = await DeleteCustomerService(
    //   customerData?.custno,
    //   customerData?.status
    // );
    let response = await getRequest(
      `${configURL.edit_customer_byId}/${customerData?.custno}/${customerData?.status}/`
    );

    if (response?.status === 200 || response?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${response?.data?.Message}`,
      });
      document.getElementById("closeDeleteModalViewCustomerCustomer").click();
      getCustomerDataById();
    } else if (
      response?.response?.status === 400 ||
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error :  ${response?.response?.data?.Message}`,
      });

      // console.log("error", response);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.statusText}`,
      });

      // console.log("error", response);
    }
  };

  const gotoEditView = () => {
    navigate(`/${RouteStrings.editCustomer}`, {
      state: { data: customerData?.custno },
    });
  };

  const generateLabelFun = (e) => {
    e.preventDefault();

    const htmlContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            .grid-container-test {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: repeat(6, 1fr);
              border: 1px solid;
            }
            
            .grid-item {
              border: 1px solid #000;
              padding: 10px;
            }
          </style>
        </head>
        <body>
          <div class="grid-container-test">
            <div class="grid-item" style="grid-row-start: ${values.row
      }; grid-row-end: ${values.row + 1}; grid-column-start: ${values.col
      }; grid-column-end: ${values.col + 1};">
              <p>${customerData.name}</p>
              <p>${customerData.address1 || "--"}</p>
              <p>${customerData.address2 || "--"}</p>
              <p>${customerData.town || "--"}</p>
              <p>${customerData.state || "--"}</p>
              <p>${customerData.country_name || "--"}</p>
              <p>${customerData.postcode || "--"}</p>
              <p>${customerData.email || "--"}</p>
            </div>
          </div>
        </body>
      </html>
    `;

    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();

    // Wait for the content to load and then print the content as a PDF
    newWindow.onload = () => {
      newWindow.print();
      newWindow.close();
    };
  };

  const [rows, setRows] = useState(1);
  const [columns, setColumns] = useState(1);

  const handleRowChange = (e) => {
    const value = parseInt(e.target.value);
    setRows(isNaN(value) ? null : value);
  };

  const handleColumnChange = (e) => {
    const value = parseInt(e.target.value);
    setColumns(isNaN(value) ? null : value);
  };

  return (
    <div>
      <div>
        <div>
          <BreadCrumb
            routename="Customer Details"
            isBack={true}
            gotoBackFun={goBack}
          />
        </div>

        <div className="newCustomerDiv my-2">
          <div className="d-flex justify-content-end w-100">
            <div className="dropdown">
              <img
                src={Images.more}
                alt=""
                className="moreIcon"
                data-toggle="dropdown"
                aria-expanded="false"
              />

              <div class="dropdown-menu">
                <a
                  class="dropdown-item text-center"
                  data-toggle="modal"
                  data-target="#deleteModalViewCustomer"
                >
                  <strong>
                    {customerData.status === "1" ? <>Delete</> : <>Activate</>}
                  </strong>
                </a>

                <a class="dropdown-item text-center" onClick={gotoEditView}>
                  <strong>Edit</strong>
                </a>
              </div>
            </div>
          </div>
          <div className="formDiv">
            <h5 className="subHeading mb-3">Contact Details</h5>
            <form className="form_">
              <div className="row">
                <div className="col-md-6">
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Full Name : </strong>
                    </label>

                    <div>
                      <span>{customerData?.title} </span>{" "}
                      <span>{customerData?.forename}</span>{" "}
                      <span> {customerData?.name}</span>
                    </div>
                  </div>

                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Company : </strong>
                    </label>
                    <p className="mb-0">{customerData?.company || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Address Line 1 : </strong>
                    </label>
                    <p className="mb-0">{customerData?.address1 || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Address Line 2 : </strong>
                    </label>
                    <p className="mb-0">{customerData?.address2 || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Town/City : </strong>
                    </label>
                    <p className="mb-0">{customerData?.town || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Country/State : </strong>
                    </label>
                    <p className="mb-0">{customerData?.county || "---"}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Postcode/ZIP Code : </strong>
                    </label>
                    <p className="mb-0">{customerData?.postcode || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <div class="form-group d-flex mb-0">
                      <label for="exampleFormControlSelect1">
                        <strong>Country : </strong>
                      </label>
                      <p className="mb-0">
                        {customerData?.country_name || "---"}
                      </p>
                    </div>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Email : </strong>
                    </label>
                    <p className="mb-0">{customerData?.email || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Home Phone : </strong>
                    </label>
                    <p className="mb-0">{customerData?.hphone || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Work Phone : </strong>
                    </label>
                    <p className="mb-0">{customerData?.ophone || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Alternate Address : </strong>
                    </label>
                    <p className="mb-0">{customerData?.altaddress || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong>Comments : </strong>
                    </label>
                    <p className="mb-0">{customerData?.comments || "---"}</p>
                  </div>
                  <div className="formDiv d-flex  mb-2">
                    <label htmlFor="" className="mb-0">
                      <strong> Personal Names : </strong>
                    </label>
                    <p className="mb-0">{customerData?.pnames || "---"}</p>
                  </div>

                  {/* TODO: to work on the rows  and columns for the generate label */}
                  <div className="">
                    <div className="d-lg-flex align-items-center">
                      <div className="d-flex flex-column">
                        <label htmlFor="">Row</label>

                        <input
                          type="number"
                          className="inputTag"
                          id="rowInput"
                          value={rows}
                          onChange={handleRowChange}
                        />
                      </div>

                      <div className="d-flex flex-column mx-lg-2">
                        <label htmlFor="">Column</label>
                        <input
                          type="number"
                          className="inputTag"
                          id="columnInput"
                          value={columns}
                          onChange={handleColumnChange}
                        />
                      </div>
                    </div>

                    <FunctionalComponentWithHook
                      columns={columns}
                      rows={rows}
                      data={customerData}
                    />
                  </div>
                </div>
              </div>

              {/* order detials diuv */}

              <div className="orderDetailsDiv">
                <div>
                  <h5 className="subHeading">Order History</h5>
                </div>

                <div>
                  <div className="row ">
                    <div className="col-md-8">
                      <OrdersTable orders={orders} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteModalViewCustomer"
        tabindex="-1"
        aria-labelledby="deleteModalViewCustomerLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalViewCustomerLabel">
                {customerData.status === "1" ? (
                  <> Delete Customer </>
                ) : (
                  <>Activate the customer</>
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* Are you sure you want to delete the customer? */}

              {customerData.status === "1" ? (
                <> Are you sure you want to delete the customer? </>
              ) : (
                <>Are you sure, you want to activate the customer?</>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                data-dismiss="modal"
                id="closeDeleteModalViewCustomerCustomer"
              >
                Close
              </button>
              {customerData?.status === "1" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-danger rounded-0"
                    onClick={deleteCustomerFun}
                  >
                    Delete
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-info rounded-0"
                    onClick={deleteCustomerFun}
                  >
                    Activate
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customerdetails d-flex flex-column">
            <span>
              <strong>Customer Name</strong> : {customerData.name || "--"}
            </span>
            <span>
              <strong>Address Line 1</strong> : {customerData.address1 || "--"}
            </span>
            <span>
              <strong>Address Line 2</strong> : :{" "}
              {customerData.address2 || "--"}
            </span>
            <span>
              <strong>Town</strong> : {customerData.town || "--"}
            </span>
            <span>
              <strong>State</strong> : {customerData.state || "--"}
            </span>
            <span>
              <strong>Country</strong> : {customerData.country || "--"}
            </span>
            <span>
              <strong>Postcode</strong> : {customerData.postcode || "--"}
            </span>
            <span>
              <strong>Email</strong> : {customerData.email || "--"}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const Grid = () => {
  const [row, setRow] = useState(0);
  const [column, setColumn] = useState(0);

  // Define a function to move the div
  const moveDiv = (newRow, newColumn) => {
    setRow(1);
    setColumn(3);
  };

  return (
    <div className="grid-container">
      {/* Render your grid here */}
      {/* Place the div at the specified row and column */}
      <div className="moving-div" style={{ gridRow: row, gridColumn: column }}>
        {/* Content of the moving div */}
        This div is at Row {row} and Column {column}
      </div>
    </div>
  );
};
