import React from "react";
import "./styles.scss";

export const CTAButton = ({ name, handleClick, type, isDisabled, id }) => {
  return (
    <div className="btndiv">
      <button
        id={id}
        className="btn btn_"
        onClick={handleClick}
        type={type}
        disabled={isDisabled}
      >
        {name}
      </button>
    </div>
  );
};
