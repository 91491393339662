import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
// import { BidsOnSaleByTenderService } from "../../../../Services/OrderServices";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";

export const BidsAndTenderComponent = () => {
  const navigate = useNavigate();
  const { getRequest } = ApiResponseHook()

  const printableDivRef = useRef(null);

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  const [bidsData, setBidsData] = useState([]);

  useEffect(() => {
    getBidsDataforSaleByTender();
  }, []);

  const data = [
    {
      item: "Item 1",
      bidderName: "John Doe",
      bidderEmail: "john@example.com",
      bid: "$100",
    },
    {
      item: "Item 2",
      bidderName: "Jane Smith",
      bidderEmail: "jane@example.com",
      bid: "$150",
    },
    {
      item: "Item 3",
      bidderName: "Michael Johnson",
      bidderEmail: "michael@example.com",
      bid: "$120",
    },
  ];

  const getBidsDataforSaleByTender = async () => {
    let res = await getRequest(configURL.bidsonsalebytender)
    // let res = await BidsOnSaleByTenderService();

    if (res?.status === 200 || res?.status === 201) {
      setBidsData(res?.data?.data);
    } else {
      console.log("error getting the data for bids", res);
    }
  };

  const handlePrint = () => {
    // Get the content of the div with the id "toprintscreen"
    var printContent = document.getElementById("toprintscreen").innerHTML;

    // Create a new window for printing
    var printWindow = window.open("", "", "width=600,height=600");

    // Write the content to the new window
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printContent);
    printWindow.document.write("</body></html>");
    printWindow.document.close();

    // Print the new window
    printWindow.print();

    // Close the new window after printing
    printWindow.close();
  };

  return (
    <div>
      <div>
        <BreadCrumb
          routename="Bids on items for sale by tender"
          isBack={true}
          gotoBackFun={gotoback}
        />
      </div>

      <div className="d-flex align-items-center justify-content-between my-2">
        <div>
          <h5>Bids on items for sale by tender</h5>
        </div>

        <div>
          <button className="btn btn-info rounded-0 mr-2" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>

      {/* the table here */}

      <div className="toprintscreen" id="toprintscreen">
        <table border="1">
          <thead>
            <tr>
              <th>Item</th>
              <th>Bidder Name</th>
              <th>Bidder Email</th>
              <th>Bid</th>
            </tr>
          </thead>
          <tbody>
            {bidsData.length === 0 ? (
              <>No Data Found</>
            ) : (
              <>
                {data.map((itemData, index) => (
                  <tr key={index}>
                    <td>{itemData.item}</td>
                    <td>{itemData.bidderName}</td>
                    <td>{itemData.bidderEmail}</td>
                    <td>{itemData.bid}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
