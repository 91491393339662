import { createSlice } from "@reduxjs/toolkit";

export const uiSessionReducer = createSlice({
  name: "uiReducer",
  initialState: {
    isLogin: false,
    loader: false,
    auth: null
  },
  reducers: {
    loggedIn: (state) => {
      state.isLogin = true
    },
    loggedOut: (state) => {
      state.isLogin = false
      state.auth = null
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setAuth: (state, action) => {
      state.isLogin = action.payload.isLogin
      state.auth = action.payload.auth
    },
    resetAuth: (state) => {
      state.isLogin = false
      state.auth = null
    }
  },
});

export const { loggedIn, loggedOut, setLoader, setAuth, resetAuth } = uiSessionReducer.actions;

export default { uiSessionReducer };