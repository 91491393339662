import axios from "axios";
import { pennymead_GetLocal } from "../Utils/LocalStorage";
import axiosInstance from "./AxiosInstance";
import { useDispatch } from "react-redux";
import { setLoader } from "../Store/reducers/reducers";

export const getRequest = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: MainBaseServices.js:29 ~ getRequest ~ error:",
      error
    );
    return error;
  }
};

export const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data, {
      //   headers: headers,
      headers: {
        "Content-Type": "text/plain",
      },
    });
    return response;
  } catch (error) {
    console.log("🚀 ~ file: BaseServices.js:27 ~ postRequest ~ error:", error);
    return error;
  }
};

export const putRequest = async (url, data = {}) => {
  // try {
  //   const response = await axiosInstance.put(url, data);
  //   // console.log('putRequest response==>', response);
  //   return response;
  // } catch (error) {
  //   console.log("putRequest  error==>", error.response);
  //   return error;
  // }

  try {
    let response = await axios.put(url, data, {
      // headers: {
      //   "Content-Type": "text/plain",
      // },
    });
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: MainBaseServices.js:53 ~ putRequest ~ error:",
      error
    );
    return error;
  }
};

export const deleteReqest = async (url, data) => {
  // try {
  //   const response = await axiosInstance.delete(url, data || null);
  //   // console.log('deleteRequest response==>', response);
  //   return response;
  // } catch (error) {
  //   console.log("deleteRequest error==>", error);
  //   return error;
  // }

  try {
    const response = await axios.delete(url, data, {
      //   headers: headers,
      headers: {
        "Content-Type": "text/plain",
      },
    });
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: MainBaseServices.js:69 ~ deleteReqest ~ error:",
      error
    );
    return error;
  }
};

export const patchRequest = async (url = {}) => {
  console.log("🚀 ~ file: MainBaseServices.js:106 ~ patchRequest ~ url:", url);
  try {
    let response = await axios.patch(url, {
      // headers: {
      //   "Content-Type": "text/plain",
      // },
    });
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: MainBaseServices.js:53 ~ patchRequest ~ error:",
      error
    );
    return error;
  }
};

export const ApiResponseHook = () => {
  const dispatch = useDispatch();
  const getRequest = async (url) => {
    dispatch(setLoader(true))
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(setLoader(false))
    }
  };

  const postRequest = async (url, data) => {
    dispatch(setLoader(true))
    try {
      const response = await axios.post(url, data, {
        //   headers: headers,
        headers: {
          "Content-Type": "text/plain",
        },
      });
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(setLoader(false))
    }
  };

  return { getRequest, postRequest }
}

export const CorsApiResponseHook = () => {
  const dispatch = useDispatch();
  const _getRequest = async (url) => {
    dispatch(setLoader(true))
    try {
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(setLoader(false))
    }
  };

  const _postRequest = async (url, data) => {
    dispatch(setLoader(true))
    try {
      const response = await axiosInstance.post(url, data, {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(setLoader(false))
    }
  };

  return { _getRequest, _postRequest }
}