export const pennymead_SetLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const pennymead_GetLocal = (key) => {
  let value = localStorage.getItem(key);
  return JSON.parse(value);
};
export const pennymead_ClearLocal = () => {
  localStorage.clear();
};

export const pennymeadStorageKeys = {
  authToken: "token",
  userDetails: "userdeatails",
};
