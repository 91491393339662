import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import "./styles.scss";
import { CTAButton } from "../../../../Components/CTAButton";
import { GetAllCountriesService } from "../../../../Services/CommonServices";
import { AddNewCustomerService } from "../../../../Services/CustomerServices";
import Swal from "sweetalert2";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";

export const AddNewCustomer = () => {
  const navigate = useNavigate();
  const { postRequest, getRequest } = ApiResponseHook();

  // --- STATES---
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllCountries = async () => {
    let response = await getRequest(configURL.get_all_countries);

    // if (response.status === 200) {
    setCountriesList(response?.data?.data || []);
    // } else {
    //   console.log("error", response);
    // }
  };


  const [customer, setCustomer] = useState({
    fullName: "",
    company: "",
    addressLine1: "",
    addressLine2: "",
    towncity: "",
    countrystate: "",
    postcodezipcode: "",
    country: "",
    email: "",
    homephone: "",
    workphone: "",
    alternateaddress: "",
    comments: "",
    personalnames: "",
  });

  const [error, setError] = useState({
    fullName: false,
    company: false,
    addressLine1: false,
    addressLine2: false,
    towncity: false,
    countrystate: false,
    postcodezipcode: false,
    country: false,
    email: false,
    homephone: false,
    workphone: false,
    alternateaddress: false,
    comments: false,
    personalnames: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    fullName: "",
    company: "",
    addressLine1: "",
    addressLine2: "",
    towncity: "",
    countrystate: "",
    postcodezipcode: "",
    country: "",
    email: "",
    homephone: "",
    workphone: "",
    alternateaddress: "",
    comments: "",
    personalnames: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    // if (name === "title") {
    //   setError({
    //     ...error,
    //     title: false,
    //   });
    //   setCustomer({
    //     ...customer,
    //     title: value,
    //   });
    // }
    // if (name === "forename") {
    //   setError({
    //     ...error,
    //     forename: false,
    //   });
    //   setCustomer({
    //     ...customer,
    //     forename: value,
    //   });
    // }
    if (name === "fullName") {
      setError({
        ...error,
        fullName: false,
      });
      setCustomer({
        ...customer,
        fullName: value,
      });
    }
    if (name === "company") {
      setError({
        ...error,
        company: false,
      });
      setCustomer({
        ...customer,
        company: value,
      });
    }
    if (name === "addressOne") {
      setError({
        ...error,
        addressLine1: false,
      });
      setCustomer({
        ...customer,
        addressLine1: value,
      });
    }
    if (name === "addressTwo") {
      setError({
        ...error,
        addressLine2: false,
      });
      setCustomer({
        ...customer,
        addressLine2: value,
      });
    }
    if (name === "town") {
      setError({
        ...error,
        towncity: false,
      });
      setCustomer({
        ...customer,
        towncity: value,
      });
    }
    if (name === "country") {
      setError({
        ...error,
        countrystate: false,
      });
      setCustomer({
        ...customer,
        countrystate: value,
      });
    }
    if (name === "postcode") {
      setError({
        ...error,
        postcodezipcode: false,
      });
      setCustomer({
        ...customer,
        postcodezipcode: value,
      });
    }
    if (name === "countrySelect") {
      setError({
        ...error,
        country: false,
      });
      setCustomer({
        ...customer,
        country: value,
      });
    }
    if (name === "email") {
      setError({
        ...error,
        email: false,
      });
      setCustomer({
        ...customer,
        email: value,
      });
    }
    if (name === "homephone") {
      setError({
        ...error,
        homephone: false,
      });
      setCustomer({
        ...customer,
        homephone: value,
      });
    }
    if (name === "workphone") {
      setError({
        ...error,
        workphone: false,
      });
      setCustomer({
        ...customer,
        workphone: value,
      });
    }
    if (name === "alternateaddress") {
      setError({
        ...error,
        alternateaddress: false,
      });
      setCustomer({
        ...customer,
        alternateaddress: value,
      });
    }
    if (name === "comments") {
      setError({
        ...error,
        comments: false,
      });
      setCustomer({
        ...customer,
        comments: value,
      });
    }
    if (name === "personalnames") {
      setError({
        ...error,
        personalnames: false,
      });
      setCustomer({
        ...customer,
        personalnames: value,
      });
    }
  };

  function validateEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      customer.fullName === "" &&
      // customer.company === "" &&
      customer.addressLine1 === "" &&
      // customer.addressLine2 === "" &&
      customer.towncity === "" &&
      customer.countrystate === "" &&
      customer.postcodezipcode === "" &&
      customer.country === "" &&
      customer.email === "" &&
      customer.homephone === ""
      // customer.workphone === ""
      // customer.alternateaddress === "" &&
      // customer.comments === "" &&
      // customer.personalnames === ""
    ) {
      setError({
        ...error,

        fullName: true,
        // company: true,
        addressLine1: true,
        // addressLine2: true,
        towncity: true,
        countrystate: true,
        postcodezipcode: true,
        country: true,
        email: true,
        homephone: true,
        // workphone: true,
        // alternateaddress: true,
        // comments: true,
        // personalnames: true,
      });
      setErrorMsg({
        fullName: "Please enter the full name",
        // company: "Please enter the company",
        addressLine1: "Please enter the address 1",
        // addressLine2: "Please enter the address 2",
        towncity: "Please enter the town/city",
        countrystate: "Please enter the county/state",
        postcodezipcode: "Please enter the post code/zip code",
        country: "Please enter the country",
        email: "Please enter the email",
        homephone: "Please enter the home phone",
        // workphone: "Please enter the work phone",
        // alternateaddress: "Please enter the alternate address",
        // comments: "Please enter the comments",
        // personalnames: "Please enter the personal names",
      });
    } else if (customer.fullName === "") {
      setError({
        ...error,
        fullName: true,
      });
      setErrorMsg({
        fullName: "Please enter the full name",
      });
    }
    // else if (customer.company === "") {
    //   setError({
    //     ...error,
    //     company: true,
    //   });
    //   setErrorMsg({
    //     company: "Please enter the company",
    //   });
    // }
    else if (customer.addressLine1 === "") {
      setError({
        ...error,
        addressLine1: true,
      });
      setErrorMsg({
        addressLine1: "Please enter the addressLine1",
      });
    }
    // else if (customer.addressLine2 === "") {
    //   setError({
    //     ...error,
    //     addressLine2: true,
    //   });
    //   setErrorMsg({
    //     addressLine2: "Please enter the addressLine2",
    //   });
    // }
    else if (customer.towncity === "") {
      setError({
        ...error,
        towncity: true,
      });
      setErrorMsg({
        towncity: "Please enter the towncity",
      });
    } else if (customer.countrystate === "") {
      setError({
        ...error,
        countrystate: true,
      });
      setErrorMsg({
        countrystate: "Please enter the countrystate",
      });
    } else if (customer.postcodezipcode === "") {
      setError({
        ...error,
        postcodezipcode: true,
      });
      setErrorMsg({
        postcodezipcode: "Please enter the postcodezipcode",
      });
    } else if (customer.country === "") {
      setError({
        ...error,
        country: true,
      });
      setErrorMsg({
        country: "Please enter the country",
      });
    } else if (customer.email === "") {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter the email",
      });
    } else if (!validateEmail(customer.email)) {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter the valid email",
      });
    } else if (customer.homephone === "") {
      setError({
        ...error,
        homephone: true,
      });
      setErrorMsg({
        homephone: "Please enter the homephone",
      });
    }
    // else if (customer.workphone === "") {
    //   setError({
    //     ...error,
    //     workphone: true,
    //   });
    //   setErrorMsg({
    //     workphone: "Please enter the workphone",
    //   });
    // }
    // else if (customer.alternateaddress === "") {
    //   setError({
    //     ...error,
    //     alternateaddress: true,
    //   });
    //   setErrorMsg({
    //     alternateaddress: "Please enter the alternateaddress",
    //   });
    // }
    // else if (customer.comments === "") {
    //   setError({
    //     ...error,
    //     comments: true,
    //   });
    //   setErrorMsg({
    //     comments: "Please enter the comments",
    //   });
    // }
    // else if (customer.personalnames === "") {
    //   setError({
    //     ...error,
    //     personalnames: true,
    //   });
    //   setErrorMsg({
    //     personalnames: "Please enter the personalnames",
    //   });
    // }
    else {
      let payload = {
        name: customer.fullName,
        company: customer.company,
        address1: customer.addressLine1,
        address2: customer.addressLine2,
        city: customer.towncity,
        state: customer.countrystate,
        postcode: customer.postcodezipcode,
        country: customer.country,
        email: customer.email,
        home_phone: customer.homephone,
        work_phone: customer.workphone,
        alternative_address: customer.alternateaddress,
        comments: customer.comments,
        personal_name: customer.personalnames,
      };

      // let response = await AddNewCustomerService(payload);
      let response = await postRequest(configURL.add_new_customer, payload);
      if (response?.status === 200 || response?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Customer Successfully Added!`,
        });
        navigate(`/${RouteStrings.customerManagement}`);
      } else if (
        response?.response?.status === 400 ||
        response?.response?.status === 401 ||
        response?.response?.status === 404
      ) {
        let errorObj = response?.response?.data?.error;

        // const firstValue = errorObj[Object.keys(errorObj)[0]];
        let firstValue = "";

        if (errorObj) {
          firstValue = errorObj[Object.keys(errorObj)[0]];
        } else {
          firstValue = response?.response?.data?.message;
        }

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error :  ${firstValue}`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${response.response.statusText}`,
        });
        // console.log("error", response);
      }
    }
  };

  const goBack = () => {
    navigate(`/${RouteStrings.customerManagement}`);
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault(); // Prevents the input of numbers
    }
  }

  function validateNumberInput(event) {
    const inputValue = event.key;

    // Check if the input is a digit (0-9), a minus sign, or a backspace (for deletion)
    if (!/[0-9-]/.test(inputValue) && event.key !== "Backspace") {
      event.preventDefault(); // Prevents the input of text and characters other than '-', digits, and backspace
    }

    // If the input is a minus sign, make sure it's the first character (negative sign)
    if (inputValue === "-" && event.target.selectionStart !== 0) {
      event.preventDefault();
    }
  }

  return (
    <div>
      <div>
        <BreadCrumb
          routename="Add New Customer"
          isBack={true}
          gotoBackFun={goBack}
        />
      </div>

      <div className="newCustomerDiv my-2">
        <div className="formDiv">
          <h5 className="subHeading">Contact Details</h5>
          <form className="form_" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Full Name <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    onChange={handleChange}
                    onKeyPress={validateInput}
                    placeholder="Enter the full name"
                    className="inp"
                  />
                  {error.fullName && (
                    <>
                      <span className="text-danger">{errorMsg.fullName}</span>
                    </>
                  )}
                </div>

                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Company</label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    onChange={handleChange}
                    placeholder="Enter the company"
                    className="inp"
                  />
                  {error.company && (
                    <>
                      <span className="text-danger">{errorMsg.company}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Address Line 1 <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="addressOne"
                    onChange={handleChange}
                    id="addressOne"
                    placeholder="Enter the address 1"
                    className="inp"
                  />
                  {error.addressLine1 && (
                    <>
                      <span className="text-danger">
                        {errorMsg.addressLine1}
                      </span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Address Line 2</label>
                  <input
                    type="text"
                    name="addressTwo"
                    onChange={handleChange}
                    id="addressTwo"
                    placeholder="Enter the address 2"
                    className="inp"
                  />
                  {error.addressLine2 && (
                    <>
                      <span className="text-danger">
                        {errorMsg.addressLine2}
                      </span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Town/City <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="town"
                    id="town"
                    onChange={handleChange}
                    onKeyPress={validateInput}
                    placeholder="Enter the town/city"
                    className="inp"
                  />
                  {error.towncity && (
                    <>
                      <span className="text-danger">{errorMsg.towncity}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    County/State <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    onKeyPress={validateInput}
                    name="country"
                    id="country"
                    placeholder="Enter the country/state"
                    className="inp"
                  />
                  {error.countrystate && (
                    <>
                      <span className="text-danger">
                        {errorMsg.countrystate}
                      </span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Postcode/ZIP Code <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="postcode"
                    id="postcode"
                    placeholder="Enter the postcode"
                    className="inp"
                  />
                  {error.postcodezipcode && (
                    <>
                      <span className="text-danger">
                        {errorMsg.postcodezipcode}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="formDiv d-flex flex-column mb-2">
                  <div class="form-group mb-0">
                    <label for="exampleFormControlSelect1">
                      Country <span className="_imp">*</span>
                    </label>
                    <select
                      onChange={handleChange}
                      class="form-control inp"
                      name="countrySelect"
                      id="exampleFormControlSelect1"
                    >
                      <option value="none" selected disabled>
                        Select Country
                      </option>
                      {countriesList?.map((country) => {
                        return (
                          <>
                            <option
                              key={country?.numcode}
                              value={country?.printable_name}
                            >
                              {country?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {error.country && (
                      <>
                        <span className="text-danger">{errorMsg.country}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Email <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="email"
                    id="email"
                    placeholder="Enter the email"
                    className="inp"
                  />
                  {error.email && (
                    <>
                      <span className="text-danger">{errorMsg.email}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">
                    Home Phone <span className="_imp">*</span>
                  </label>
                  <input
                    type="number"
                    name="homephone"
                    onChange={handleChange}
                    onKeyDown={validateNumberInput}
                    id="homephone"
                    placeholder="Enter the homephone"
                    className="inp"
                  />
                  {error.homephone && (
                    <>
                      <span className="text-danger">{errorMsg.homephone}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Work Phone</label>
                  <input
                    type="number"
                    name="workphone"
                    onChange={handleChange}
                    onKeyDown={validateNumberInput}
                    id="workphone"
                    placeholder="Enter the workphone"
                    className="inp"
                  />
                  {error.workphone && (
                    <>
                      <span className="text-danger">{errorMsg.workphone}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Alternate Address</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="alternateaddress"
                    id="alternateaddress"
                    placeholder="Enter the alternateaddress"
                    className="inp"
                  />
                  {error.alternateaddress && (
                    <>
                      <span className="text-danger">
                        {errorMsg.alternateaddress}
                      </span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Comments</label>
                  <input
                    type="text"
                    name="comments"
                    onChange={handleChange}
                    id="comments"
                    placeholder="Enter the comments"
                    className="inp"
                  />
                  {error.comments && (
                    <>
                      <span className="text-danger">{errorMsg.comments}</span>
                    </>
                  )}
                </div>
                <div className="formDiv d-flex flex-column mb-2">
                  <label htmlFor="">Personal Names</label>
                  <input
                    type="text"
                    name="personalnames"
                    onChange={handleChange}
                    id="personalnames"
                    placeholder="Enter the personalnames"
                    className="inp"
                  />
                  {error.personalnames && (
                    <>
                      <span className="text-danger">
                        {errorMsg.personalnames}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <CTAButton name="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
