// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   pennymead_ClearLocal,
//   pennymead_SetLocal,
// } from "../../../Utils/LocalStorage";
// import { loggedIn, loggedOut } from "../../../Store/reducers/reducers";
// import { RouteStrings } from "../../../Routes/RouteStrings";
// import { useNavigate } from "react-router-dom";
// import "./styles.scss";
// import { BreadCrumb } from "../../../Components/Breadcrumb";
// import axios from "axios";

// export const Dashboard = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const logout = () => {
//     // pennymead_SetLocal("login", false);
//     dispatch(loggedOut());
//     pennymead_ClearLocal();
//     navigate(RouteStrings.login, { replace: true });
//   };

//   useEffect(() => {}, []);

//   return (
//     <div>
//       <div className="container-fluid my-3">
//         {/* <div className="w-100 d-flex justify-content-end">
//           <button className="btn btn-danger" onClick={logout}>
//             Logout
//           </button>
//         </div> */}

//         <div>
//           <BreadCrumb routename="Dashboard" />
//         </div>

//         <div className="cards">
//           <div className="cardDiv p-3">
//             <div className="row">
//               <div className="col-md-4 d-flex flex-column align-items-center">
//                 <div className="card p-3 _card">
//                   <h5 className="mb-0 subHeading">Orders to be invoiced</h5>
//                   <p className="mb-0">25</p>
//                 </div>
//               </div>
//               <div className="col-md-4">
//                 <div className="card p-3 _card">
//                   <h5 className="mb-0 subHeading">Orders awating payment</h5>
//                   <p className="mb-0">25</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { useSelector } from "react-redux";
import { Navbar } from "../../../Components/Navbar";
import { Navigate, Outlet } from "react-router-dom";
import { RouteStrings } from "../../../Routes/RouteStrings";
import { AdminSideBarComponent } from "../../../Components/Sidebar";

export const Dashboard = () => {
  const { isLogin } = useSelector((state) => state.UIStorage);

  return isLogin ? (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="w-100">
          <Navbar />
          <div className="row no-gutters">
            <div className="col-md-2">
              <AdminSideBarComponent />
            </div>
            <div className="col-md-10">
              <div className="p-3">
                <Outlet />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  ) : (
    <>{<Navigate to={RouteStrings.login} />}</>
  );
};
