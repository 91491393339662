import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "../../../Assets/Styles/common.scss";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const AdminNoteManagement = () => {
  const { getRequest, postRequest } = ApiResponseHook();
  const [note, setNote] = useState({});
  const [newNote, setnewNote] = useState("");
  const [allTips, setAllTips] = useState([]);
  const [types, setTypes] = useState([]);
  const [newTip, setNewTip] = useState();
  const [selecttype, setSelectType] = useState("");

  const inpRef = useRef();

  useEffect(() => {
    getAllNotes();
    getAllTips();
    getAllHelpTipsTypes();
  }, []);

  const getAllHelpTipsTypes = async () => {
    // let res = await GetAllHelpNoteTypesService();

    let res = await getRequest(configURL.get_all_help_note_type);

    setTypes(res?.data?.data);
  };

  const getAllNotes = async () => {
    // let res = await GetLatestNote();

    let res = await getRequest(configURL.get_all_notes);

    if (res?.status === 200 || res?.status === 201) {
      setNote(res?.data?.data?.iptxt);
    } else {
      console.log("error getting the note data", res);
    }
  };

  const getAllTips = async () => {
    // let res = await GetAllTipsService();

    let res = await getRequest(configURL.get_all_tips);

    if (res?.status === 200 || res?.status === 201) {
      setAllTips(res?.data?.data);
    } else {
      console.log("error getting all the tips ", res);
    }
  };

  const handleNote = (e) => {
    setnewNote(e.target.value);
  };

  const handleSelect = (e) => {
    setSelectType(e.target.value);
  };

  const handleTip = (e) => {
    setNewTip(e.target.value);
  };

  const submitNote = async () => {
    let formData = new FormData();

    formData.append("note", newNote);

    // let res = await AddNewNoteService(formData);
    let res = await postRequest(configURL.add_new_note, formData);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Added the new note!`,
      });
      inpRef.current.value = "";
      getAllNotes();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  // const handleDelete = async (data) => {
  //   let res = await DeleteTipService(data.id);

  //   if (res.status === 201 || res.status === 200) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success",
  //       text: `Deleted the note successfully!`,
  //     });
  //     getAllTips();
  //   } else if (
  //     res.response.status === 400 ||
  //     res.response.status === 401 ||
  //     res.response.status === 404
  //   ) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error ${res.response?.data?.message}`,
  //     });
  //     console.log("error", res);
  //   } else {
  //     console.log("errror", res);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error`,
  //     });
  //   }
  // };

  // const addNewtip = async () => {
  //   let payload = {
  //     category: selecttype,
  //     note: newTip,
  //   };

  //   let res = await AddNewTipService(payload);

  //   if (res.status === 201 || res.status === 200) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success",
  //       text: `Added new tip`,
  //     });
  //     inptextRef.current.value = "";
  //     selectRef.current.value = "";
  //     document.getElementById("closeAddnewtipmodal").click();
  //     getAllTips();
  //   } else if (
  //     res.response.status === 400 ||
  //     res.response.status === 401 ||
  //     res.response.status === 404
  //   ) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error ${res.response?.data?.message}`,
  //     });
  //     console.log("error", res);
  //   } else {
  //     console.log("errror", res);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: `Error`,
  //     });
  //   }
  // };

  return (
    <div>
      <div>
        <BreadCrumb routename="Note Management" />
      </div>

      <div>
        <h5 className="subHeading">Note Management</h5>
      </div>

      <div className="notesDiv my-3">
        <h6>Note : {note?.iptxt}</h6>
      </div>

      <div>
        <textarea
          name="note"
          id="note"
          cols="30"
          ref={inpRef}
          rows="10"
          className="inp"
          onChange={handleNote}
          // defaultValue={note}
          placeholder="enter the note here..."
        ></textarea>
      </div>
      <div className="my-3">
        <button className="btn btn-info rounded-0" onClick={submitNote}>
          Add New Note
        </button>
      </div>

      {/* <div className="mt-3 d-flex justify-content-between">
        <h6>Search Tips</h6>

        <button
          className="btn btn-info rounded-0"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          Add New Tip
        </button>
      </div> */}

      {/* <div className="tips">
        <div className="row">
          {allTips?.map((item) => {
            return (
              <div className="col-md-3">
                <div className="card p-2 my-2 position-relative">
                  <img
                    src={Images.deleteIcon}
                    alt="img"
                    className="deleteIcon"
                    onClick={() => handleDelete(item)}
                  />
                  {item?.note}
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

      {/* add new tip modal */}
      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add New Tip
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex flex-column">
                <label htmlFor="">Select type</label>

                <select
                  className="custom-select inp my-2"
                  onChange={handleSelect}
                  ref={selectRef}
                >
                  <option disabled selected>
                    Select the type
                  </option>
                  {types?.map((item) => {
                    return (
                      <option key={item?.category} value={item?.category}>
                        {item?.category}
                      </option>
                    );
                  })}
                </select>

                <label htmlFor="">Add New Tip</label>
                <textarea
                  name="tip"
                  id="tip"
                  cols="30"
                  onChange={handleTip}
                  ref={inptextRef}
                  rows="10"
                  className="inp w-100 mt-2"
                  placeholder="add new tip here..."
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary rounded-0"
                id="closeAddnewtipmodal"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary rounded-0"
                onClick={addNewtip}
              >
                Add Tip
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
