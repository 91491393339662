import React, { useMemo } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";

import "./styles.scss";
import { GlobalFilter } from "../../../../Utils/Table/GlobalFilter";
import Data from "./data.json";
import "./styles.scss"

import { useNavigate, useLocation } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";

export const TipsTable = (props) => {
  const location = useLocation();

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Tips",
        accessor: "tip",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (tableProps) => (
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn mx-1 deleteButton"
              data-toggle="modal"
              data-target="#deleteModal"
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );
  // const data = useMemo(() => props.data);
  const data = useMemo(() => Data);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;

  // const gotoedit = (data) => {
  //   console.log("🚀 ~ file: index.js:175 ~ gotoedit ~ data:", data);
  //   navigate(`/${RouteStrings.editCustomer}`, { state: { data: data } });
  // };

  // const gotoView = (data) => {
  //   console.log("🚀 ~ file: index.js:123 ~ gotoView ~ data:", data);
  //   navigate(RouteStrings.viewCustomer);
  // };

  // const gotoOrderDetails = (data) => {
  //   // navigate(RouteStrings.editItem, { state: { data: data } });
  //   // console.log("the order details are", data);
  // };

  return (
    <>
      <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <button
          className="btn btn-info"
          data-toggle="modal"
          data-target="#addnewtip"
        >
          Add New Tip
        </button>
      </div>
      <div>
        <table {...getTableProps()} className="mt-3">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {Data.length === 0 ? (
            <tbody>
              <tr>No orders</tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                        // onClick={() => gotoOrderDetails(row.original)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="d-flex align-items-center mb-3">
          <div>
            <select
              value={pageSize}
              className="selectTag mb-3"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="" disabled>
                Select
              </option>
              {[5, 10, 15, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="w-100 text-center mt-3 mb-1">
            <button
              className="mx-1 skipToBtn"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="mx-1 actionBtn"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <span className="mx-2 pageNumber">
              {/* Page{" "} */}
              <strong>
                {pageIndex + 1}
                {/* of {pageOptions.length} */}
              </strong>{" "}
            </span>
            <button
              className="mx-1 actionBtn"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="skipToBtn"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>

      {/* Delete Modal */}

      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Customer
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete the tip?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn deleteButton">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add new tip modal */}

      <div
        class="modal fade"
        id="addnewtip"
        tabindex="-1"
        aria-labelledby="addnewtipLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addnewtipLabel">
                Add New Tip
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex flex-column">
                <label htmlFor="" className="mb-0">
                  New Tip Help
                </label>
                <input
                  type="text"
                  name="newtip"
                  id="newtip"
                  placeholder="Enter a new tip here"
                  className="inpTip"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Add New Tip
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
