import React, { useEffect, useState } from "react";
import "./styles.scss";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CTAButton } from "../../../Components/CTAButton";
import {
  GetAllGlobalSettingsService,
  UpdateGlobalSettingsService,
} from "../../../Services/GlobalSettingServices";

import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const GlobalSettings = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  // states
  const [allsettings, setAllSettings] = useState([]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getAllSettings();
  }, []);

  const getAllSettings = async () => {
    // let res = await GetAllGlobalSettingsService();

    let res = await getRequest(configURL.get_all_global_settings);

    setAllSettings(res?.data?.data);

    // Update formData based on the API response
    const updatedFormData = formData.map((field) => {
      const apiData = res?.data?.data?.find(
        (apiField) => apiField.shortname === field.shortname
      );
      return apiData ? { ...field, value: apiData.value } : field;
    });

    setFormData(updatedFormData);
  };

  const [formData, setFormData] = useState([
    { shortname: "omail", value: "", placeholder: "Office Mail" }, //order mail
  ]);
  const handleChange_ = (event) => {
    const { name, value } = event.target;
    setError(false);
    // Find the corresponding object in formData by name and update its value
    const updatedFormData = formData.map((field) =>
      field.shortname === name ? { ...field, value } : field
    );

    setFormData(updatedFormData);
  };

  function validateEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  // Function to submit the form data
  const handleSubmit = async () => {
    let officemail = formData.find((item) => {
      if (item.shortname === "omail") {
        return item;
      }
    });

    if (officemail.value === "") {
      setError(true);
      setErrorMsg("please enter email");
    } else if (!validateEmail(officemail.value)) {
      setError(true);
      setErrorMsg("invalid email");
      // console.log("error==========");
    } else {
      setError(false);

      // let res = await UpdateGlobalSettingsService(formData);

      let res = await postRequest(configURL.update_global_settings, formData);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the global settings`,
        });
        getAllSettings();
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleChange = () => { };

  return (
    <div>
      <div>
        <BreadCrumb routename="Global Settings" />
      </div>
      <div className="globalformsettings py-2">
        <div className="row">
          <div className="col-md-8">
            <div>
              {formData.map((field) => (
                <div
                  key={field.shortname}
                  className="globalsettingsdiv d-flex flex-column"
                >
                  <label htmlFor={field.shortname} className="mb-0">
                    {field.shortname === "omail" ? "Order Email" : null}
                  </label>
                  <input
                    type={field.shortname === "omail" ? "text" : null}
                    name={field.shortname}
                    id={field.shortname}
                    value={field.value}
                    onChange={handleChange_}
                    placeholder={`Enter the ${field.placeholder}`}
                    className="inpSettings"
                  />
                </div>
              ))}
              {error && (
                <>
                  <p className="mb-0 text-danger">{errorMsg}</p>
                </>
              )}
              <button
                className="btn btn-primary rounded-0"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="w-100 my-1">
              <h5 className="mb-0 subHeading">Orders Email</h5>
              <p className="mb-0">
                This is the e-mail address to which you will recieve order
                details when orders are placed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
