import React, { useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "./styles.scss";
import { TipsTable } from "./TipsTable";

export const HelpSystemSettings = () => {
  const [selectedArea, setSelectedArea] = useState("");

  const handleSelect = (e) => {
    setSelectedArea(e.target.value);
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Help System Settings" />
      </div>

      <div className="helpsystemMainDiv">
        <div className="d-flex flex-column w-50 my-2">
          <div className="form-group">
            <label for="exampleFormControlSelect1">
              Select a help area from the list below:
            </label>
            <select
              className="form-control inp"
              id="exampleFormControlSelect1"
              onChange={handleSelect}
            >
              <option selected disabled>
                Select a help area
              </option>
              <option value="registration">Registration</option>
              <option value="search">Search</option>
            </select>
          </div>
        </div>

        {!selectedArea && (
          <h5 className="text-danger subHeading">
            Please the help area from the dropdown
          </h5>
        )}

        {selectedArea && (
          <>
            <div className="currSelectedAreaDiv">
              <h5 className="subHeading mb-2">
                Currently Selected Area : {selectedArea}
              </h5>
            </div>
          </>
        )}

        {selectedArea && (
          <>
            <h5 className="subHeading">Help tips are shown below</h5>

            {/* tips table */}
            <div className="my-2">
              <TipsTable />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
