import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CTAButton } from "../../../../Components/CTAButton";
import Swal from "sweetalert2";

import "./styles.scss";
import {
  GetAllCategoriesService,
} from "../../../../Services/CommonServices";
import {
  GetAllGroupsForCategoriesService,
  GetStockByIdService,
  // GetSubcategoryByCategoryId,
  UpdateStockServices,
} from "../../../../Services/StockServices";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";

export const EditItem = ({ data, searchStockFun, donoshowSearchDiv }) => {
  // const location = useLocation();
  // const navigate = useNavigate();

  // states
  const [stockData, setStockData] = useState([]);
  // const [showCountryList, setShowCountryList] = useState(false);
  // const [showSubjectList, setShowSubjectList] = useState(false);
  // const [showWestIndiesList, setShowWestIndiesList] = useState(false);
  // const [showGreatBritainList, setShowGreatBritainList] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  // const [countriesList, setCountriesList] = useState([]);
  // const [subjectList, setSubjectsList] = useState([]);
  // const [wiList, setWiList] = useState([]);
  // const [gbList, setGbList] = useState([]);

  // const [defaultCountry, setDefaultCountry] = useState({});

  // const [defaultSubject, setDefaultSubject] = useState({});
  // const [defaultwi, setDefaultwi] = useState({});
  // const [defaultgb, setDefaultgb] = useState({});

  // const [keyArr, setKeyArr] = useState([]);

  const [keywordsList, setKeywordsList] = useState([]);
  // console.log("🚀 ~ EditItem ~ keywordsList:", keywordsList)
  const [startDate, setStartDate] = useState("");
  const [startAddDate, setStartAddDate] = useState("");

  // const [editKeywordsArr, setEditKeywordsArr] = useState({
  //   countries: {},
  //   subject: {},
  //   wiList: {},
  //   gbList: {},
  // });

  const [allGroups, setAllGroups] = useState([]);
  // console.log("🚀 ~ EditItem ~ allGroups:", allGroups)

  // const goBack = () => {
  //   navigate(RouteStrings.stockManagement);
  // };

  // use effect
  useEffect(() => {
    getStockById();
    // getAllSubcategoriesByCategoryId();
    // ------
    getAllGroupByCategoryId(data.category);
    // -----------
    // getKeywords();
    getAllCategories();
  }, []);

  const getAllGroupByCategoryId = async (id) => {
    setAllGroups([]);
    let res = await GetAllGroupsForCategoriesService(id);

    if (res?.status === 200 || res?.status === 201) {
      setAllGroups(res?.data?.data);
    } else {
      console.log("error getting all the gruops", res);
    }
  };

  // const getKeywords = async () => {
  //   let keywords = data?.keywords;
  //   let keywordsArr = keywords.split(" ");
  //   setKeyArr(keywordsArr);
  // };

  // const removeAt = (data) => {
  //   let _data = data?.split("@")[1];
  //   _data = _data || "";
  //   return _data;
  // };

  const getStockById = async () => {
    let res = await GetStockByIdService(data.sysid);
    // console.log("🚀 ~ getStockById ~ res:", res)

    if (res.status === 200 || res.status === 201) {
      setStockData(res?.data?.data[0]);
      setFormData({
        ...editFormData,
        author: res?.data?.data[0]?.author,
        author2: res?.data?.data[0]?.author2 || "",
        title: res?.data?.data[0]?.title || "",
        longtitle: res?.data?.data[0]?.longtitle || "",
        publisher: res?.data?.data[0]?.publisher || "",
        pubdate: res?.data?.data[0]?.pubdate || "",
        adddate: res?.data?.data[0]?.adddate || "",
        description: res?.data?.data[0]?.description || "",
        footnote: res?.data?.data[0]?.footnote || "",
        price: res?.data?.data[0]?.price || "",
        keywords: res?.data?.data[0]?.keywords || "",
        category: res?.data?.data[0]?.category || "",
        quantity: res?.data?.data[0]?.quantity || "",
        location: res?.data?.data[0]?.location || "",
        vendor: res?.data?.data[0]?.vendor || "",
        isFeatured: res?.data?.data[0]?.isFeatured || "",
        sysid: res?.data?.data[0]?.sysid,
      });
      let _data = res?.data?.data[0]?.keywords;
      // console.log("🚀 ~ getStockById ~ _data:", _data)
      // const result = {};
      // if (Array.isArray(_data)) {
      //   setKeywordsList([]);
      // } else {
      //   for (const key in _data) {
      //     result[key.toLowerCase()] = _data[key]
      //       .map((item) => item.keywords)
      //       .reduce((acc, val) => acc.concat(val), []);
      //   }
      //   setKeywordsList(result);
      // }
      _data = _data.split(",").map(item => item.trim())
      setKeywordsList(_data)
    } else {
      console.log("error getting the stock data", res);
    }
  };

  const getAllCategories = async () => {
    let res = await GetAllCategoriesService();
    setCategoriesList(res?.data?.data);
  };

  const [editFormData, setFormData] = useState({
    author: stockData?.author || "",
    author2: stockData?.author2 || "",
    title: stockData?.title || "",
    longtitle: stockData?.longtitle || "",
    publisher: stockData?.publisher || "",
    pubdate: stockData?.pubdate || "",
    adddate: stockData?.adddate || "",
    description: stockData?.description || "",
    footnote: stockData?.footnote || "",
    price: stockData?.price || "",
    keywords: stockData?.keywords || "",
    category: stockData?.category || "",
    quantity: stockData?.quantity || "",
    location: stockData?.location || "",
    vendor: stockData?.vendor || "",
    isFeatured: stockData?.isFeatured || "",
    sysid: stockData?.sysid,
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "author") {
      setEditStockError({
        ...editStockError,
        author: false,
      });
      setFormData({
        ...editFormData,
        author: value,
      });
    }
    if (name === "author2") {
      setFormData({
        ...editFormData,
        author2: value,
      });
    }

    if (name === "title") {
      setEditStockError({
        ...editStockError,
        title: false,
      });
      setFormData({
        ...editFormData,
        title: value,
      });
    }
    if (name === "longtitle") {
      setFormData({
        ...editFormData,
        longtitle: value,
      });
    }
    if (name === "publisher") {
      setEditStockError({
        ...editStockError,
        publisher: false,
      });
      setFormData({
        ...editFormData,
        publisher: value,
      });
    }
    if (name === "pubdate") {
      setEditStockError({
        ...editStockError,
        pudate: false,
      });
      setFormData({
        ...editFormData,
        pubdate: value,
      });
    }
    if (name === "adddate") {
      setFormData({
        ...editFormData,
        adddate: value,
      });
    }
    if (name === "description") {
      setEditStockError({
        ...editStockError,
        description: false,
      });
      setFormData({
        ...editFormData,
        description: value,
      });
    }
    if (name === "footnote") {
      setFormData({
        ...editFormData,
        footnote: value,
      });
    }
    if (name === "price") {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
      } else {
        setEditStockError({
          ...editStockError,
          price: false,
        });
        setFormData({
          ...editFormData,
          price: value,
        });
      }
    }

    if (name === "category") {
      setFormData({
        ...editFormData,
        keywords: {},
        category: value,
      });
      getAllGroupByCategoryId(value);
    }

    if (name === "quantity") {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
      } else {
        setEditStockError({
          ...editStockError,
          quantity: false,
        });
        setFormData({
          ...editFormData,
          quantity: value,
        });
      }
    }
    if (name === "location") {
      setEditStockError({
        ...editStockError,
        location: false,
      });
      setFormData({
        ...editFormData,
        location: value,
      });
    }
    if (name === "vendor") {
      setFormData({
        ...editFormData,
        vendor: value,
      });
    }
  };

  // const [subcategoriesList, setSubcategoriesList] = useState([]);

  // const [selectedSubCategories, setSelectedSubCategories] = useState({});

  // const [test, setTest] = useState("");

  const [editStockError, setEditStockError] = useState({
    author: false,
    title: false,
    publisher: false,
    pudate: false,
    description: false,
    price: false,
    keywords: false,
    quantity: false,
    // location: false,
  });

  const [editStockErrorMsg, setEditStockErrorMsg] = useState({
    author: "",
    title: "",
    publisher: "",
    pudate: "",
    description: "",
    price: "",
    keywords: "",
    quantity: "",
    // location: "",
  });

  // const getAllSubcategoriesByCategoryId = async (id) => {
  //   let res = await GetSubcategoryByCategoryId(id);

  //   if (res?.status === 200 || res?.status === 201) {
  //     setSubcategoriesList(res?.data?.data);
  //   } else {
  //     console.log("error getting the subcategories list", res);
  //   }
  // };

  const handleSubcategory = (e) => {
    if (e.target.checked) {
      keywordsList.push(e.target.value);
    } else {
      let _item = keywordsList.filter((item) => item !== e.target.value);
      setKeywordsList(_item);
    }
  };

  // const [checkedItems, setCheckedItems] = useState({});

  // const handleCheckboxChange = (item) => (event) => {
  //   const value = event.target.value;
  //   setCheckedItems((prevState) => ({
  //     ...prevState,
  //     [item]: value,
  //   }));
  // };

  const submitFunUpdate = async (e) => {
    // console.log("FUNCTION TRIGGRED!!!!!!!!!!!!!!!!!!!!!!!!");

    let _valuesarr = keywordsList.join(",");

    // let _valuesarr = Object.values(keywordslistarr).flat(2);

    e.preventDefault();

    let payload = {
      author: editFormData.author,
      author2: editFormData.author2,
      title: editFormData.title,
      longtitle: editFormData.longtitle,
      publisher: editFormData.publisher,
      pubdate: editFormData.pubdate,
      adddate: editFormData.adddate,
      description: editFormData.description,
      footnote: editFormData.footnote,
      price: editFormData.price,
      keywords: _valuesarr,
      category: editFormData.category,
      quantity: editFormData.quantity,
      vendor: editFormData.vendor,
      location: editFormData.location,
      sysid: editFormData.sysid,
    };

    if (
      payload.author === "" &&
      payload.title === "" &&
      payload.description === "" &&
      payload.price === "" &&
      payload.quantity
    ) {
      setEditStockError({
        ...editStockError,
        author: true,
        title: true,
        description: true,
        price: true,
      });

      setEditStockErrorMsg({
        author: "Please enter the author",
        title: "Please enter title",
        description: "Please enter description",
        price: "Please enter price",
      });
    } else if (payload.author === "") {
      setEditStockError({
        ...editStockError,
        author: true,
      });
      setEditStockErrorMsg({
        author: "Please enter the author",
      });
    } else if (payload.title === "") {
      setEditStockError({
        ...editStockError,
        title: true,
      });
      setEditStockErrorMsg({
        title: "Please enter the title",
      });
    } else if (payload.description === "") {
      setEditStockError({
        ...editStockError,
        description: true,
      });
      setEditStockErrorMsg({
        description: "Please enter the description",
      });
    } else if (payload.price === "") {
      setEditStockError({
        ...editStockError,
        price: true,
      });
      setEditStockErrorMsg({
        price: "Please enter the price",
      });
    } else if (payload.price <= 0) {
      setEditStockError({
        ...editStockError,
        price: true,
      });
      setEditStockErrorMsg({
        price: "Price cannot not be less than 0",
      });
    } else if (payload.quantity === "") {
      setEditStockError({
        ...editStockError,
        quantity: true,
      });
      setEditStockErrorMsg({
        quantity: "Please enter the quantity",
      });
    } else if (payload.quantity < 0) {
      setEditStockError({
        ...editStockError,
        quantity: true,
      });
      setEditStockErrorMsg({
        quantity: "Quantity cannot be less than 0",
      });
    } else {
      // console.log("🚀 ~ submitFunUpdate ~ payload:", payload)
      let res = await UpdateStockServices(payload);
      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the stock successfully`,
        });
        searchStockFun();
        donoshowSearchDiv(false);
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleSelectYear = (date) => {
    setStartDate(date);
    let _year = date.getFullYear();

    setFormData({
      ...editFormData,
      pubdate: _year,
    });
  };

  const handleSelectAddDate = (date) => {
    const formattedDate = date.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    setStartAddDate(date);
    setFormData({
      ...editFormData,
      adddate: formattedDate,
    });
  };

  return (
    <div>
      <div className="card p-3">
        <div className="addnewitemdiv">
          <form className="newItemForm">
            <div className="item_">
              <label htmlFor="">
                Author <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="author"
                id="author"
                value={editFormData.author}
                onChange={handleChange}
                placeholder="Enter author name"
                className="input_"
              />
              {editStockError.author && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.author}</p>
                </>
              )}
            </div>

            <div className="item_">
              <label htmlFor="">Author 2 </label>
              <input
                type="text"
                name="author2"
                id="author2"
                value={editFormData.author2}
                onChange={handleChange}
                placeholder="Enter author 2 name"
                className="input_"
              />
            </div>

            <div className="item_">
              <label htmlFor="">
                Title <span className="_imp">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter title"
                className="input_"
                name="title"
                id="title"
                value={editFormData.title}
                onChange={handleChange}
              />
              {editStockError.title && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.title}</p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Longtitle</label>
              <input
                type="text"
                name="longtitle"
                id="longtitle"
                value={editFormData.longtitle}
                onChange={handleChange}
                placeholder="Enter longtitle"
                className="input_"
              />
            </div>
            <div className="item_">
              <label htmlFor="">Publisher</label>
              <input
                type="text"
                name="publisher"
                id="publisher"
                value={editFormData.publisher}
                onChange={handleChange}
                placeholder="Enter publisher name"
                className="input_"
              />
              {editStockError.publisher && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.publisher}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Pubdate</label>

              <DatePicker
                selected={startDate}
                name="date"
                value={editFormData.pubdate}
                onChange={(date) => handleSelectYear(date)}
                className="input_"
                dateFormat="yyyy"
                placeholderText="Select Year"
                showYearPicker
                yearDropdownItemNumber={10}
              />
              {editStockError.pudate && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.pudate}</p>
                </>
              )}
            </div>

            <div className="dateitem_">
              <label htmlFor="">Add Date</label>

              <DatePicker
                selected={startAddDate}
                name="date"
                value={editFormData.adddate}
                onChange={(date) => handleSelectAddDate(date)}
                className="inputTag"
                placeholderText="Select date"
              />
            </div>

            <div className="item_">
              <label htmlFor="">
                Description <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="description"
                id="description"
                value={editFormData.description}
                onChange={handleChange}
                placeholder="Enter description"
                className="input_"
              />
              {editStockError.description && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.description}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Footnote</label>
              <input
                type="text"
                name="footnote"
                id="footnote"
                value={editFormData.footnote}
                onChange={handleChange}
                placeholder="Enter footnote"
                className="input_"
              />
            </div>
            <div className="item_">
              <label htmlFor="">
                Price <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="price"
                id="price"
                value={editFormData.price}
                onChange={handleChange}
                placeholder="Enter price"
                className="input_"
              />
              {editStockError.price && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.price}</p>
                </>
              )}
            </div>

            <div className="my-2">
              <div class="form-group">
                <label for="exampleFormControlSelect1">
                  Select a category from the list below
                </label>
                <select
                  class="form-control input_"
                  value={editFormData.category}
                  name="category"
                  onChange={handleChange}
                  id="exampleFormControlSelect1"
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {categoriesList?.map((item) => {
                    return (
                      <>
                        <option key={item?.reference} value={item?.reference}>
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! groups !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            <div className="my-2">
              <div class="form-group">
                {allGroups?.map((mapVal) => Object.keys(mapVal))
                  ?.flat(1)
                  ?.map((item) => {
                    return (
                      <>
                        <p>
                          <strong>{item}</strong>
                        </p>

                        <div className="my-2">
                          <div className="row">
                            {Object?.values(
                              allGroups?.find((findVal) => findVal[item])
                            )
                              ?.flat(1)
                              ?.map((mapval) => {
                                return (
                                  <>
                                    <div className="col-md-2">
                                      <div className="d-flex align-items-center">
                                        <Form>
                                          <div className="mb-3">
                                            <Form.Check
                                              type="checkbox"
                                              id="checkbox"
                                              label={mapval?.name}
                                              value={mapval?.reference}
                                              onChange={(e) =>
                                                handleSubcategory(e)
                                              }
                                              defaultChecked={
                                                keywordsList.includes(mapval.reference)
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    );
                  })}

                {/* {allGroups
                  ?.map((mapVal) => Object?.keys(mapVal))
                  ?.flat(1)
                  ?.map((item) => {
                    return (
                      <>
                        <div className="my-2">
                          <CTAButton
                            name={item}
                            handleClick={(e) => e.preventDefault()}
                          />
                        </div>

                        <div className="w-75">
                          <Select
                            options={Object?.values(
                              allGroups?.find((findVal) => findVal[item])
                            )
                              ?.flat(1)
                              ?.map((mapVal) => ({
                                label: mapVal?.name,
                                value: mapVal?.keywords,
                              }))}
                            defaultValue={
                              Object?.values(
                                editFormData?.keywords?.[item] || []
                              )?.map((mapVal) => ({
                                label: mapVal?.name,
                                value: mapVal?.keywords,
                              })) || []
                            }
                            onChange={handleSubcategory(item)}
                            isMulti
                          />
                        </div>
                      </>
                    );
                  })} */}
                {/* {allGroups?.map((mapVal) => Object.keys(mapVal))} */}
              </div>
            </div>

            <div className="item_">
              <label htmlFor="">
                Quantity <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="quantity"
                id="quantity"
                value={editFormData.quantity}
                onChange={handleChange}
                placeholder="Enter quatity"
                className="input_"
              />
              {editStockError.quantity && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.quantity}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Location</label>
              <input
                type="text"
                name="location"
                id="location"
                value={editFormData.location}
                onChange={handleChange}
                placeholder="Enter location"
                className="input_"
              />

              {editStockError.location && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.location}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Vendor</label>
              <input
                type="text"
                name="vendor"
                id="vendor"
                value={editFormData.vendor}
                onChange={handleChange}
                placeholder="Enter vendor"
                className="input_"
              />
            </div>

            <div className="submitbtn my-2">
              <CTAButton
                name="Submit"
                handleClick={submitFunUpdate}
                id="submitbtn"
              />
            </div>
          </form>
        </div>
      </div>

      {/* update category modal */}

      <div
        class="modal fade"
        id="updatecategory"
        tabindex="-1"
        aria-labelledby="updatecategoryLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updatecategoryLabel">
                Category to add to
              </h5>
              <button
                type="button"
                class="close"
                id="modalclosebtnicon"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label htmlFor="">Please select the category</label>

                <select
                  class="form-control input_"
                  id="exampleFormControlSelect1"
                >
                  <option>Category one</option>
                  <option>Category two</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="footermodalclosebtn"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-info"
                id="modalupdatecategory"
              >
                Update Category
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add image modal */}

      <div
        class="modal fade"
        id="uploadImage"
        tabindex="-1"
        aria-labelledby="uploadImageLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="uploadImageLabel">
                Add Image to
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="modalcloseaddimage"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <div className="d-flex flex-column">
                  <label htmlFor="">Please select an image</label>

                  <input type="file" name="image" id="image" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                id="addimageclosemodalbtn"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-info"
                id="uploadaddimagemodalbtn"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
