import React, { useEffect, useState } from "react";
import { RouteStrings } from "./RouteStrings";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LoginComponent } from "../Pages/AuthScreens/LoginScreen";
import { SignUpComponent } from "../Pages/AuthScreens/SignupScreen";
import { TwoFactorAuth } from "../Pages/AuthScreens/TwoFactorAuth";
import { ForgotPassword } from "../Pages/AuthScreens/ForgotPasswordScreen";
import { ResetPassword } from "../Pages/AuthScreens/ResetPassword";
import {
  pennymead_ClearLocal,
  pennymead_GetLocal,
} from "../Utils/LocalStorage";
import { Dashboard } from "../Pages/AdminScreens/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "../Components/Navbar";
import { AdminSideBarComponent } from "../Components/Sidebar";
import { StockManagement } from "../Pages/AdminScreens/StockManagement";
import { CustomerManagement } from "../Pages/AdminScreens/CustomerManagement";
import { OrderManagement } from "../Pages/AdminScreens/OrderManagement";
import { MailingListManagement } from "../Pages/AdminScreens/MailingListManagement";
import { ShopOnBehalf } from "../Pages/AdminScreens/ShopOnBehalf";
import { PageManagement } from "../Pages/AdminScreens/PageManagement";
import { CategoryManagement } from "../Pages/AdminScreens/CategoryManagement";
import { PostageManagement } from "../Pages/AdminScreens/PostageManagement";
import { GlobalSettings } from "../Pages/AdminScreens/GlobalSettings";
import { HolidaySettings } from "../Pages/AdminScreens/HolidaySettings";
import { HelpSystemSettings } from "../Pages/AdminScreens/HelpSystemSettings";
import { AdminManagement } from "../Pages/AdminScreens/AdminManagement";
import { AddNewItem } from "../Pages/AdminScreens/StockManagement/AddNewItem";
import { EditItem } from "../Pages/AdminScreens/StockManagement/EditItem";
import { AddNewCustomer } from "../Pages/AdminScreens/CustomerManagement/AddNewCustomer";
import { EditCustomer } from "../Pages/AdminScreens/CustomerManagement/EditCustomer";
import { ViewCustomer } from "../Pages/AdminScreens/CustomerManagement/ViewCustomer";
import { GraphicalSalesAnalysis } from "../Pages/AdminScreens/OrderManagement/GraphicalSalesAnalysis";
import { ViewOrder } from "../Pages/AdminScreens/OrderManagement/ViewOrder";
import { AdminInfo } from "../Pages/AdminScreens/AdminInfo";
import { ContactUsAndTerms } from "../Pages/AdminScreens/ContactUsAndTerms";
import { AdminProductDetails } from "../Pages/AdminScreens/AdminProductDetails";
import { AdminNoteManagement } from "../Pages/AdminScreens/AdminNotesManagement";
import { ViewOrderSummaryPage } from "../Pages/AdminScreens/OrderManagement/ViewOrderSummaryPage";
import { OrderInvoicePage } from "../Pages/AdminScreens/OrderManagement/InvoicePage";
import { NotFoundPage } from "../Pages/404Page";
import { BidsAndTenderComponent } from "../Pages/AdminScreens/OrderManagement/BidsAndTenderPage";
import { ViewPackedOrderById } from "../Pages/AdminScreens/OrderManagement/ViewOrder/ViewPackedOrderById";
import { OrderInvoicePagePacked } from "../Pages/AdminScreens/OrderManagement/InvoicePagePacked";
import { CreatePrintedCatalog } from "../Pages/AdminScreens/StockManagement/CreatePrintedCatalog";
import { loggedOut } from "../Store/reducers/reducers";
import { Test } from "../Pages/AdminScreens/Dashboard/Test";
import { PrintInvoicedData } from "../Pages/AdminScreens/OrderManagement/OrderTable/PrintInvoicedData";

export const RouteComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Define the function you want to trigger after 5 seconds
    const myFunction = () => {
      // console.log("Function triggered after 5 seconds");
      // You can perform any actions you want here
    };
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

    // Set a timeout for 5 seconds (5000 milliseconds)
    const timeoutId = setTimeout(myFunction, 5000);
    const logoutfun = setTimeout(logout, twentyFourHoursInMilliseconds);

    // Cleanup the timeout if the component unmounts before it triggers
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(logoutfun);
    };
  }, []);

  const logout = () => {
    dispatch(loggedOut());
    pennymead_ClearLocal();
    navigate(RouteStrings.login, { replace: true });
  };

  return (
    <div>
      <Routes>
        <Route path={RouteStrings.login} element={<LoginComponent />} />
        <Route path={RouteStrings.register} element={<SignUpComponent />} />
        <Route path={RouteStrings.twofactorAuth} element={<TwoFactorAuth />} />
        <Route
          path={RouteStrings.forgotPassword}
          element={<ForgotPassword />}
        />
        <Route path={RouteStrings.resetPassword} element={<ResetPassword />} />

        <Route path={RouteStrings.dashboard} element={<Dashboard />}>
          <Route index element={<Test />} />
          <Route
            path={RouteStrings.stockManagement}
            element={<StockManagement />}
          />
          <Route
            path={RouteStrings.createPrintedCatalog}
            element={<CreatePrintedCatalog />}
          />
          <Route
            path={RouteStrings.customerManagement}
            element={<CustomerManagement />}
          />
          <Route
            path={RouteStrings.orderManagement}
            element={<OrderManagement />}
          />
          {/* <Route path={RouteStrings.viewOrder} element={<ViewOrder />} /> */}


          {/* <Route path={RouteStrings.viewOrder} element={<ViewOrder />} /> */}

          <Route
            path={RouteStrings.printIvoicedOrders}
            element={<PrintInvoicedData />}
          />
          <Route
            path={RouteStrings.orderInvoicePage}
            element={<OrderInvoicePage />}
          />
          <Route
            path={RouteStrings.orderPackedInvoicePage}
            element={<OrderInvoicePagePacked />}
          />
          <Route
            path={RouteStrings.bidsandTenderOrder}
            element={<BidsAndTenderComponent />}
          />

          <Route
            path={RouteStrings.viewOrderSummary}
            element={<ViewOrderSummaryPage />}
          />
          <Route
            path={RouteStrings.orderViewInvoicePacked}
            element={<ViewPackedOrderById />}
          />
          <Route
            path={RouteStrings.graphicalSalesAnalysis}
            element={<GraphicalSalesAnalysis />}
          />
          <Route
            path={RouteStrings.mailingListManagement}
            element={<MailingListManagement />}
          />
          <Route path={RouteStrings.shoponBehalf} element={<ShopOnBehalf />} />
          <Route
            path={RouteStrings.pageManagement}
            element={<PageManagement />}
          />
          <Route
            path={RouteStrings.categotyManagement}
            element={<CategoryManagement />}
          />
          <Route
            path={RouteStrings.postageManagement}
            element={<PostageManagement />}
          />
          <Route
            path={RouteStrings.globalSettings}
            element={<GlobalSettings />}
          />
          <Route
            path={RouteStrings.holidaySettings}
            element={<HolidaySettings />}
          />
          <Route
            path={RouteStrings.helpSystemSettings}
            element={<HelpSystemSettings />}
          />
          <Route
            path={RouteStrings.adminManagement}
            element={<AdminManagement />}
          />
          <Route path={RouteStrings.addNewItem} element={<AddNewItem />} />
          <Route path={RouteStrings.editItem} element={<EditItem />} />
          <Route
            path={RouteStrings.addNewCustomer}
            element={<AddNewCustomer />}
          />
          <Route path={RouteStrings.editCustomer} element={<EditCustomer />} />
          <Route path={RouteStrings.viewCustomer} element={<ViewCustomer />} />
          <Route path={RouteStrings.adminInfo} element={<AdminInfo />} />
          <Route
            path={RouteStrings.contactus}
            element={<ContactUsAndTerms />}
          />
          <Route
            path={RouteStrings.adminProductDetails}
            element={<AdminProductDetails />}
          />
          <Route
            path={RouteStrings.adminNoteManagement}
            element={<AdminNoteManagement />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
};
