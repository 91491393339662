import React from "react";
import "./styles.scss";

export const AuthBannerComponent = ({ screenHeading }) => {
  return (
    <div className="banner_main">
      <div className="heading_main">
        <h1 className="_heading">{screenHeading}</h1>
      </div>
    </div>
  );
};
