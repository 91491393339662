import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../Routes/RouteStrings";
import Swal from "sweetalert2";
import { AuthBannerComponent } from "../../../Components/AuthBanner";
import { resetPasswordService } from "../../../Services/AuthServices";
import { Images } from "../../../Assets/Images/imageslist";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeConfirmPassword, setPasswordTypeConfirmPassword] =
    useState("password");
  const [showHide, setShowHide] = useState(true);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(true);

  const [error, setError] = useState({
    password: false,
    confirmPassword: false,
    isPasswordSame: false,
    conditions: false,
    conditionsConfirmPassword: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    password: "",
    confirmPassword: "",
    isPasswordSame: "",
    conditions: "",
    conditionsConfirmPassword: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "password") {
      setError({
        ...error,
        password: false,
        conditions: false,
        isPasswordSame: false,
      });
      setPassword(value);
    }

    if (name === "confirmpassword") {
      setError({
        ...error,
        isPasswordSame: false,
        conditionsConfirmPassword: false,
        confirmPassword: false,
      });
      setConfirmPassword(value);
    }
  };

  function validtePassword(password) {
    // console.log(
    //   "🚀 ~ file: index.js:65 ~ validtePassword ~ password:",
    //   password
    // );
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
      password
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === "" && confirmPassword === "") {
      setError({
        ...error,
        password: true,
        confirmPassword: true,
      });
      setErrorMsg({
        password: "Please enter the password",
        confirmPassword: "Please enter the confirm password",
      });
    } else if (password === "") {
      setError({
        ...error,
        password: true,
      });
      setErrorMsg({
        password: "Please enter the password",
      });
    } else if (confirmPassword === "") {
      setError({
        ...error,
        confirmPassword: true,
      });
      setErrorMsg({
        confirmPassword: "Please enter the confirm password",
      });
    } else if (!validtePassword(password)) {
      // console.log("true---->");
      setError({
        ...error,
        conditions: true,
      });

      setErrorMsg({
        conditions:
          "Password must contain at least 8 characters,1 upper case, 1 lower case, 1 special character and 1 number",
      });
    } else if (!validtePassword(confirmPassword)) {
      setError({
        ...error,
        conditionsConfirmPassword: true,
      });

      setErrorMsg({
        conditionsConfirmPassword:
          "Confirm Password must contain at least 8 characters,1 upper case, 1 lower case, 1 special character and 1 number",
      });
    } else if (password !== confirmPassword) {
      setError({
        ...error,
        isPasswordSame: true,
      });
      setErrorMsg({
        isPasswordSame:
          "Password and confirm password are not same, please verify",
      });
    } else {
      let payload = {
        new_password: password,
        confirmPWD: confirmPassword,
      };

      let response = await resetPasswordService(payload, token);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          // title: res?.status,
          text: "Password has been successfully changed",
        });
        navigate(RouteStrings.login);
      } else if (response.response.status === 400) {
        Swal.fire({
          icon: "error",
          text: `Error, ${response.response.data.message}`,
        });
      }

      // console.log(
      //   "🚀 ~ file: index.js:80 ~ handleSubmit ~ response:",
      //   response
      // );
    }
  };

  const showHideFun = () => {
    // console.log("clicked");
    if (!showHide) {
      setShowHide(true);
      setPasswordType("password");
    } else {
      setShowHide(false);
      setPasswordType("text");
    }
  };
  const showHideFunConfirmPassword = () => {
    if (!showHideConfirmPassword) {
      setShowHideConfirmPassword(true);
      setPasswordTypeConfirmPassword("password");
    } else {
      setShowHideConfirmPassword(false);
      setPasswordTypeConfirmPassword("text");
    }
  };

  return (
    <div className="container-fluid">
      <div>
        <div className="row">
          <div className="col-md-6">
            <AuthBannerComponent screenHeading="Pennymead.com" />
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="card login_main px-3">
                <h1 className="heading mb-2">Reset Password</h1>
                {/* <p className="_text">Reset your password.</p> */}

                <div className="login_form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_content mb-2 position-relative w-75">
                      <label htmlFor="">New Password</label>
                      <input
                        type={passwordType}
                        name="password"
                        id="password"
                        onChange={handleChange}
                        className="input w-100"
                        placeholder="Please enter your password"
                      />
                      {showHide ? (
                        <img
                          src={Images.hide}
                          alt=""
                          className="showHideIcon"
                          onClick={showHideFun}
                        />
                      ) : (
                        <img
                          src={Images.show}
                          alt=""
                          className="showHideIcon"
                          onClick={showHideFun}
                        />
                      )}
                      {error.password && (
                        <>
                          <p className="text-danger mb-0">
                            {errorMsg.password}
                          </p>
                        </>
                      )}
                      {error.conditions && (
                        <>
                          <p className="text-danger mb-0">
                            {errorMsg.conditions}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="form_content mb-2 position-relative w-75">
                      <label htmlFor="">Confirm Password</label>
                      <input
                        type={passwordTypeConfirmPassword}
                        name="confirmpassword"
                        id="confirmpassword"
                        onChange={handleChange}
                        className="input w-100"
                        placeholder="Please confirm your password"
                      />
                      {showHideConfirmPassword ? (
                        <img
                          src={Images.hide}
                          alt=""
                          className="showHideIcon"
                          onClick={showHideFunConfirmPassword}
                        />
                      ) : (
                        <img
                          src={Images.show}
                          alt=""
                          className="showHideIcon"
                          onClick={showHideFunConfirmPassword}
                        />
                      )}
                      {error.confirmPassword && (
                        <>
                          <p className="text-danger mb-0">
                            {errorMsg.confirmPassword}
                          </p>
                        </>
                      )}

                      {error.isPasswordSame && (
                        <>
                          <p className="text-danger mb-0">
                            {errorMsg.isPasswordSame}
                          </p>
                        </>
                      )}

                      {error.conditionsConfirmPassword && (
                        <>
                          <p className="text-danger mb-0">
                            {errorMsg.conditionsConfirmPassword}
                          </p>
                        </>
                      )}
                    </div>

                    <div className="mb-2">
                      <button className="btn btn-primary btn_login">
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
