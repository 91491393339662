export const RouteStrings = {
  login: "/login",
  register: "/signup",
  twofactorAuth: "/two-factor-auth",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  dashboard: "/",
  board: "dashboard",
  stockManagement: "stock-management",
  createPrintedCatalog: "create-printed-catalog",
  customerManagement: "customer-management",
  orderManagement: "order-management",
  viewOrder: "view-order",
  printIvoicedOrders: "/print-invoiced-orders",
  mailingListManagement: "mailing-list-management",
  shoponBehalf: "shop-on-behalf",
  pageManagement: "page-management",
  categotyManagement: "category-management",
  postageManagement: "postage-management",
  globalSettings: "global-settings",
  holidaySettings: "holiday-settings",
  helpSystemSettings: "help-system-settings",
  adminManagement: "admin-management",
  addNewItem: "add-new-item",
  editItem: "edit-item",
  addNewCustomer: "add-new-customer",
  viewCustomer: "view-customer",
  editCustomer: "edit-customer",
  graphicalSalesAnalysis: "graphical-sales-analysis",
  adminInfo: "admin-info",
  contactus: "contact-us",
  adminProductDetails: "product-details",
  adminNoteManagement: "note-management",
  viewOrderSummary: "view-order-summary",
  bidsandTenderOrder: "bids-and-tender",
  orderInvoicePage: "order-invoice",
  orderViewInvoicePacked: "order-packed-invoice",
  orderPackedInvoicePage: "order-invoice-page-packed",
  notFoundPage: "not-found",
};
