import React from "react";
import { Images } from "../../Assets/Images/imageslist";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const BreadCrumb = ({ routename, gotoBackFun, isBack }) => {
  const navigate = useNavigate();

  const goback = () => {
    gotoBackFun();
  };

  return (
    <div className="mb-3">
      {isBack && (
        <img
          src={Images.back}
          alt=""
          id="back"
          className="backBtn my-1"
          onClick={goback}
        />
      )}
      <div className="d-flex align-items-center">
        <h4 className="title">Pennymead.com</h4>
        <span className="font-weight-bold mx-2 sym"> {">"} </span>
        <h4 className="route">{routename}</h4>
      </div>
    </div>
  );
};
