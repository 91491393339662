import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { GetAllCategoriesService } from "../../../../Services/CommonServices";
import Form from "react-bootstrap/Form";
import {
  CreatePrintedCatalogService,
  GetSubcategoryByCategoryId,
} from "../../../../Services/StockServices";
import { Paragraph, Document, Packer, TextRun, BorderStyle } from "docx";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { configURL } from "../../../../Services/configuration";

export const CreatePrintedCatalog = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  const [selectAll, setSelectAll] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [allSubcategories, setAllSubCategories] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const navigate = useNavigate();

  const gobacktoMain = () => {
    navigate(`/${RouteStrings.stockManagement}`);
  };

  const getAllCategories = async () => {
    let res = await GetAllCategoriesService();
    if (res?.status === 200 || res?.status === 201) {
      setAllCategories(res?.data?.data);
    } else {
      // console.log("error getting all the categories");
    }
  };

  const handleChange = (e) => {
    const selectedRadioValue = e.target.value;
    setSelectedValue(selectedRadioValue);
    getAllSubcategoriesByCategoryId(selectedRadioValue);
    setSelectAll(false);
    setSelectedOrders([]);
  };

  const getAllSubcategoriesByCategoryId = async (id) => {
    let res = await GetSubcategoryByCategoryId(id);
    if (res?.status === 201 || res?.status === 200) {
      setAllSubCategories(res?.data?.data);
    } else {
      // console.log("error getting all the subcategories");
    }
  };

  const handleChangeSubcategories = (event) => {
    const { value, checked } = event.target;
    setSelectedOrders((prevSelectedOrders) => {
      if (checked) {
        // If the checkbox is checked, add the value to the selected orders
        return [...prevSelectedOrders, value];
      } else {
        // If the checkbox is unchecked, remove the value from selected orders
        return prevSelectedOrders.filter((item) => item !== value);
      }
    });
  };

  const generate = (info) => {
    let values = Object.values(info);

    let combinedArr = values.flat(5);

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: combinedArr
            .map((item, index) => {
              const paragraphs = [];
              // Display only specific properties
              const propertiesToShow = [
                "author",
                "title",
                "publisher",
                "pubdate",
                "description",
              ];
              for (const key of propertiesToShow) {
                const value = item[key];
                paragraphs.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${key}: "${value}",`,
                      }),
                    ],
                  })
                );
              }
              // Add an empty line between objects
              if (index < combinedArr.length - 1) {
                paragraphs.push(new Paragraph(""));
              }
              return paragraphs;
            }).flat(),
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "printed_catalog.docx");
    });
  };

  const createPrintedCatalogFunciton = async () => {
    let payload = {
      category: selectedValue,
      keywords: selectedOrders,
    };

    if (payload.keywords.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select the subcategories`,
      });
    } else {
      // let response = await CreatePrintedCatalogService(payload);

      let response = await postRequest(configURL.get_printed_catalog, payload);

      if (response?.status === 200 || response?.status === 201) {
        generate(response?.data?.data || []);
      } else if (
        response?.response?.status === 404 ||
        response?.response?.status === 400 ||
        response?.response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${response?.response?.data?.message || "No stock found"}`,
        });
      } else {
        // console.log(
        //   "error getting the printed catalog data---------->",
        //   response
        // );
      }
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedOrders([]);
    } else {
      const newSelectedItems = allSubcategories.map((item) => item.reference);
      setSelectedOrders(newSelectedItems);
    }
    setSelectAll(!selectAll);
  };
  return (
    <div>
      <div>
        <div>
          <BreadCrumb
            routename="Create Printed Catalog"
            gotoBackFun={gobacktoMain}
            isBack={true}
          />
        </div>

        {/* display all the categories and when clicked on the radio button show the related sub categories */}

        <div className="allcategoriesandsubcategories">
          <div className="row">
            <div className="col-md-4">
              <h6 className="my-2">Categories</h6>
              <div className="allcategories">
                {allCategories?.map((item) => {
                  return (
                    <>
                      <div className="mb-3">
                        <Form>
                          <Form.Check
                            type="radio"
                            name="radioGroup"
                            id={item?.reference}
                            value={item?.reference}
                            onChange={(e) => handleChange(e)}
                            checked={item?.reference === selectedValue}
                            label={item?.name}
                            className="mr-2"
                          />
                        </Form>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="col-md-8">
              <div className="subcategoriesdiv">
                <div className="d-flex align-items-center justify-content-between">
                  {allSubcategories?.length === 0 && (
                    <>
                      <h6 className="text-danger">
                        Please select the category
                      </h6>
                    </>
                  )}
                  {allSubcategories?.length > 0 && (
                    <>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <h6 className="my-2 ">Subcategories</h6>
                        </div>

                        <div className="d-flex">
                          <button
                            id="selectalltoggle"
                            className="mr-2 btn btn-info rounded-0 my-2"
                            onClick={toggleSelectAll}
                          >
                            {selectAll ? "Unselect All" : "Select All"}
                          </button>

                          <button
                            id="createprintedcatalog"
                            className="btn btn-primary rounded-0 my-2"
                            onClick={createPrintedCatalogFunciton}
                          >
                            Create Printed Catalog
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  {allSubcategories?.length > 0 && (
                    <>
                      {allSubcategories?.map((item) => {
                        const isChecked = selectedOrders.includes(
                          item.reference
                        );
                        return (
                          <>
                            <div className="col-md-3">
                              <Form>
                                <Form.Check
                                  type="checkbox"
                                  id={item.reference}
                                  label={item.name}
                                  name="checkbox"
                                  value={item.reference}
                                  onChange={handleChangeSubcategories}
                                  checked={isChecked}
                                />
                              </Form>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
