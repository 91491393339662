import React, { useEffect, useState } from "react";

export const PrintPage = React.forwardRef((props, ref) => {
  const { rows, columns, data, column_row_ref } = props;
  let row = rows;
  let column = columns;

  if (row < 1) {
    row = 1;
  }

  if (row > 7) {
    row = 7;
  }

  if (column < 1) {
    column = 1;
  }

  if (column > 3) {
    column = 3;
  }

  // console.log(
  //   "🚀 ~ file: PrintPage.js:5 ~ PrintPage ~ column_row_ref:",
  //   column_row_ref
  // );

  // console.log("🚀 ~ file: PrintPage.js:11 ~ PrintPage ~ property:", property);
  // console.log(
  //   "🚀 ~ file: PrintPage.js:5 ~ PrintPage ~ replace_index:",
  //   column_row_ref[property]
  // );

  // const calculatePosition = (row, column) => {
  //   if (row < 1) {
  //     row = 1;
  //   }

  //   if (row > 7) {
  //     row = 7;
  //   }

  //   if (column < 1) {
  //     column = 1;
  //   }

  //   if (column > 3) {
  //     column = 3;
  //   }

  //   // Define the dimensions of the A4 sheet in millimeters
  //   const sheetWidth = 210; // A4 width
  //   const sheetHeight = 297; // A4 height

  //   // Define margins in millimeters
  //   const topMargin = 15;
  //   const bottomMargin = 5;
  //   const sideMargin = 11;

  //   // Calculate the width and height of the content area, considering margins
  //   const contentWidth = sheetWidth - sideMargin;
  //   const contentHeight = sheetHeight - topMargin - bottomMargin;

  //   // Calculate the width and height of each cell within the content area
  //   const cellWidth = contentWidth / 3; // 3 columns
  //   const cellHeight = contentHeight / 7; // 7 rows

  //   const position = {
  //     top:
  //       // row === 7
  //       //   ? `${sheetHeight - bottomMargin - cellHeight}mm`
  //       `${topMargin + (row - 1) * cellHeight}mm`,
  //     left: `${sideMargin + (column - 1) * cellWidth}mm`,
  //   };

  //   return position;
  // };

  // const imagePosition = calculatePosition(rows, columns);

  // useEffect(() => {
  let display_positions = new Array(21).fill("");
  // let display_positions = new Array(21).fill(data);

  let property = [`${row}_${column}`];
  let replace_index = column_row_ref[property];

  display_positions.splice(replace_index, 1, data);

  // console.log(display_positions);
  // }, [rows, columns]);

  return (
    <div
      ref={ref}
      className="row"
      style={{
        flex: 1,
        justifyContent: "space-between",
        padding: "15mm 0.5mm",
        alignItems: "stretch",
        // backgroundColor: "pink",
      }}
    >
      {display_positions.map((val) => (
        <div
          className="col-4"
          style={{
            height: 211.5,
            // border: "1px solid blue",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {val && Object.keys(data).length > 0 && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {data?.title !== "" &&
                data.forename !== "" &&
                data.name !== "" && (
                  <>
                    <div>
                      <span className="print_address"></span>
                      <span className="print_address">{data?.title} </span>{" "}
                      <span className="print_address">{data?.forename}</span>{" "}
                      <span className="print_address"> {data?.name}</span>
                    </div>
                  </>
                )}
              {data?.title === "" &&
                data?.forename === "" &&
                data.name !== "" && (
                  <>
                    <p className="print_address">{data?.name}</p>
                  </>
                )}

              {data.address1 && (
                <p className="print_address">{data.address1}</p>
              )}
              {data.address2 && (
                <p className="print_address">{data.address2}</p>
              )}
              {data.town && <p className="print_address">{data.town}</p>}
              {data.county && <p className="print_address">{data?.county}</p>}
              {data.state && <p className="print_address">{data.state}</p>}

              {data.postcode && (
                <p className="print_address">{data.postcode}</p>
              )}
              {data.country && (
                <p className="print_address">{data.country_name}</p>
              )}
              {/* {
              data.hphone && <p className="print_address">{data.hphone}</p>
              } */}
            </div>
          )}
        </div>
      ))}

      {/* {Object.keys(data).length > 0 && (
        <>
          {data?.title !== "" && data.forename !== "" && data.name !== "" && (
            <>
              <div>
                <span className="print_address"></span>
                <span className="print_address">{data?.title} </span>{" "}
                <span className="print_address">{data?.forename}</span>{" "}
                <span className="print_address"> {data?.name}</span>
              </div>
            </>
          )}
          {data?.title === "" && data?.forename === "" && data.name !== "" && (
            <>
              <p className="print_address">{data?.name}</p>
            </>
          )}

          {data.address1 && <p className="print_address">{data.address1}</p>}
          {data.address2 && <p className="print_address">{data.address2}</p>}
          {data.town && <p className="print_address">{data.town}</p>}
          {data.county && <p className="print_address">{data?.county}</p>}
          {data.state && <p className="print_address">{data.state}</p>}

          {data.postcode && <p className="print_address">{data.postcode}</p>}
          {data.country && <p className="print_address">{data.country_name}</p>}
          {data.hphone && <p className="print_address">{data.hphone}</p>}
        </>
      )} */}
    </div>
  );
});
