import React from "react";
import "./styles.scss";

export const NotFoundPage = () => {
  return (
    <>
      <div className="d-flex justify-content-center flex-column">
        <h1>404</h1>
        <h3>Page not found.</h3>
      </div>
    </>
  );
};
