import React from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "../../../Assets/Styles/common.scss";

export const AdminProductDetails = () => {
  return (
    <div>
      <div>
        <BreadCrumb routename="Product Details" />
      </div>

      {/* // ! select the category from the dropdown and then you need to show the dropdown for the books list in the category and then after selecting the book
    //  ! then you need to show the header description text editor(refer the design for the product description)*/}

      <div className="my-3">
        <h5 className="subHeading">Product Details</h5>
      </div>
    </div>
  );
};
