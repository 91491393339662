import {
  getRequest,
  postRequest,
  putRequest,
  deleteReqest,
  patchRequest,
} from "./MainBaseServices";

import axios from "axios";

import { configURL } from "./configuration";

export const GetAllPostageDetailsService = async () => {
  try {
    let response = await getRequest(configURL.get_all_postage_details);

    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: PostageManagementServices.js:18 ~ GetAllPostageDetailsService ~ error:",
    //   error
    // );
    return error;
  }
};

export const UpdatePostageServices = async (payload) => {
  try {
    let response = await postRequest(configURL.update_postage_rate, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: PostageManagementServices.js:32 ~ UpdatePostageServices ~ error:",
      error
    );
    return error;
  }
};
