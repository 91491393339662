import {
    getRequest,
    postRequest,
    putRequest,
    deleteReqest,
    patchRequest,
} from "./MainBaseServices";

// import axios from "axios";

import { configURL } from "./configuration";

// export const GetAllOrdersService = async (pageno) => {
//     try {
//         let response = await getRequest(`${configURL.get_all_orders}/${pageno}/`);
//         // console.log("🚀 ~ file: OrderServices.js:17 ~ GetAllOrdersService ~ response:", response)
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:18 ~ GetAllOrdersService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SearchOrderService = async (payload) => {
//     try {
//         let response = await postRequest(configURL.search_order, payload);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:31 ~ SearchCustomerService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetAllCompletedOrdersService = async (pageno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_all_completedOrders}/${pageno}/`
//         );

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:48 ~ GetAllCompletedOrdersService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// // // getting all order awating payment
export const GetAllOrdersAwatingInvoiceService = async (pageno) => {
    try {
        let response = await getRequest(
            `${configURL.get_all_ordersAwatingInvoice}/${pageno}/`
        );
        return response;
    } catch (error) {
        console.log(
            "🚀 ~ file: OrderServices.js:64 ~ GetAllOrdersAwatingService ~ error:",
            error
        );
        return error;
    }
};

// // // getting all orders invoice
// export const GetAllOrdersAwatingPaymentService = async (pageno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_all_ordersAwatingPayment}/${pageno}/`
//         );

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:79 ~ GetAllOrdersAwatingPaymentService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const DeleteOrderService = async (orderno) => {
//     try {
//         let response = getRequest(`${configURL.delete_order}/${orderno}/`);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:96 ~ DeleteOrderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const ViewOrderService = async (payload) => {
//     try {
//         let response = await postRequest(configURL.view_order, payload);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:110 ~ ViewOrderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const ViewOrderSummaryService = async (payload) => {
//     try {
//         let response = await postRequest(configURL.view_order_summary, payload);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:122 ~ ViewOrderSummaryService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetOrderByOrderNumberService = async (id) => {
//     try {
//         let res = await getRequest(`${configURL.get_order_byOrderno}/${id}/`);
//         return res;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:135 ~ GetOrderByOrderNumberService ~ error:",
//             error
//         );
//         return error;
//     }
// };

export const AddAdditionalOrderDetailsService = async (payload) => {
    try {
        let response = await postRequest(
            configURL.add_additional_orderDeatils,
            payload
        );

        return response;
    } catch (error) {
        console.log(
            "🚀 ~ file: OrderServices.js:148 ~ AddAdditionalOrderDetailsService ~ error:",
            error
        );
        return error;
    }
};

// export const SendOrderInvoiceService = async (orderno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.send_order_invoice}/${orderno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:166 ~ SendOrderInvoiceService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const EditCustomerAddressService = async (payload) => {
//     try {
//         let response = await postRequest(configURL.edit_address, payload);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:180 ~ EditCustomerAddressService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SendPaymentReminderService = async (orderno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.send_payment_reminder}/${orderno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:193 ~ SendPaymentReminderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const MarkPaymentReceivedService = async (orderno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.mark_payment_received}/${orderno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:209 ~ MarkPaymentReceivedService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetGraphsForOrderByMonth = async () => {
//     try {
//         let response = await getRequest(configURL.get_all_graphs_by_month);

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:223 ~ GetGraphsForOrderByMonth ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const CategoryPerformanceByItemSoldService = async () => {
//     try {
//         let response = await getRequest(configURL.category_performance_items_sold);

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:238 ~ CategoryPerformanceByItemSoldService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetCategoryPerformanceBySaleService = async () => {
//     try {
//         let response = await getRequest(configURL.category_performance_by_sales);

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:251 ~ GetCategoryPerformanceBySaleService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const BidsOnSaleByTenderService = async () => {
//     try {
//         let response = await getRequest(configURL.bidsonsalebytender);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:265 ~ BidsOnSaleByTenderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const CombineOrderService = async (payload) => {
//     try {
//         let response = await postRequest(configURL.combineOrders, payload);
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:278 ~ CombineOrderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetAllOrdersPackedService = async (pagno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_all_packed_orders}/${pagno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:291 ~ GetAllOrdersPackedService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetOrderDetailsPackedOrderById = async (id) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_packed_order_details}/${id}/`
//         );

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:306 ~ GetOrderDetailsPackedOrderById ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetAllPackedOrdersAwatingInvoiceService = async (pageno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_packed_orders_awating_invoice}/${pageno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:323 ~ GetAllPackedOrdersAwatingInvoiceService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetAllPackedOrdersAwatingPaymentService = async (pageno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_packed_orders_awating_payment}/${pageno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:338 ~ GetAllPackedOrdersAwatingPaymentService ~ error:",
//             error
//         );
//         return error;
//     }
// };
// export const GetAllPackedCompletedOrdersService = async (pageno) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_packed_completed_orders}/${pageno}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:338 ~ GetAllPackedOrdersAwatingPaymentService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const AddPakcedAdditionalOrderService = async (payload) => {
//     try {
//         let response = await postRequest(
//             `${configURL.add_postage_price_change_packed}/`,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:366 ~ AddPakcedAdditionalOrderService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SendPackedOrderInvoiceService = async (packageId) => {
//     try {
//         let response = await getRequest(
//             `${configURL.sendInvoice_packed_order}/${packageId}/`
//         );

//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:383 ~ SendPackedOrderInvoiceService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SendPaymentRemainderPackedService = async (packageid) => {
//     try {
//         let response = await getRequest(
//             `${configURL.send_payment_remainder_packed}/${packageid}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:398 ~ SendPaymentRemainderPackedService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const PackedOrderMarkPaymentReceviedServcie = async (packageid) => {
//     try {
//         let res = await getRequest(
//             `${configURL.mark_payment_received_packed_order}/${packageid}/`
//         );
//         return res;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:414 ~ PackedOrderMarkPaymentReceviedServcie ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SearchPackedOrdersServcice = async (payload, index) => {
//     try {
//         let response = await postRequest(
//             `${configURL.search_packed_orders}/${index}/`,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:429 ~ SearchPackedOrdersServcice ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const SearchPackedOrdersByDateRange = async (payload, index) => {
//     try {
//         let response = await postRequest(
//             `${configURL.search_packed_orders_by_date_rage}/${index}/`,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:442 ~ SearchPackedOrdersByDateRange ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const UnpackedOrdersService = async (packageId) => {
//     try {
//         let response = await getRequest(
//             `${configURL.unpacke_orders}/${packageId}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:458 ~ UnpackedOrdersService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const EditPakcedCustomerAddressService = async (payload) => {
//     try {
//         let response = await postRequest(
//             configURL.add_additional_packed_order,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:474 ~ EditPakcedCustomerAddressService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const GetAllUnpackedPaidOrdersService = async (id) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_all_unpacked_paid_orders}/${id}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:489 ~ GetAllUnpackedPaidOrdersService ~ error:",
//             error
//         );
//         return error;
//     }
// };
// export const GetAllPackedPaidOrdersService = async (id) => {
//     try {
//         let response = await getRequest(
//             `${configURL.get_all_packed_paid_orders}/${id}/`
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:489 ~ GetAllPackedPaidOrdersService ~ error:",
//             error
//         );
//         return error;
//     }
// };

// export const ChangeUnpackedOrderStatusService = async (payload) => {
//     try {
//         let response = await postRequest(
//             configURL.changeunpackedorderstatus,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:519 ~ ChangeUnpackedOrderStatusService ~ error:",
//             error
//         );
//         return error;
//     }
// };
// export const ChangePackedOrderStatusService = async (payload) => {
//     try {
//         let response = await postRequest(
//             configURL.changepackedorderstatus,
//             payload
//         );
//         return response;
//     } catch (error) {
//         console.log(
//             "🚀 ~ file: OrderServices.js:519 ~ ChangePackedOrderStatusService ~ error:",
//             error
//         );
//         return error;
//     }
// };
