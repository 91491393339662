import axios from "axios";
import { pennymead_GetLocal } from "../Utils/LocalStorage";
import axiosInstance from "./AxiosInstance";

const headers = {
  "Content-Type": "text/plain",
};

export const getRequest = async (url) => {
  // try {
  //   const response = await axiosInstance.get(url);
  //   return response;
  // } catch (error) {
  //   console.log("get request error", error);
  //   return error.message;
  // }
  try {
    const response = await axios.get(url);
    // console.log(
    //   "🚀 ~ file: BaseServices.js:19 ~ getRequest ~ response:",
    //   response
    // );
    return response;
  } catch (error) {
    // console.log("🚀 ~ file: BaseServices.js:20 ~ getRequest ~ error:", error);
  }
};

export const postRequest = async (url, data) => {
  // try {
  //   const response = await axiosInstance.post(url, data);
  //   // console.log('postRequest response==>', response);
  //   return response;
  // } catch (error) {
  //   console.log("postRequest  error==>", error.response);
  //   return error;
  // }
  try {
    const response = await axios.post(url, data, {
      headers: headers,
    });
    return response;
  } catch (error) {
    // console.log("🚀 ~ file: BaseServices.js:27 ~ postRequest ~ error:", error);
    return error;
  }
};

export const putRequest = async (url, data = {}) => {
  try {
    const response = await axiosInstance.put(url, data);
    // console.log('putRequest response==>', response);
    return response;
  } catch (error) {
    // console.log("putRequest  error==>", error.response);
    return error;
  }
};

export const deleteReqest = async (url, data) => {
  try {
    const response = await axiosInstance.delete(url, data || null);
    // console.log('deleteRequest response==>', response);
    return response;
  } catch (error) {
    // console.log("deleteRequest error==>", error);
    return error;
  }
};
