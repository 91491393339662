import React from "react";
import { AuthBannerComponent } from "../../../Components/AuthBanner";

export const SignUpComponent = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <AuthBannerComponent screenHeading="Pennymead.com" />
          </div>
          <div className="col-md-6">SignUpComponent</div>
        </div>
      </div>
    </div>
  );
};
