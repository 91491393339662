import React, { useCallback, useEffect } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { CTAButton } from "../../../../Components/CTAButton";
import { useReactToPrint } from "react-to-print";

export const OrderInvoicePagePacked = () => {
  const location = useLocation();

  const _orderData = location?.state?.data;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`, {
      state: { data: _orderData },
    });
  };

  // const toprintData = () => {
  //   var printContent = document.getElementById("toprintscreen").innerHTML;

  //   var printWindow = window.open("", "", "width=600,height=600");

  //   printWindow.document.open();
  //   printWindow.document.write("<html><head><title>Print</title></head><body>");
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   printWindow.print();

  //   printWindow.close();
  // };

  const getOnlyDateFromDateTime = (date_) => {
    const parts = date_.split(" ");

    const datePart = parts[0];

    return datePart;
  };

  // const toprintData = () => {
  //   // Get the content of the div with the id "toprintscreen"
  //   var printContent = document.getElementById("toprintscreen").innerHTML;

  //   // Create a new window for printing
  //   var printWindow = window.open("", "", "width=600,height=600");

  //   // Write the content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write("<html><head><title>Print</title></head><body>");
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   // Print the new window
  //   printWindow.print();

  //   // Close the new window after printing
  //   printWindow.close();
  // };

  const componentRef = React.useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   onBeforeGetContent: () => {
  //     toprintData();
  //   },
  // });
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "invoice",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <>
      <div className="d-flex justify-content-between">
        <BreadCrumb
          routename="View Invoice"
          isBack={true}
          gotoBackFun={gotoback}
        />

        <div className="mx-5">
          {/* <CTAButton name="Print" handleClick={toprintData} /> */}
          <CTAButton name="Print" handleClick={handlePrint} id="submitbtn" />
        </div>
      </div>

      {/* ----------------------- */}
      <div ref={componentRef} className="toprintscreen p-5">
        <div style={{ width: "100%" }}>
          <h4>Pennymead Books</h4>
          <p>1 Brewerton Street, Knaresborough, HG5 8AZ</p>
          <p>(Proprietor David Druett)</p>
          <p>Tel. 01423 865962</p>
        </div>
        <hr />
        <div className="my-3">
          <h4 className="">Order Invoice Packed</h4>
        </div>

        <div className="orderdatediv">
          {/* <h6>Order Placed : {_orderData?.order?.packed_date || "--"}</h6> */}
          <h6>
            Order Placed :{/* {_orderData?.order?.packed_date || "--"} */}
            {getOnlyDateFromDateTime(_orderData?.order?.packed_date)}
          </h6>

          <h6>Package Id : {_orderData?.order?.orderno}</h6>
        </div>

        <hr />
        <div className="customerDetailsDiv mt-3">
          <h4 className="my-2">Customer Details</h4>

          <div className="details">
            <p>
              <>
                <strong>Customer Name :</strong>
              </>
              {_orderData?.customer?.name || "--"}
            </p>
            <p>
              <strong>Address Line 1 : </strong>
              {_orderData?.customer?.address1 || "--"}
            </p>
            <p>
              <strong>Address Line 2 :</strong>
              {_orderData?.customer?.address2 || "--"}
            </p>
            <p>
              <strong>Town/City</strong> {_orderData?.customer?.town || "--"}
            </p>
            <p>
              <strong>County/State :</strong>
              {_orderData?.customer?.county || "--"}
            </p>
            <p>
              <strong>Postcode/ZIP Code :</strong>
              {_orderData?.customer?.postcode || "--"}
            </p>
            <p>
              <strong>Country :</strong>
              {_orderData?.customer?.country || "--"}
            </p>
            <p>
              <strong>Email Address :</strong>
              {_orderData?.customer?.email || "--"}
            </p>
          </div>
        </div>

        <hr />

        <>
          <div className="itemsorderediv mt-3">
            <h4 className="my-2">Orders</h4>
          </div>

          <hr />

          {_orderData?.order?.orders?.map((item) => {
            return (
              <>
                <div>
                  <div className="d-flex flex-column">
                    <div>
                      <span>
                        <strong>Order Number : </strong>
                      </span>{" "}
                      {item?.orderno}
                    </div>
                    <div>
                      <span>
                        <strong>Order Date : </strong>
                      </span>{" "}
                      {/* {item?.ordate} */}
                      {getOnlyDateFromDateTime(item?.ordate)}
                    </div>
                  </div>

                  {/* <span>----------------</span> */}

                  {item?.order_item?.map((subItem) => {
                    return (
                      <>
                        <h6>
                          <strong>{subItem?.title}</strong>
                        </h6>
                        {/* <h7>{subItem?.author}</h7> */}
                        <p>{subItem?.author}</p>
                        <p>{subItem?.description}</p>

                        <p>£{subItem?.price}</p>
                        <hr />
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}

          <div>
            <p>
              <strong>Sub Total :</strong> £{_orderData?.order?.total}
            </p>

            <p>
              <strong>Postage :</strong> £{_orderData?.order?.postage}
            </p>

            <p>
              <strong>Price Adjustment :</strong> £
              {_orderData?.order?.pricechange}
            </p>

            <p>
              <strong>Total :</strong> £{_orderData?.order?.grand_total}
            </p>

            <hr />
          </div>
        </>
      </div>
    </>
  );
};
