import React, { useEffect, useState } from "react";
import "./styles.scss";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CTAButton } from "../../../Components/CTAButton";
import Swal from "sweetalert2";
import {
  GetActiveHolidayService,
  TurnOffHolidayService,
  TurnOnService,
} from "../../../Services/HolidaySettingsService";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const HolidaySettings = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  const [holidayData, setHolidayData] = useState({});

  const [holidayForm, setHolidayForm] = useState({
    date: "",
    message: "",
  });

  useEffect(() => {
    getActiveHolidayInfo();
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "dateofreturn") {
      setHolidayForm({
        ...holidayForm,
        date: value,
      });
    }
    if (name === "message") {
      setHolidayForm({
        ...holidayForm,
        message: value,
      });
    }
  };

  const getActiveHolidayInfo = async () => {
    // let res = await GetActiveHolidayService();

    let res = await getRequest(configURL.read_holiday_info);

    if (res?.status === 200 || res?.status === 201) {
      setHolidayData(res?.data?.data[0]);
    } else {
      console.log("errror getting the active holiday-------->", res);
    }
  };

  const submitHoliday = async () => {
    // console.log("the form data is", holidayForm);

    let payload = {
      date_end: holidayForm.date,
      message: holidayForm.message,
    };

    // let res = await TurnOnService(payload);

    let res = await postRequest(configURL.add_new_holiday, payload);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the holiday`,
      });

      setHolidayForm({
        date: "",
        message: "",
      });

      getActiveHolidayInfo();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const turnOffHolidayFun = async () => {
    // let res = await TurnOffHolidayService();

    let res = await postRequest(configURL.turn_off_holiday);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Holiday Turned Off`,
      });
      getActiveHolidayInfo();
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Holiday Settings" />
      </div>
      {!holidayData ? (
        <>
          <div className="holidaysettingsmainDiv">
            {/* {holidayData && (
              <>
                <div className="activeholidaydiv">
                  <p className="text-danger">
                    A Holiday named <strong>{holidayData?.message}</strong> has
                    the end date of <strong>{holidayData?.end}</strong>.
                  </p>

                  <button className="turnOffBtn" onClick={turnOffHolidayFun}>
                    Click here to turn off the active holiday.
                  </button>
                </div>
              </>
            )} */}

            <div className="my-1">
              <h5 className="subHeading">
                Holiday settings are currently turned off. To turn them on,
                click the button below.
              </h5>

              <h4 className="subHeading my-1">Details : </h4>

              <div className="d-flex flex-column w-25">
                <label htmlFor="" className="mb-0">
                  Date Of Return
                </label>
                <input
                  type="date"
                  name="dateofreturn"
                  onChange={handleChange}
                  id="dateofreturn"
                  placeholder="Please enter the date of return"
                  className="inp"
                />
              </div>

              <div className="my-2">
                <h6 className="subHeading">
                  The following default message will be displayed to customers
                  when they view their shopping basket:
                </h6>

                <div className="text-center">
                  <h5 className="text-danger subHeading my-1">
                    Please note I am away on holiday until [Date of return].{" "}
                    <br /> You are welcome to place orders during this time, but
                    they will not be processed until my return on [Date of
                    return].
                  </h5>
                </div>
              </div>

              <div className="my-2">
                <h6 className="subHeading">
                  Use the box below to give any additional information.
                </h6>
              </div>

              <div className="d-flex flex-column">
                <label htmlFor="">Message : </label>
                <textarea
                  name="message"
                  className="text_"
                  id="message"
                  onChange={handleChange}
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <h6 className="my-2 subHeading">
                This message will be displayed to your customers when they view
                their shopping basket.
              </h6>
              <CTAButton name="Turn On" handleClick={submitHoliday} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-column">
            <h6 className="my-3">
              Holiday is on, Turn it off by clicking the below button
            </h6>
            Date of return : {holidayData?.end}
            <div>
              <button
                className="btn btn-primary my-2 rounded-0"
                onClick={turnOffHolidayFun}
              >
                Turn Off
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
