import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { useReactToPrint } from "react-to-print";

export const PrintInvoicedData = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const componentRef = React.useRef();

  const [orders, setOrders] = useState(location?.state?.data);

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrintOrders = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "orders_awating_invoice",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <>
      <div>
        <BreadCrumb
          routename="Print Invoiced Orders"
          isBack={true}
          gotoBackFun={gotoback}
        />
      </div>

      {/* showing the data in the table */}
      <div>
        <div
          className="my-2"
          style={{ textAlign: "end", padding: "10px 10px" }}
        >
          <button
            className="btn btn-primary rounded-0"
            onClick={handlePrintOrders}
          >
            Print Orders
          </button>
        </div>
        <div ref={componentRef} style={{ padding: "10px 10px" }}>
          <table>
            <thead>
              <tr>
                <th>Order No</th>
                <th>Author</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {/* {orders.map((item, index) => (
                <tr key={index}>
                  <td>{}</td>
                  <td>{item.author || "---"}</td>
                  <td>{item.title || "---"}</td>
                  <td>{item.description || "---"}</td>
                  <td>£{item.price || "---"}</td>
                  <td>{item.location || "---"}</td>
                </tr>
              ))} */}
              {location?.state?.key === "unpacked" && (
                <>
                  {orders?.map((item, index) =>
                    item?.stock_details?.map((stock, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.orderno}</td>
                            <td>{stock.author || "---"}</td>
                            <td>{stock.title || "---"}</td>
                            <td>{stock.description || "---"}</td>
                            <td>£{stock.price || "---"}</td>
                            <td>{stock.location || "---"}</td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </>
              )}
              {location?.state?.key === "packed" && (
                <>
                  {orders?.map((item, index) =>
                    item?.order_item?.map((stock, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.orderno}</td>
                            <td>{stock.author || "---"}</td>
                            <td>{stock.title || "---"}</td>
                            <td>{stock.description || "---"}</td>
                            <td>£{stock.price || "---"}</td>
                            <td>{stock.location || "---"}</td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
