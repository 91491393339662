import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "./styles.scss";
import { MailingListTable } from "./MailingListTable";
import { CTAButton } from "../../../Components/CTAButton";
import { GetAllCategoriesService } from "../../../Services/CommonServices";
import { GetAllMailingListService } from "../../../Services/MailingListServices";
import BounceLoader from "react-spinners/BounceLoader";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

// !SPINNERS

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const MailingListManagement = () => {
  const { getRequest } = ApiResponseHook()

  let [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryId] = useState("");

  const [categorylist, setCategorylist] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAllCategoriesList();
  }, []);

  const handleChange = (event) => {
    setCategory(event.target.options[event.target.selectedIndex].text);
    setCategoryId(event.target.value);
    getAllMalilingListData(event.target.value);
    setShow(true);
  };

  const getAllCategoriesList = async () => {
    let response = await getRequest(configURL.get_all_categories_master)
    setCategorylist(response?.data?.data || []);
  };

  const getAllMalilingListData = async (id) => {
    let response = await getRequest(`${configURL.get_all_mailing_list}/${id}/`)
    setData(response?.data?.data || []);
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Mailing List Management" />
      </div>

      {/* {loading ? (
        <>
          <div className="d-flex justify-content-center align-items-center loaderCSS w-50">
            <BounceLoader
              color="#873900"
              loading={loading}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : ( // )}*/}
      <>
        <div className="mailinglistdiv my-4">
          <h6 className="subHeading">
            Select a category you would like to export below
          </h6>

          <div class="form-group mt-2">
            <select
              className="form-control selectCategory"
              id="exampleFormControlSelect1"
              onChange={handleChange}
            >
              <option value="Select Category" disabled selected>
                Select Category
              </option>

              {categorylist?.map((item) => {
                return (
                  <option key={item?.reference} value={item?.reference}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            {!show && (
              <>
                <h4 className="text-danger subHeading mt-2">
                  Please select a category from the dropdown
                </h4>
              </>
            )}
          </div>
        </div>



        {show && (
          <>
            {/* mailing list table */}
            <div>
              <MailingListTable
                category={category}
                categoryid={categoryid}
                mailinglist={data}
              />
            </div>
          </>
        )}
      </>

    </div>
  );
};
