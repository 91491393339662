import React from "react";
import "./styles.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { RouteStrings } from "../../Routes/RouteStrings";

export const AdminSideBarComponent = (props) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column sidebar_">
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.dashboard}
        id="dashboard"
      >
        Dashboard
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.stockManagement}
        id="stockmanagement"
      >
        Stock Management
      </NavLink>

      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.customerManagement}
        id="customermanagement"
      >
        Customer Management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.orderManagement}
        id="ordermanagement"
      >
        Order Management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.mailingListManagement}
        id="mailiinglistmanagement"
      >
        Mailing list management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.shoponBehalf}
        id="shoponbehalf"
      >
        Shop on behalf of customer
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.pageManagement}
        id="pagemanagement"
      >
        Page Management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.categotyManagement}
        id="categorymanagement"
      >
        Category Management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.postageManagement}
        id="postagemanagement"
      >
        Postage Management
      </NavLink>
      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.globalSettings}
        id="globalsettings"
      >
        Global Settings
      </NavLink>

      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.holidaySettings}
        id="holidaysettings"
      >
        Holiday Settings
      </NavLink>

      <NavLink
        className="navLinkClass"
        activeclassName="active"
        to={RouteStrings.adminManagement}
        id="adminmanagement"
      >
        Admin Management
      </NavLink>

      <p>
        <button
          class="btn_cms"
          type="button"
          data-toggle="collapse"
          data-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          id="cms"
        >
          CMS
        </button>
      </p>
      <div class="collapse" id="collapseExample">
        <div class="cms_section d-flex flex-column">
          <NavLink
            className="cms_options"
            activeclassName="active"
            to={RouteStrings.adminInfo}
            id="admininfo"
          >
            Admin Info
          </NavLink>
          <NavLink
            className="cms_options"
            activeclassName="active"
            to={RouteStrings.contactus}
            id="contact"
          >
            Contact Us
          </NavLink>
          {/* <NavLink
              className="cms_options"
              activeclassName="active"
              to={RouteStrings.adminProductDetails}
            >
              Product Details
            </NavLink> */}
          <NavLink
            className="cms_options"
            activeclassName="active"
            to={RouteStrings.adminNoteManagement}
            id="adminnotemanagement"
          >
            Note Management
          </NavLink>
        </div>
      </div>
    </div>
  );
};
