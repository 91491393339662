import React, { useMemo } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";

import "./styles.scss";
import { GlobalFilter } from "../../../../Utils/Table/GlobalFilter";
// import Data from "./data.json";

import { useNavigate, useLocation } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { CTAButton } from "../../../../Components/CTAButton";
import { CSVLink, CSVDownload } from "react-csv";

export const MailingListTable = ({ category, mailinglist }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        Header: "Customer Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  const data = useMemo(() => mailinglist);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;

  const downlaodFile = () => { };

  const dataToDownload = React.useMemo(() => {
    return rows.map((row) => ({
      Name: row.values.name,
      Email: row.values.email,
    }));
  }, [rows]);

  return (
    <>
      <div className="d-md-flex justify-content-between align-items-end">
        <h4 className="mb-2 mb-md-0">{category}</h4>
        <div className="d-md-flex">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />

          <div className="mt-2 mt-md-0 ml-md-2">
            {/* <CTAButton
                  name="Download File"
                  handleClick={() => downlaodFile()}
                /> */}
            <CSVLink
              filename={"my-file.csv"}
              className="btn btn-primary rounded-0 dwn_btn"
              data={dataToDownload}
            >
              Download File
            </CSVLink>
          </div>
        </div>
      </div>

      <div>

        {mailinglist.length > 0 && <>
          <div className="">
            <table {...getTableProps()} className="mt-3">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          // onClick={() => gotoOrderDetails(row.original)}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-md-flex align-items-center mb-3">
            <div className="mt-2 mt-md-0">
              <select
                value={pageSize}
                className="selectTag mb-3"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                <option value="" disabled>
                  Select
                </option>
                {[5, 10, 15, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-100 text-center mt-3 mb-1">
              <button
                className="mx-1 skipToBtn"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>
              <button
                className="mx-1 actionBtn"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Prev
              </button>
              <span className="mx-2 pageNumber">
                {/* Page{" "} */}
                <strong>
                  {pageIndex + 1}
                  {/* of {pageOptions.length} */}
                </strong>{" "}
              </span>
              <button
                className="mx-1 actionBtn"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className="skipToBtn"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </>}
      </div>
    </>
  );
};
