import React, { useEffect } from "react";
import "./styles.scss";
import { Images } from "../../Assets/Images/imageslist";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loggedOut } from "../../Store/reducers/reducers";
import { RouteStrings } from "../../Routes/RouteStrings";


export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    // pennymead_SetLocal("login", false);
    dispatch(loggedOut());
    // pennymead_ClearLocal();
    navigate(RouteStrings.login, { replace: true });
  };

  const { isLogin } = useSelector(state => state.UIStorage)

  useEffect(() => {
    if (!isLogin) {
      navigate(RouteStrings.login)
    }
  }, [isLogin])



  return (
    <div>
      <nav class="navbar navbar-expand-lg _navbar">
        <div className="text-center w-100">
          <h1 className="navHeading">Pennymead.com Administrator Area</h1>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">

              <img
                src={Images.profile}
                alt="profile"
                className="_profile"
                data-toggle="dropdown"
                aria-expanded="false"
              />

              <div class="dropdown-menu">
                <Link to={RouteStrings.login} class="dropdown-item text-center" onClick={logout}>
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
