import logo from "./logo.svg";
import "./App.css";
import { RouteComponent } from "./Routes/Routes";
import { Navbar } from "./Components/Navbar";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

function App() {
  const { loader } = useSelector(state => state.UIStorage)
  return (
    <div>
      <div>
        <RouteComponent />
      </div>
      {loader && <div className='loader_style'>
        <Spinner animation="border" role="status">
          {/* <span className="visually-hidden">Loading...</span> */}
        </Spinner>
      </div>}
    </div>
  );
}

export default App;
