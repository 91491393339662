import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { CTAButton } from "../../../../Components/CTAButton";
import {
  EditCustomerDataService,
  GetCustomerById,
} from "../../../../Services/CustomerServices";
import Swal from "sweetalert2";
// import { GetAllCountriesService } from "../../../../Services/CommonServices";
import "./styles.scss";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { configURL } from "../../../../Services/configuration";

export const EditCustomer = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { getRequest, postRequest } = ApiResponseHook();
  // !---- states -----
  // const [customerData, setCustomerData] = useState({});
  const [conutriesList, setCountriesList] = useState([]);

  const [emailError, setEmailError] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");

  const [editCustomerData, setEditCustomerData] = useState({
    name: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    home_phone: "",
    work_phone: "",
    alternative_address: "",
    comments: "",
    personal_name: "",
    custid: "",
  });
  // console.log(
  //   "🚀 ~ file: index.js:39 ~ EditCustomer ~ editCustomerData:",
  //   editCustomerData
  // );

  // !---- useEffect ----

  useEffect(() => {
    getCustomerDataById();
    getAllCountriesData();
  }, []);

  const getAllCountriesData = async () => {
    // let response = await GetAllCountriesService();
    let response = await getRequest(configURL.get_all_countries);
    setCountriesList(response?.data?.data);
  };

  function validateEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(editCustomerData.email)) {
      // console.log("errror");
      setEmailError(true);
      setEmailMsg("please enter a valid email id");
    } else {
      setEmailError(false);
      // let response = await EditCustomerDataService(editCustomerData);
      let response = await postRequest(
        configURL.update_customerData,
        editCustomerData
      );

      if (response?.status === 200 || response?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the customer data`,
        });
        getCustomerDataById();
      } else if (
        response?.response?.status === 400 ||
        response?.response?.status === 401 ||
        response?.response?.status === 404
      ) {
        let errorObj = response?.response?.data?.error;

        // const firstValue = errorObj[Object.keys(errorObj)[0]];

        let firstValue = "";

        if (errorObj) {
          firstValue = errorObj[Object.keys(errorObj)[0]];
        } else {
          firstValue = response?.response?.data?.message;
        }

        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error :  ${firstValue}`,
        });
        // console.log("error", response);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
        // console.log("error", response);
      }

      // console.log("no error");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmailError(false);
    let obj = {};
    obj[name] = value;
    setEditCustomerData({ ...editCustomerData, ...obj });
  };

  const goBack = () => {
    navigate(`/${RouteStrings.customerManagement}`);
  };

  // !--- funcitons ---
  const getCustomerDataById = async () => {
    // let response = await GetCustomerById(location?.state?.data);
    let response = await getRequest(
      `${configURL.get_customer_byId}/${location?.state?.data}/`
    );

    if (response.status === 200) {
      setEditCustomerData({
        ...editCustomerData,
        name: response?.data?.data[0]?.customer[0]?.name,
        company: response?.data?.data[0]?.customer[0]?.company,
        address1: response?.data?.data[0]?.customer[0]?.address1,
        address2: response?.data?.data[0]?.customer[0]?.address2,
        city: response?.data?.data[0]?.customer[0]?.town,
        state: response?.data?.data[0]?.customer[0]?.county,
        postcode: response?.data?.data[0]?.customer[0]?.postcode,
        country: response?.data?.data[0]?.customer[0]?.country,
        email: response?.data?.data[0]?.customer[0]?.email,
        home_phone: response?.data?.data[0]?.customer[0]?.hphone,
        work_phone: response?.data?.data[0]?.customer[0]?.ophone,
        alternative_address: response?.data?.data[0]?.customer[0]?.altaddress,
        comments: response?.data?.data[0]?.customer[0]?.comments,
        personal_name: response?.data?.data[0]?.customer[0]?.pnames,
        custid: response?.data?.data[0]?.customer[0]?.custno,
      });
    } else if (
      response?.response?.status === 400 ||
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.statusText}`,
      });
      // console.log("error", response);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      // console.log("error====>", response);
    }
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault(); // Prevents the input of numbers
    }
  }

  function validateNumberInput(event) {
    const inputValue = event.key;

    // Check if the input is a digit (0-9), a minus sign, or a backspace (for deletion)
    if (!/[0-9-]/.test(inputValue) && event.key !== "Backspace") {
      event.preventDefault(); // Prevents the input of text and characters other than '-', digits, and backspace
    }

    // If the input is a minus sign, make sure it's the first character (negative sign)
    if (inputValue === "-" && event.target.selectionStart !== 0) {
      event.preventDefault();
    }
  }

  return (
    <div>
      <div>
        <div>
          <BreadCrumb
            routename="Edit Customer"
            isBack={true}
            gotoBackFun={goBack}
          />
        </div>

        <div className="newCustomerDiv my-2">
          <div className="formDiv">
            <h5 className="subHeading mb-3">Contact Details</h5>
            <form className="form_" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Full Name <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="inp"
                      onKeyPress={validateInput}
                      value={editCustomerData?.name || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Company</label>

                    <input
                      type="text"
                      id="company"
                      name="company"
                      className="inp"
                      value={editCustomerData?.company || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Address Line 1 <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="address1"
                      name="address1"
                      className="inp"
                      value={editCustomerData?.address1 || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Address Line 2</label>

                    <input
                      type="text"
                      id="address2"
                      name="address2"
                      className="inp"
                      value={editCustomerData?.address2 || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Town/City <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="city"
                      name="city"
                      className="inp"
                      onKeyPress={validateInput}
                      value={editCustomerData?.city || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      County/State <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="state"
                      name="state"
                      className="inp"
                      onKeyPress={validateInput}
                      value={editCustomerData?.state || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Postcode/ZIP Code <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="postcode"
                      name="postcode"
                      className="inp"
                      value={editCustomerData?.postcode || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="formDiv d-flex flex-column mb-2">
                    <div class="form-group mb-0">
                      <label for="exampleFormControlSelect1">
                        Country <span className="_imp">*</span>
                      </label>
                      <select
                        onChange={handleChange}
                        class="form-control inp"
                        name="country"
                        value={editCustomerData?.country || ""}
                        id="exampleFormControlSelect1"
                      >
                        <option selected disabled>
                          Select Country
                        </option>

                        {conutriesList?.map((country) => {
                          return (
                            <>
                              <option
                                key={country?.numcode}
                                value={country?.printable_name}
                              >
                                {country?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Email <span className="_imp">*</span>
                    </label>

                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="inp"
                      value={editCustomerData?.email || ""}
                      onChange={handleChange}
                    />

                    {emailError && (
                      <>
                        <p className="mb-0 text-danger">{emailMsg}</p>
                      </>
                    )}
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">
                      Home Phone <span className="_imp">*</span>
                    </label>

                    <input
                      type="number"
                      id="home_phone"
                      name="home_phone"
                      className="inp"
                      onKeyDown={validateNumberInput}
                      value={editCustomerData?.home_phone || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Work Phone</label>

                    <input
                      type="number"
                      id="work_phone"
                      name="work_phone"
                      className="inp"
                      onKeyDown={validateNumberInput}
                      value={editCustomerData?.work_phone || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Alternate Address </label>

                    <input
                      type="text"
                      id="alternative_address"
                      name="alternative_address"
                      className="inp"
                      value={editCustomerData?.alternative_address || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Comments</label>

                    <input
                      type="text"
                      id="comments"
                      name="comments"
                      className="inp"
                      value={editCustomerData?.comments || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="formDiv d-flex flex-column mb-2">
                    <label htmlFor="">Personal Names</label>

                    <input
                      type="text"
                      id="personal_name"
                      name="personal_name"
                      className="inp"
                      value={editCustomerData?.personal_name || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <CTAButton name="Submit" type="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
