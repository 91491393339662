import React, { useEffect, useRef, useState } from "react";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { useLocation, useNavigate } from "react-router-dom";
import { CTAButton } from "../../../../Components/CTAButton";
import Swal from "sweetalert2";

import "./styles.scss";
import { GetAllCategoriesService } from "../../../../Services/CommonServices";
import {
  AddNewStockService,
  GetAllGroupsForCategoriesService,
  GetStockByIdService,
} from "../../../../Services/StockServices";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import imageCompression from "browser-image-compression";
import Form from "react-bootstrap/Form";

export const AddSimilarItem = ({
  data,
  donoshowSearchDiv,
  hidesimilardiv,
  emptyData,
  isAddSimilar,
  categoryId,
  hideAddSimilarDiv,
  gobacktodiv,
  setNewSysId,
}) => {
  // console.log("🚀 ~ file: index.js:31 ~ data:~~~~~~~~~~>", data);
  const location = useLocation();
  const navigate = useNavigate();

  const delImgInpRef = useRef();

  // states
  const [stockData, setStockData] = useState([]);

  const [categoriesList, setCategoriesList] = useState([]);

  const [imagesArr, setImageArr] = useState([]);

  const [keyArr, setKeyArr] = useState([]);

  const [keywordsList, setKeywordsList] = useState([]);

  const [startDate, setStartDate] = useState("");

  const [allGroups, setAllGroups] = useState([]);

  const goBack = () => {
    navigate(`/${RouteStrings.stockManagement}`);
  };

  // use effect
  useEffect(() => {
    getStockById();

    if (isAddSimilar === "addSimilar") {
      // console.log("call api for adding similar from addsimilar");
      getAllGroupByCategoryId(data[0]?.category);
      // getAllGroupByCategoryId(9);
    } else {
      // console.log("call the api from the edit search");
      getAllGroupByCategoryId(data.category);
      // getAllGroupByCategoryId(9);
    }

    getKeywords();
    getAllCategories();
  }, []);

  const getAllGroupByCategoryId = async (id) => {
    setAllGroups([]);
    let res = await GetAllGroupsForCategoriesService(id);
    if (res?.status === 200 || res?.status === 201) {
      setAllGroups(res?.data?.data);
    } else {
      // console.log("error getting all the gruops", res);
    }
  };

  const getKeywords = async () => {
    // let keywords = data?.keywords;
    // let keywordsArr = keywords?.split(" ");
    // setKeyArr(keywordsArr);

    let keywords = "";
    let keywordsArr = "";

    if (isAddSimilar === "addSimilar") {
      keywords = data[0]?.other_keywords;
      keywordsArr = keywords?.split(" ");
      setKeyArr(keywordsArr);
    } else {
      keywords = data?.keywords;
      keywordsArr = keywords?.split(" ");
      setKeyArr(keywordsArr);
    }
  };

  const getStockById = async () => {
    // let res = await GetStockByIdService(data.sysid);
    // let res = await GetStockByIdService(32301);

    let res = "";

    if (isAddSimilar === "addSimilar") {
      res = await GetStockByIdService(data[0]?.sysid);
    } else {
      res = await GetStockByIdService(data?.sysid);
    }

    if (res.status === 200 || res.status === 201) {
      setStockData(res?.data?.data[0]);
      setFormData({
        ...editFormData,
        author: res?.data?.data[0]?.author,
        author2: res?.data?.data[0]?.author2 || "",
        title: res?.data?.data[0]?.title || "",
        longtitle: res?.data?.data[0]?.longtitle || "",
        publisher: res?.data?.data[0]?.publisher || "",
        pubdate: res?.data?.data[0]?.pubdate || "",
        description: res?.data?.data[0]?.description || "",
        footnote: res?.data?.data[0]?.footnote || "",
        price: res?.data?.data[0]?.price || "",
        keywords: res?.data?.data[0]?.keywords || "",
        category: res?.data?.data[0]?.category || "",
        quantity: res?.data?.data[0]?.quantity || "",
        location: res?.data?.data[0]?.location || "",
        vendor: res?.data?.data[0]?.vendor || "",
        isFeatured: res?.data?.data[0]?.isFeatured || "",
        sysid: res?.data?.data[0]?.sysid,
      });

      let _data = res?.data?.data[0]?.keywords;
      // const result = {};
      // if (Array.isArray(_data)) {
      //   setKeywordsList({});
      // } else {
      //   for (const key in _data) {
      //     result[key.toLowerCase()] = _data[key]
      //       .map((item) => item.keywords)
      //       .reduce((acc, val) => acc.concat(val), []);
      //   }
      //   setKeywordsList(result);
      // }
      _data = _data.split(",").map(item => item.trim())
      setKeywordsList(_data)
    } else {
    }
  };

  const getAllCategories = async () => {
    let res = await GetAllCategoriesService();
    setCategoriesList(res?.data?.data);
  };

  const [editFormData, setFormData] = useState({
    author: stockData?.author || "",
    author2: stockData?.author2 || "",
    title: stockData?.title || "",
    longtitle: stockData?.longtitle || "",
    publisher: stockData?.publisher || "",
    pubdate: stockData?.pubdate || "",
    description: stockData?.description || "",
    footnote: stockData?.footnote || "",
    price: stockData?.price || "",
    keywords: stockData?.keywords || "",
    category: stockData?.category || "",
    quantity: stockData?.quantity || "",
    location: stockData?.location || "",
    vendor: stockData?.vendor || "",
    isFeatured: stockData?.isFeatured || "",
    sysid: stockData?.sysid,
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "author") {
      setEditStockError({
        ...editStockError,
        author: false,
      });
      setFormData({
        ...editFormData,
        author: value,
      });
    }
    if (name === "author2") {
      setFormData({
        ...editFormData,
        author2: value,
      });
    }

    if (name === "title") {
      setEditStockError({
        ...editStockError,
        title: false,
      });
      setFormData({
        ...editFormData,
        title: value,
      });
    }
    if (name === "longtitle") {
      setFormData({
        ...editFormData,
        longtitle: value,
      });
    }
    if (name === "publisher") {
      setEditStockError({
        ...editStockError,
        publisher: false,
      });
      setFormData({
        ...editFormData,
        publisher: value,
      });
    }
    if (name === "pubdate") {
      setEditStockError({
        ...editStockError,
        pudate: false,
      });
      setFormData({
        ...editFormData,
        pubdate: value,
      });
    }
    if (name === "description") {
      setEditStockError({
        ...editStockError,
        description: false,
      });
      setFormData({
        ...editFormData,
        description: value,
      });
    }
    if (name === "footnote") {
      setFormData({
        ...editFormData,
        footnote: value,
      });
    }
    if (name === "price") {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
      } else {
        setEditStockError({
          ...editStockError,
          price: false,
        });
        setFormData({
          ...editFormData,
          price: value,
        });
      }
    }

    if (name === "category") {
      setFormData({
        ...editFormData,
        keywords: {},
        category: value,
      });
      getAllGroupByCategoryId(value);
    }

    if (name === "quantity") {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
      } else {
        setEditStockError({
          ...editStockError,
          quantity: false,
        });
        setFormData({
          ...editFormData,
          quantity: value,
        });
      }
    }
    if (name === "location") {
      setEditStockError({
        ...editStockError,
        location: false,
      });
      setFormData({
        ...editFormData,
        location: value,
      });
    }
    if (name === "vendor") {
      setFormData({
        ...editFormData,
        vendor: value,
      });
    }
  };

  const [editStockError, setEditStockError] = useState({
    author: false,
    title: false,
    publisher: false,
    pudate: false,
    description: false,
    price: false,
    keywords: false,
    quantity: false,
    image: false,
  });

  const [editStockErrorMsg, setEditStockErrorMsg] = useState({
    author: "",
    title: "",
    publisher: "",
    pudate: "",
    description: "",
    price: "",
    keywords: "",
    quantity: "",
    image: "",
  });

  // const handleSubcategory = (item, groupname, e) => {
  // let _groupname = groupname.toLowerCase();
  // if (e.target.checked) {
  //   keywordsList[_groupname].push(item);
  // } else {
  //   let _item = keywordsList[_groupname].filter((item1) => item1 !== item);
  //   setKeywordsList({ ...keywordsList, [_groupname]: _item });
  // }
  // console.log(
  //   "🚀 ~ file: index.js:358 ~ handleSubcategory ~ keywordsList[groupname.toLowerCase()]:",
  //   keywordsList
  // );

  // let temp = { ...editFormData };

  // let isPropertyExist = Object.hasOwn(editFormData.keywords, item);

  // temp.keywords[item] = selecedOption;

  // setFormData({
  //   ...editFormData,
  //   keywords: temp?.keywords,
  // });
  // };

  const handleSubcategory = (e) => {
    if (e.target.checked) {
      keywordsList.push(e.target.value);
    } else {
      let _item = keywordsList.filter((item) => item !== e.target.value);
      setKeywordsList(_item);
    }
  };

  const submitFunUpdate = async (e) => {
    e.preventDefault();

    let _valuesarr = keywordsList.join(",");

    // let _valuesarr = Object.values(keywordslistarr).flat(2);

    let payload = {
      author: editFormData.author,
      author2: editFormData.author2,
      title: editFormData.title,
      longtitle: editFormData.longtitle,
      publisher: editFormData.publisher,
      pubdate: editFormData.pubdate,
      description: editFormData.description,
      footnote: editFormData.footnote,
      price: editFormData.price,
      keywords: _valuesarr,
      // keywords: Object.values(editFormData.keywords)
      //   .flat(2)
      //   .map((mapVal) => {
      //     return Object.hasOwn(mapVal, "value")
      //       ? mapVal?.value
      //       : mapVal.keywords;
      //   })
      //   .join(" "),
      category: editFormData.category,
      quantity: editFormData.quantity,
      vendor: editFormData.vendor,
      location: editFormData.location,
      sysid: editFormData.sysid,
    };

    if (
      payload.author === "" &&
      payload.title === "" &&
      payload.description === "" &&
      payload.price === "" &&
      payload.quantity
    ) {
      setEditStockError({
        ...editStockError,
        author: true,
        title: true,

        description: true,
        price: true,
      });

      setEditStockErrorMsg({
        author: "Please enter the author",
        title: "Please enter title",

        description: "Please enter description",
        price: "Please enter price",
      });
    } else if (payload.author === "") {
      setEditStockError({
        ...editStockError,
        author: true,
      });
      setEditStockErrorMsg({
        author: "Please enter the author",
      });
    } else if (payload.title === "") {
      setEditStockError({
        ...editStockError,
        title: true,
      });
      setEditStockErrorMsg({
        title: "Please enter the title",
      });
    } else if (payload.description === "") {
      setEditStockError({
        ...editStockError,
        description: true,
      });
      setEditStockErrorMsg({
        description: "Please enter the description",
      });
    } else if (payload.price === "") {
      setEditStockError({
        ...editStockError,
        price: true,
      });
      setEditStockErrorMsg({
        price: "Please enter the price",
      });
    } else if (payload.price <= 0) {
      setEditStockError({
        ...editStockError,
        price: true,
      });
      setEditStockErrorMsg({
        price: "Price cannot not be less than 0",
      });
    } else if (payload.quantity === "") {
      setEditStockError({
        ...editStockError,
        quantity: true,
      });
      setEditStockErrorMsg({
        quantity: "Please enter the quantity",
      });
    } else if (payload.quantity <= 0) {
      setEditStockError({
        ...editStockError,
        quantity: true,
      });
      setEditStockErrorMsg({
        quantity: "Quantity cannot be less than 0",
      });
    } else if (imagesArr?.length === 0) {
      setEditStockError({
        ...editStockError,
        image: true,
      });
      setEditStockErrorMsg({
        image: "Please select image for the stock",
      });
    } else {
      // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!submit !!!!!!!!!!!!!!!!!!!!!!!!

      const formData = new FormData();

      formData.append("author", payload.author);
      formData.append("author2", payload.author2);
      formData.append("title", payload.title);
      formData.append("longtitle", payload.longtitle);
      formData.append("publisher", payload.publisher);
      formData.append("pubdate", payload.pubdate);
      formData.append("description", payload.description);
      formData.append("footnote", payload.footnote);
      formData.append("price", payload.price);
      formData.append("keywords", payload.keywords);
      formData.append("category", payload.category);
      formData.append("quantity", payload.quantity);
      formData.append("vendor", payload.vendor);
      formData.append("location", payload.location);

      imagesArr.forEach((item, index) => {
        formData.append("stockref[]", item.file);
      });

      let res = await AddNewStockService(formData);

      if (res?.status === 200 || res?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Stock added successfully!`,
        });

        if (isAddSimilar === "addSimilar") {
          hideAddSimilarDiv();
          setNewSysId(res?.data?.sysid);
          // console.log("add similar data res ---->", res);
        } else {
          hidesimilardiv();
          emptyData();
          navigate(`/${RouteStrings.stockManagement}`);
        }
      } else if (
        res?.response?.status === 400 ||
        res?.response?.status === 404 ||
        res?.response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.statusText}`,
        });
        // console.log("error", res);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.statusText}`,
        });
        // console.log("error", res);
      }
    }
  };

  const handleSelectYear = (date) => {
    setStartDate(date);
    let _year = date.getFullYear();

    setFormData({
      ...editFormData,
      pubdate: _year,
    });
  };

  // !!!!!!!!!!!!!!!! show loading for uploading the image!!!!!!!!!!!!!!!!!!
  const showLoading = () => {
    Swal.fire({
      title: "Adding Image...",
      allowOutsideClick: false, // Prevent closing the dialog by clicking outside
      showConfirmButton: false, // Hide the confirm button
      showCancelButton: false, // Hide the cancel button
      onOpen: () => {
        Swal.showLoading();
      },
      html: ` <div class="loader-container">
        <div class="loader"></div>
      </div>`,
    });
  };

  const showLoaded = () => {
    Swal.fire({
      title: "Image added",
      icon: "success",
      // html: // You can use different icons or customize as needed
    });
  };

  const handleStockImage = async (e) => {
    setEditStockError({
      ...editStockError,
      image: false,
    });

    showLoading();
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      const imageObj = {
        file: compressedFile,
      };
      imagesArr.push(imageObj);
      displayImages();
      showLoaded();
    } catch (error) {
      // console.log(error);
    }
  };

  function displayImages() {
    // !!!!!!!!!!!!
    const imageContainer = document.getElementById("imageContainer");
    // Clear the existing content in the container
    imageContainer.innerHTML = "";

    // Loop through the imagesArr and create a container div for each image
    for (let i = 0; i < imagesArr.length; i++) {
      const imageObj = imagesArr[i];
      const imageDiv = document.createElement("div");

      // Create img element for the image
      const imgElement = document.createElement("img");
      imgElement.src = URL.createObjectURL(imageObj.file);
      imgElement.classList.add("image-class");

      const deleteButton = document.createElement("button");
      deleteButton.textContent = "Delete";
      deleteButton.classList.add("delete-img-button");
      deleteButton.onclick = function () {
        deleteImage(i);
      };

      const imageName = document.createElement("div");
      imageName.textContent = imageObj.file.name;
      imageName.classList.add("image-name");

      // Add img, text, and delete button elements to the container div
      imageDiv.appendChild(imgElement);
      imageDiv.appendChild(imageName);
      imageDiv.appendChild(deleteButton);

      imageContainer.appendChild(imageDiv);
    }
  }

  function deleteImage(index) {
    // to clear the inp field value
    delImgInpRef.current.value = "";
    imagesArr.splice(index, 1);
    displayImages();
  }

  const closesimilaritemdiv = (e) => {
    e.preventDefault();
    hidesimilardiv();
  };

  return (
    <div>
      <div className="card p-3">
        <h6>Add Similar Item</h6>

        <div className="addnewitemdiv">
          <form className="newItemForm">
            <div className="item_">
              <label htmlFor="">
                Author <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="author"
                id="author"
                value={editFormData.author}
                onChange={handleChange}
                placeholder="Enter author name"
                className="input_"
              />
              {editStockError.author && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.author}</p>
                </>
              )}
            </div>

            <div className="item_">
              <label htmlFor="">Author 2 </label>
              <input
                type="text"
                name="author2"
                id="author2"
                value={editFormData.author2}
                onChange={handleChange}
                placeholder="Enter author 2 name"
                className="input_"
              />
            </div>

            <div className="item_">
              <label htmlFor="">
                Title <span className="_imp">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter title"
                className="input_"
                name="title"
                id="title"
                value={editFormData.title}
                onChange={handleChange}
              />
              {editStockError.title && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.title}</p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Longtitle</label>
              <input
                type="text"
                name="longtitle"
                id="longtitle"
                value={editFormData.longtitle}
                onChange={handleChange}
                placeholder="Enter longtitle"
                className="input_"
              />
            </div>
            <div className="item_">
              <label htmlFor="">Publisher</label>
              <input
                type="text"
                name="publisher"
                id="publisher"
                value={editFormData.publisher}
                onChange={handleChange}
                placeholder="Enter publisher name"
                className="input_"
              />
              {editStockError.publisher && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.publisher}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Pubdate</label>

              <DatePicker
                selected={startDate}
                name="date"
                value={editFormData.pubdate}
                onChange={(date) => handleSelectYear(date)}
                className="input_"
                dateFormat="yyyy"
                placeholderText="Select Year"
                showYearPicker
                yearDropdownItemNumber={10}
              />
              {editStockError.pudate && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.pudate}</p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">
                Description <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="description"
                id="description"
                value={editFormData.description}
                onChange={handleChange}
                placeholder="Enter description"
                className="input_"
              />
              {editStockError.description && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.description}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Footnote</label>
              <input
                type="text"
                name="footnote"
                id="footnote"
                value={editFormData.footnote}
                onChange={handleChange}
                placeholder="Enter footnote"
                className="input_"
              />
            </div>
            <div className="item_">
              <label htmlFor="">
                Price <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="price"
                id="price"
                value={editFormData.price}
                onChange={handleChange}
                placeholder="Enter price"
                className="input_"
              />
              {editStockError.price && (
                <>
                  <p className="mb-0 text-danger">{editStockErrorMsg.price}</p>
                </>
              )}
            </div>

            <div className="my-2">
              <div class="form-group">
                <label for="exampleFormControlSelect1">
                  Select a category from the list below
                </label>
                <select
                  class="form-control input_"
                  value={editFormData.category}
                  name="category"
                  onChange={handleChange}
                  id="exampleFormControlSelect1"
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {categoriesList?.map((item) => {
                    return (
                      <>
                        <option key={item?.reference} value={item?.reference}>
                          {item?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! groups !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            <div className="my-2">
              <div class="form-group">
                {allGroups
                  ?.map((mapVal) => Object.keys(mapVal))
                  ?.flat(1)
                  ?.map((item) => {
                    return (
                      <>
                        <p>
                          <strong>{item}</strong>
                        </p>

                        <div className="my-2">
                          <div className="row">
                            {Object?.values(
                              allGroups?.find((findVal) => findVal[item])
                            )
                              ?.flat(1)
                              ?.map((mapval) => {
                                return (
                                  <>
                                    <div className="col-md-2">
                                      <div className="d-flex align-items-center">
                                        <Form>
                                          <div className="mb-3">
                                            <Form.Check
                                              type="checkbox"
                                              id="checkbox"
                                              label={mapval?.name}
                                              value={mapval?.keywords}
                                              onChange={(e) =>
                                                handleSubcategory(e)
                                              }
                                              defaultChecked={
                                                keywordsList.includes(mapval.reference)
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        </Form>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            <div className="item_">
              <label htmlFor="">
                Quantity <span className="_imp">*</span>
              </label>
              <input
                type="text"
                name="quantity"
                id="quantity"
                value={editFormData.quantity}
                onChange={handleChange}
                placeholder="Enter quatity"
                className="input_"
              />
              {editStockError.quantity && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.quantity}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Location</label>
              <input
                type="text"
                name="location"
                id="location"
                value={editFormData.location}
                onChange={handleChange}
                placeholder="Enter location"
                className="input_"
              />

              {editStockError.location && (
                <>
                  <p className="mb-0 text-danger">
                    {editStockErrorMsg.location}
                  </p>
                </>
              )}
            </div>
            <div className="item_">
              <label htmlFor="">Vendor</label>
              <input
                type="text"
                name="vendor"
                id="vendor"
                value={editFormData.vendor}
                onChange={handleChange}
                placeholder="Enter vendor"
                className="input_"
              />
            </div>

            <div className="addImagediv d-flex flex-column">
              <label htmlFor="">
                Add Image <span className="_imp">*</span>
              </label>

              <div
                id="imageContainer"
                className="imgcontainer d-flex align-items-center my-3"
              ></div>

              <input
                type="file"
                name="stockref"
                ref={delImgInpRef}
                onChange={handleStockImage}
                placeholder="Select Image"
                className="input_image"
              />

              {editStockError?.image && (
                <>
                  <p className="text-danger">{editStockErrorMsg.image}</p>
                </>
              )}
            </div>

            <div className="submitbtn my-2">
              <CTAButton
                name="Submit"
                handleClick={submitFunUpdate}
                id="submitbtn"
              />
            </div>

            <div className="my-2">
              {isAddSimilar !== "addSimilar" && (
                <>
                  <button
                    className="btn btn-danger rounded-0"
                    onClick={closesimilaritemdiv}
                    id="cancel"
                  >
                    Cancel
                  </button>
                </>
              )}

              {isAddSimilar === "addSimilar" && (
                <>
                  <button
                    className="btn btn-danger rounded-0"
                    onClick={gobacktodiv}
                    id="backbtn"
                  >
                    Back
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
