import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "./styles.scss";
import { CTAButton } from "../../../Components/CTAButton";
import { AdminTable } from "./AdminTable";
import {
  AddNewAdminService,
  getAllAdminService,
} from "../../../Services/AdminServices";
import BounceLoader from "react-spinners/BounceLoader";
import Swal from "sweetalert2";
import axios from "axios";
import { Images } from "../../../Assets/Images/imageslist";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

// !SPINNERS

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const AdminManagement = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  let [loading, setLoading] = useState(false);
  const [adminForm, setAdminForm] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    isSuperAdmin: "",
    isSystemAdmin: "",
    isSalesAdmin: "",
  });

  const [error, setError] = useState({
    username: false,
    name: false,
    email: false,
    password: false,
    confirmpassword: false,
    isSuperAdmin: false,
    isSystemAdmin: false,
    isSalesAdmin: false,
    isSelected: false,
    isPasswordSame: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    isSuperAdmin: "",
    isSystemAdmin: "",
    isSalesAdmin: "",
    isSelected: "",
    isPasswordSame: "",
  });
  // eye icon state
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeConfirmPassword, setPasswordTypeConfirmPassword] =
    useState("password");
  const [showHide, setShowHide] = useState(true);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(true);

  const [admins, setAdmins] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setAdminForm({
      username: "",
      name: "",
      email: "",
      password: "",
      confirmpassword: "",
      isSuperAdmin: "",
      isSystemAdmin: "",
      isSalesAdmin: "",
    });

    setError({
      username: false,
      name: false,
      email: false,
      password: false,
      confirmpassword: false,
      isSuperAdmin: false,
      isSystemAdmin: false,
      isSalesAdmin: false,
      isSelected: false,
      isPasswordSame: false,
    });
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    getalladminslistsrv();
  }, []);

  const getalladminslistsrv = async () => {
    setLoading(true);
    // let res = await getAllAdminService();
    let res = await getRequest(configURL.get_all_admins);

    if (res.status === 200) {
      setLoading(false);
      setAdmins(res.data.data);
    } else {
      setLoading(false);
      // console.log("get admins error");
    }
  };

  const showHideFun = () => {
    // console.log("clicked");
    if (!showHide) {
      setShowHide(true);
      setPasswordType("password");
    } else {
      setShowHide(false);
      setPasswordType("text");
    }
  };
  const showHideFunConfirmPassword = () => {
    if (!showHideConfirmPassword) {
      setShowHideConfirmPassword(true);
      setPasswordTypeConfirmPassword("password");
    } else {
      setShowHideConfirmPassword(false);
      setPasswordTypeConfirmPassword("text");
    }
  };

  function validateEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  function validtePassword(password) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
      password
    );
  }

  function validateInput(event) {
    const inputValue = event.key;
    if (/\s/.test(inputValue)) {
      event.preventDefault(); // Prevents the input of spaces
    }
  }

  const handleChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === "username") {
      setError({
        ...error,
        username: false,
      });
      setAdminForm({
        ...adminForm,
        username: value.replace(/\s/g, ""),
      });
    }

    if (name === "name") {
      setError({
        ...error,
        name: false,
      });
      setAdminForm({
        ...adminForm,
        name: value,
      });
    }

    if (name === "email") {
      setError({
        ...error,
        email: false,
      });
      setAdminForm({
        ...adminForm,
        email: value,
      });
    }

    if (name === "password") {
      setError({
        ...error,
        password: false,
        isPasswordSame: false,
      });
      setAdminForm({
        ...adminForm,
        password: value,
      });
    }

    if (name === "confirmpassword") {
      setError({
        ...error,
        confirmpassword: false,
        isPasswordSame: false,
      });
      setAdminForm({
        ...adminForm,
        confirmpassword: value,
      });
    }

    if (name === "systemadmin") {
      setError({
        ...error,
        isSelected: false,
      });
      setAdminForm({
        ...adminForm,
        isSystemAdmin: checked,
      });
    }

    if (name === "salesadmin") {
      setError({
        ...error,
        isSelected: false,
      });
      setAdminForm({
        ...adminForm,
        isSalesAdmin: checked,
      });
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();

    if (
      adminForm.username === "" &&
      adminForm.name === "" &&
      adminForm.email === "" &&
      adminForm.password === "" &&
      adminForm.confirmpassword === "" &&
      adminForm.isSuperAdmin === "" &&
      adminForm.isSystemAdmin === "" &&
      adminForm.isSalesAdmin === ""
    ) {
      setError({
        username: true,
        name: true,
        email: true,
        password: true,
        confirmpassword: true,
        isSuperAdmin: true,
        isSystemAdmin: true,
        isSalesAdmin: true,
        isSelected: true,
      });
      setErrorMsg({
        username: "Please enter the username",
        name: "Please enter the name",
        email: "Please enter the email",
        password: "Please enter the password",
        confirmpassword: "Please enter the confirmpassword",
        isSelected: "Please select any one",
      });
    } else if (adminForm.username === "") {
      setError({
        ...error,
        username: true,
      });
      setErrorMsg({
        username: "Please enter the username",
      });
    } else if (adminForm.name === "") {
      setError({
        ...error,
        name: true,
      });
      setErrorMsg({
        username: "Please enter the username",
      });
    } else if (adminForm.email === "") {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter the email",
      });
    } else if (!validateEmail(adminForm.email)) {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter a valid email id",
      });
    } else if (adminForm.password === "") {
      setError({
        ...error,
        password: true,
      });
      setErrorMsg({
        password: "Please enter the password",
      });
    } else if (!validtePassword(adminForm.password)) {
      setError({
        ...error,
        password: true,
      });

      setErrorMsg({
        password:
          "Password must contain at least 8 characters,1 upper case, 1 lower case, 1 special character and 1 number",
      });
    } else if (adminForm.confirmpassword === "") {
      setError({
        ...error,
        confirmpassword: true,
      });
      setErrorMsg({
        confirmpassword: "Please enter the confirmpassword",
      });
    } else if (!validtePassword(adminForm.password)) {
      setError({
        ...error,
        confirmpassword: true,
      });

      setErrorMsg({
        confirmpassword:
          "Confirm Password must contain at least 8 characters,1 upper case, 1 lower case, 1 special character and 1 number",
      });
    } else if (adminForm.password !== adminForm.confirmpassword) {
      setError({
        ...error,
        isPasswordSame: true,
      });

      setErrorMsg({
        isPasswordSame:
          "Password and Confirm Password are not same, please verify",
      });
    } else if (
      (adminForm.isSystemAdmin === false || adminForm.isSystemAdmin === "") &&
      (adminForm.isSalesAdmin === false || adminForm.isSalesAdmin === "")
    ) {
      setError({
        ...error,
        isSelected: true,
      });
      setErrorMsg({
        isSelected: "Please select",
      });
    } else {
      setLoading(true);
      // console.log("the form is", adminForm);

      let _sales = "";
      let _system = "";

      if (adminForm.isSystemAdmin === true) {
        _system = 1;
      } else {
        _system = 0;
      }
      if (adminForm.isSalesAdmin === true) {
        _sales = 1;
      } else {
        _sales = 0;
      }

      let payload = {
        username: adminForm.username,
        name: adminForm.name,
        password: adminForm.password,
        system: _system,
        sales: _sales,
        email: adminForm.email,
      };
      // let response = await AddNewAdminService(payload);

      let response = await postRequest(configURL.add_new_admin, payload);

      if (response?.status === 201 || response?.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Added the new admin successfully!`,
        });
        // document.getElementById("closeNewAdminModal").click();
        handleClose();
        getalladminslistsrv();
      } else if (
        response?.response?.status === 400 ||
        response?.response?.status === 401 ||
        response?.response?.status === 404
      ) {
        setLoading(false);

        let errorObj = response?.response?.data?.error;

        const firstValue = errorObj[Object.keys(errorObj)[0]];
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error : ${firstValue} `,
        });
      } else if (response?.response?.status === 500) {
        // console.log("the 500 error log", response?.response?.data?.message);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error : ${response?.response?.data?.message} `,
        });
      } else {
        // console.log("error response", response);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const closeNewAdmin = () => {
    // console.log("close btn clicked");
  };

  return (
    <>
      {/* {loading ? (
        <>
          <div className="d-flex justify-content-center align-items-center loaderCSS h-100">
            <BounceLoader
              color="#873900"
              loading={loading}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : ( */}
      <>
        <div>
          <div>
            <BreadCrumb routename="Admin Management" />
          </div>

          <div className="">
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-primary btn_addNewAdmin"
                onClick={handleShow}
              >
                Add New Admin
              </button>
            </div>

            <AdminTable
              admins_list={admins}
              getalladminslistsrv={getalladminslistsrv}
            />
          </div>

          {/* Delete Modal */}

          <div
            class="modal fade"
            id="addNewAdmin"
            tabindex="-1"
            aria-labelledby="addNewAdminLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="addNewAdminLabel">
                    Add New Admin
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="adminForm">
                    <form onSubmit={handleForm} id="adminForm">
                      <div className="formDiv d-flex flex-column w-75">
                        <label htmlFor="">Username</label>
                        <input
                          type="text"
                          name="username"
                          onChange={handleChange}
                          id="username"
                          className="inpAddAdmin"
                          placeholder="Enter the username"
                        />
                        {error.username && (
                          <span className="text-danger">
                            {errorMsg.username}
                          </span>
                        )}
                      </div>
                      <div className="formDiv d-flex flex-column w-75">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          id="name"
                          className="inpAddAdmin"
                          placeholder="Enter the name"
                        />
                        {error.name && (
                          <span className="text-danger">{errorMsg.name}</span>
                        )}
                      </div>
                      <div className="formDiv d-flex flex-column w-75">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          onChange={handleChange}
                          className="inpAddAdmin"
                          placeholder="Enter the email"
                        />
                        {error.email && (
                          <span className="text-danger">{errorMsg.email}</span>
                        )}
                      </div>
                      <div className="formDiv d-flex flex-column position-relative w-75">
                        <label htmlFor="">Password</label>
                        <input
                          type={passwordType}
                          name="password"
                          id="password"
                          onChange={handleChange}
                          className="inpAddAdmin w-100"
                          placeholder="Enter the password"
                        />
                        {showHide ? (
                          <img
                            src={Images.hide}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFun}
                          />
                        ) : (
                          <img
                            src={Images.show}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFun}
                          />
                        )}
                        {error.password && (
                          <span className="text-danger">
                            {errorMsg.password}
                          </span>
                        )}
                      </div>
                      <div className="formDiv d-flex flex-column position-relative w-75">
                        <label htmlFor="">Confirm password</label>
                        <input
                          type={passwordTypeConfirmPassword}
                          name="confirmpassword"
                          id="confirmpassword"
                          onChange={handleChange}
                          className="inpAddAdmin"
                          placeholder="Enter the confirm password"
                        />
                        {showHideConfirmPassword ? (
                          <img
                            src={Images.hide}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFunConfirmPassword}
                          />
                        ) : (
                          <img
                            src={Images.show}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFunConfirmPassword}
                          />
                        )}
                        {error.confirmpassword && (
                          <span className="text-danger">
                            {errorMsg.confirmpassword}
                          </span>
                        )}
                      </div>

                      {error.isPasswordSame && (
                        <span className="text-danger">
                          {errorMsg.isPasswordSame}
                        </span>
                      )}

                      <div class="form-check d-flex align-items-center mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="systemadmin"
                          onChange={handleChange}
                          value="systemadmin"
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Is System Admin
                        </label>
                      </div>
                      <div class="form-check d-flex align-items-center mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          onChange={handleChange}
                          name="salesadmin"
                          value="salesadmin"
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Is Sales Admin
                        </label>
                      </div>
                      {error.isSelected && (
                        <span className="text-danger">
                          {errorMsg.isSelected}
                        </span>
                      )}

                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          id="closeNewAdminModal"
                          data-dismiss="modal"
                          onClick={closeNewAdmin}
                        >
                          Close
                        </button>
                        <button type="submit" class="btn btn-primary">
                          Add New Admin
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* add new admin new modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title> Add New Admin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="adminForm">
                {/* <form onSubmit={handleForm} id="adminForm"> */}
                <div className="formDiv d-flex flex-column w-75">
                  <label htmlFor="">Username</label>
                  <input
                    type="text"
                    name="username"
                    onChange={handleChange}
                    onKeyDown={validateInput}
                    id="username"
                    className="inpAddAdmin"
                    placeholder="Enter the username"
                  />
                  {error.username && (
                    <span className="text-danger">{errorMsg.username}</span>
                  )}
                </div>
                <div className="formDiv d-flex flex-column w-75">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    id="name"
                    className="inpAddAdmin"
                    placeholder="Enter the name"
                  />
                  {error.name && (
                    <span className="text-danger">{errorMsg.name}</span>
                  )}
                </div>
                <div className="formDiv d-flex flex-column w-75">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    className="inpAddAdmin"
                    placeholder="Enter the email"
                  />
                  {error.email && (
                    <span className="text-danger">{errorMsg.email}</span>
                  )}
                </div>
                <div className="formDiv d-flex flex-column position-relative w-75">
                  <label htmlFor="">Password</label>
                  <input
                    type={passwordType}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    className="inpAddAdmin w-100"
                    placeholder="Enter the password"
                  />
                  {showHide ? (
                    <img
                      src={Images.hide}
                      alt=""
                      className="showHideIcon"
                      onClick={showHideFun}
                    />
                  ) : (
                    <img
                      src={Images.show}
                      alt=""
                      className="showHideIcon"
                      onClick={showHideFun}
                    />
                  )}
                  {error.password && (
                    <span className="text-danger">{errorMsg.password}</span>
                  )}
                </div>
                <div className="formDiv d-flex flex-column position-relative w-75">
                  <label htmlFor="">Confirm password</label>
                  <input
                    type={passwordTypeConfirmPassword}
                    name="confirmpassword"
                    id="confirmpassword"
                    onChange={handleChange}
                    className="inpAddAdmin"
                    placeholder="Enter the confirm password"
                  />
                  {showHideConfirmPassword ? (
                    <img
                      src={Images.hide}
                      alt=""
                      className="showHideIcon"
                      onClick={showHideFunConfirmPassword}
                    />
                  ) : (
                    <img
                      src={Images.show}
                      alt=""
                      className="showHideIcon"
                      onClick={showHideFunConfirmPassword}
                    />
                  )}
                  {error.confirmpassword && (
                    <span className="text-danger">
                      {errorMsg.confirmpassword}
                    </span>
                  )}
                </div>

                {error.isPasswordSame && (
                  <span className="text-danger">{errorMsg.isPasswordSame}</span>
                )}

                <div class="form-check d-flex align-items-center mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="systemadmin"
                    onChange={handleChange}
                    value="systemadmin"
                    id="defaultCheck1"
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    Is System Admin
                  </label>
                </div>
                <div class="form-check d-flex align-items-center mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={handleChange}
                    name="salesadmin"
                    value="salesadmin"
                    id="defaultCheck1"
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    Is Sales Admin
                  </label>
                </div>
                {error.isSelected && (
                  <span className="text-danger">{errorMsg.isSelected}</span>
                )}

                {/* </form> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary rounded-0" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary rounded-0" onClick={handleForm}>
                Add New Admin
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
      {/* )} */}
    </>
  );
};
