import axios from "axios";
import { store } from "../Store/store";

const axiosInstance = axios.create({
  baseURL: "http://www.dollarbirdtechnologies.com/db_dev/pennymead"
});


// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  let accessToken = store.getState().UIStorage.auth.accesstoken
  config.headers["Authorization"] = `Bearer ${accessToken}`
  // config.headers["Authorization"] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2OTc0NzMxODksImV4cCI6MTY5NzQ3Njc4OSwiZGF0YSI6eyJ1c2VyX2lkIjoiMTUwIn19._IqKI-Y23lkYhkkfW2EFfZRreIVvoNWfaCZZ4OCh6Ek`

  return new Promise((resolve, reject) => {
    resolve(config);
  })
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  })
}, (error) => {

  if (error.response.status === 401 && (error.response.data.error).toLowerCase() === "expired token") {
    console.log("🚀 ~ file: AxiosInstance.js:31 ~ axiosInstance.interceptors.response.use ~ error:", error.response.data.error)
  }

  return Promise.reject(error);
});

export default axiosInstance;
