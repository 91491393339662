import React, { useEffect, useState } from "react";
import { Images } from "../../../../Assets/Images/imageslist";
import "./styles.scss";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import BounceLoader from "react-spinners/BounceLoader";
import { TransactionTable } from "../OrderTable/TransactionTable";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const ViewPackedOrderById = ({ data, orderno }) => {
  const { getRequest, postRequest } = ApiResponseHook()
  const location = useLocation();
  let [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [packageData, setPackageData] = useState([]);

  const [values, setValues] = useState({
    row: 0,
    col: 0,
  });

  const [allCountries, setAllCountries] = useState([]);
  const [editAddress, setEditAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });

  const navigate = useNavigate();

  const [editError, setEditError] = useState({
    address1: false,
    address2: false,
    city: false,
    county: false,
    postcode: false,
    country: false,
    orderno: false,
  });
  const [editErrorMsg, setEditErrorMsg] = useState({
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });

  const [updateOrder, setUpdateOrder] = useState({
    postage: "",
    pricechange: "",
    note: "",
    orderno: "",
  });

  useEffect(() => {
    getOrderDetailsById();
    getAllCountries();
  }, []);

  const handleAddressChange = (e) => {
    let { name, value } = e.target;

    if (name === "address1") {
      setEditError({
        ...editError,
        address1: false,
      });
      setEditAddress({
        ...editAddress,
        address1: value,
      });
    }
    if (name === "address2") {
      setEditError({
        ...editError,
        address2: false,
      });
      setEditAddress({
        ...editAddress,
        address2: value,
      });
    }
    if (name === "city") {
      setEditError({
        ...editError,
        city: false,
      });
      setEditAddress({
        ...editAddress,
        city: value,
      });
    }
    if (name === "county") {
      setEditError({
        ...editError,
        county: false,
      });
      setEditAddress({
        ...editAddress,
        county: value,
      });
    }
    if (name === "postcode") {
      setEditError({
        ...editError,
        postcode: false,
      });
      setEditAddress({
        ...editAddress,
        postcode: value,
      });
    }
    if (name === "country") {
      setEditError({
        ...editError,
        country: false,
      });
      setEditAddress({
        ...editAddress,
        country: value,
      });
    }
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault(); // Prevents the input of numbers
    }
  }

  const getAllCountries = async () => {
    // let res = await GetAllCountriesService();
    let res = await getRequest(configURL.get_all_countries);

    if (res?.status === 200 || res?.status === 201) {
      setAllCountries(res?.data?.data);
    } else {
      // console.log("error getting all the countries", res);
    }
  };

  const getOrderDetailsById = async () => {
    let _orderno = location?.state?.data?.order?.orderno || orderno;

    // let res = await GetOrderDetailsPackedOrderById(_orderno);
    let res = await getRequest(
      `${configURL.get_packed_order_details}/${_orderno}/`
    );
    if (res?.status === 201 || res?.status === 200) {
      setPackageData(res?.data?.data);
      setUpdateOrder({
        ...updateOrder,
        postage: res?.data?.data?.postage,
        pricechange: res?.data?.data?.pricechange,
        note: res?.data?.data?.sellnote,
        orderno: res?.data?.data?.orderno,
      });

      setEditAddress({
        ...editAddress,
        address1: res?.data?.data?.address1,
        address2: res?.data?.data?.address2,
        city: res?.data?.data?.town,
        county: res?.data?.data?.county,
        postcode: res?.data?.data?.postcode,
        country: res?.data?.data?.country,
        orderno: res?.data?.data?.orderno,
      });
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res?.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const updateCustomerAddress = async () => {
    if (
      editAddress.address1 === "" &&
      editAddress.city === "" &&
      editAddress.country === "" &&
      editAddress.county === "" &&
      editAddress.postcode === ""
    ) {
      setEditError({
        ...editError,
        address1: true,
        city: true,
        country: true,
        county: true,
        postcode: true,
      });

      setEditErrorMsg({
        address1: "Please enter address 1",
        city: "Please enter city",
        country: "Please enter country",
        county: "Please enter county",
        postcode: "Please enter postcode",
      });
    } else if (editAddress.address1 === "") {
      setEditError({
        editError,
        address1: true,
      });
      setEditErrorMsg({
        address1: "Please enter address 1",
      });
    } else if (editAddress.city === "") {
      setEditError({
        editError,
        city: true,
      });
      setEditErrorMsg({
        city: "Please enter city",
      });
    } else if (editAddress.country === "") {
      setEditError({
        editError,
        country: true,
      });
      setEditErrorMsg({
        country: "Please enter country",
      });
    } else if (editAddress.county === "") {
      setEditError({
        editError,
        county: true,
      });
      setEditErrorMsg({
        county: "Please enter county",
      });
    } else if (editAddress.postcode === "") {
      setEditError({
        editError,
        postcode: true,
      });
      setEditErrorMsg({
        postcode: "Please enter postcode",
      });
    } else {
      let payload = {
        address1: editAddress.address1,
        address2: editAddress.address2,
        city: editAddress.city,
        county: editAddress.county,
        postcode: editAddress.postcode,
        country: editAddress.country,
        package_id: editAddress.orderno,
      };

      // let res = await EditPakcedCustomerAddressService(payload);
      let res = await postRequest(
        configURL.add_additional_packed_order,
        payload
      );
      if (res?.status === 201 || res?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the address successfully`,
        });
        getOrderDetailsById();
        setIsEdit(false);
      } else if (
        res?.response?.status === 400 ||
        res?.response?.status === 401 ||
        res?.response?.status === 404 ||
        res?.response?.status === 500
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const generateLabelFun = (e) => {
    e.preventDefault();

    const htmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          .grid-container-test {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: repeat(6, 1fr);
            border: 1px solid;
          }
          
          .grid-item {
            border: 1px solid #000;
            padding: 10px;
          }
        </style>
      </head>
      <body>
        <div class="grid-container-test">
          <div class="grid-item" style="grid-row-start: ${values.row
      }; grid-row-end: ${values.row + 1}; grid-column-start: ${values.col
      }; grid-column-end: ${values.col + 1};">
      <p>${packageData?.name}</p>
      <p>${packageData?.address1 || "--"}</p>
      <p>${packageData?.address2 || "--"}</p>
      <p>${packageData?.town || "--"}</p>
      <p>${packageData?.state || "--"}</p>
      <p>${packageData?.country || "--"}</p>
      <p>${packageData?.postcode || "--"}</p>
      <p>${packageData?.email || "--"}</p>
          </div>
        </div>
      </body>
    </html>
  `;

    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();

    // Wait for the content to load and then print the content as a PDF
    newWindow.onload = () => {
      newWindow.print();
      newWindow.close();
    };
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "postage_charge") {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
      } else {
        setUpdateOrder({
          ...updateOrder,
          postage: value,
        });
      }
    }
    if (name === "adjustment") {
      setUpdateOrder({
        ...updateOrder,
        pricechange: value,
      });
    }
    if (name === "note") {
      setUpdateOrder({
        ...updateOrder,
        note: value,
      });
    }
  };

  const addAdditionalDetails = async () => {
    // setLoading(true);

    let payload = {
      postage_charge: updateOrder.postage,
      adjustment: updateOrder.pricechange,
      note: updateOrder.note,
      package_id: updateOrder.orderno,
    };

    // let res = await AddPakcedAdditionalOrderService(payload);
    let res = await postRequest(
      `${configURL.add_postage_price_change_packed}/`,
      payload
    );

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the order successfully`,
      });
      getOrderDetailsById();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const sendInvoiceFun = async () => {
    // setLoading(true);

    // console.log("packageData.orderno==============>", packageData.orderno);

    // let res = await SendPackedOrderInvoiceService(packageData.orderno);
    let res = await getRequest(
      `${configURL.sendInvoice_packed_order}/${packageData.orderno}/`
    );
    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Invoice sent successfully!`,
      });
      getOrderDetailsById();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const markPaymentReceived = async () => {
    // let res = await PackedOrderMarkPaymentReceviedServcie(packageData?.orderno);
    let res = await getRequest(
      `${configURL.mark_payment_received_packed_order}/${packageData?.orderno}/`
    );

    if (res?.status === 201 || res?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Marked Payment Received`,
      });
      getOrderDetailsById();
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const generateInvoicePage = () => {
    let customerDetails = {
      name: packageData?.name,
      address1: packageData?.address1,
      address2: packageData?.address2,
      town: packageData?.town,
      county: packageData?.county,
      postcode: packageData?.postcode,
      country: packageData?.country,
      email: packageData?.email,
    };

    navigate(`/${RouteStrings.orderPackedInvoicePage}`, {
      state: { data: { order: packageData, customer: customerDetails } },
    });
  };

  const sendPaymentReminderFun = async () => {
    // let res = await SendPaymentRemainderPackedService(packageData?.orderno);
    let res = await getRequest(
      `${configURL.send_payment_remainder_packed}/${packageData?.orderno}/`
    );

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Reminder sent successfully!`,
      });
      getOrderDetailsById();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  const unpackOrder = async () => {
    // let res = await UnpackedOrdersService(packageData.orderno);
    let res = await getRequest(
      `${configURL.unpacke_orders}/${packageData.orderno}/`
    );
    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Unpacked the order successfully`,
      });
      document.getElementById("closeunpackOrderModal").click();
      navigate(`${RouteStrings.orderManagement}`);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // console.log("errorr", res);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      document.getElementById("closeunpackOrderModal").click();
    } else {
      // console.log("errorr", res);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      document.getElementById("closeunpackOrderModal").click();
    }
  };

  const [deliveryStatus, setDeiveryStatus] = useState("");
  const [deliveryNote, setDeliveryNote] = useState("");
  const handleDeliveryStatus = (e) => {
    setDeiveryStatus(e.target.value);
  };

  const updateDeliveryStatus = async () => {
    let payload = {
      package_status: deliveryStatus,
      package_note: deliveryNote,
      package_id: packageData?.orderno,
    };

    // let res = await ChangePackedOrderStatusService(paylaod);
    let res = await postRequest(
      configURL.changepackedorderstatus,
      payload
    );

    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Delivery Status Updated`,
      });

      getOrderDetailsById();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${res?.response?.data?.message} || error updating the delivery status`,
      });
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="d-flex justify-content-center align-items-center loaderCSS w-100">
            <BounceLoader
              color="#873900"
              loading={loading}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <BreadCrumb
              routename="Order Invoice"
              isBack={true}
              gotoBackFun={gotoback}
            />

            <div className="d-flex justify-content-between"></div>
            <div className="viewOrderByIdPackedOrder">
              <div className="row">
                {packageData?.invoiced === "0" &&
                  packageData?.transtatus === "0" && (
                    <>
                      <div className="d-flex justify-content-end w-100">
                        <div className="mx-5">
                          <div className="dropdown">
                            <img
                              src={Images.more}
                              alt=""
                              className="moreIcon"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            />

                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item text-center"
                                data-toggle="modal"
                                data-target="#unpackModal"
                              >
                                <strong>Unpack Orders</strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* {packageData?.invoiced === "1" &&
                  packageData?.transtatus === "0" && (
                    <>
                      <div className="d-flex justify-content-end w-100">
                        <div className="mx-5">
                          <div className="dropdown">
                            <img
                              src={Images.more}
                              alt=""
                              className="moreIcon"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            />

                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item text-center"
                                data-toggle="modal"
                                data-target="#unpackModal"
                              >
                                <strong>Unpack Orders</strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}

                <div className="col-md-6">
                  <div className="orderItemsDetails">
                    <h6 className="mb-3">Order Details</h6>

                    <h7 className="mr-2">
                      <strong>Package Id :</strong> {packageData?.orderno}
                    </h7>

                    <div className="itemsOrder">
                      {packageData?.orders?.map((order) => {
                        return (
                          <>
                            <div className="d-flex flex-column">
                              <div>
                                <h7 className="mr-2">
                                  <strong>Order Number :</strong>{" "}
                                  {order?.orderno}
                                </h7>
                                <h7>
                                  <strong>Order Date :</strong> {order?.ordate}
                                </h7>
                              </div>

                              {order?.order_item?.map((item) => {
                                return (
                                  <>
                                    <div className="itemslist my-3 w-100">
                                      <div className="row mb-2">
                                        <div className="col-md-6">
                                          <img
                                            src={
                                              item?.stockimages[0] ||
                                              Images.booksimage
                                            }
                                            alt=""
                                            className="image"
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <div className="infodiv w-100">
                                            <h6>{item?.title}</h6>
                                            <p>
                                              <strong>Keywords : </strong>{" "}
                                              {item?.keywords}
                                            </p>
                                            <p>
                                              <strong>Price :£ </strong>{" "}
                                              {item?.price}
                                            </p>
                                            <p className="mb-0">
                                              {item?.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="customerDetails">
                    <h5 className="subHeading">Customer Details</h5>
                    <div className="mb-1 d-flex ">
                      <label htmlFor="" className="mb-0">
                        Name :
                      </label>

                      <p className="mb-0 ">{packageData?.name || "--"}</p>
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="mb-0">
                              Address line 1
                              <span className="text-danger mr-1">*</span> :
                            </label>
                            <input
                              type="text"
                              name="address1"
                              id="address1"
                              value={editAddress?.address1}
                              onChange={handleAddressChange}
                              placeholder="Enter the address1"
                              className="inputTag w-75"
                            />
                            {editError.address1 && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.address1}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            Address line 1 :
                          </label>
                          <p className="mb-0">
                            {packageData?.address1 || "--"}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-100 ">
                            <label htmlFor="" className="mb-0">
                              Address line 2 :
                            </label>
                            <input
                              type="text"
                              name="address2"
                              id="address2"
                              value={editAddress?.address2}
                              placeholder="Enter the address2"
                              onChange={handleAddressChange}
                              className="inputTag w-75"
                            />
                            {editError.address2 && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.address2}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            Address line 2 :
                          </label>
                          <p className="mb-0">
                            {packageData?.address2 || "--"}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="mb-0">
                              Town/City{" "}
                              <span className="text-danger mr-1">*</span> :
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              value={editAddress?.city}
                              placeholder="Enter the town"
                              onKeyDown={validateInput}
                              onChange={handleAddressChange}
                              className="inputTag w-75"
                            />
                            {editError.city && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.city}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            Town/City :
                          </label>
                          <p className="mb-0">{packageData?.town || "--"}</p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="mb-0">
                              State <span className="text-danger mr-1">*</span>{" "}
                              :
                            </label>
                            <input
                              type="text"
                              name="county"
                              id="county"
                              value={editAddress?.county}
                              onKeyDown={validateInput}
                              placeholder="Enter the county"
                              onChange={handleAddressChange}
                              className="inputTag w-75"
                            />
                            {editError.county && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.county}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            State :
                          </label>
                          <p className="mb-0">{packageData?.county || "--"}</p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-100">
                            <label htmlFor="" className="mb-0">
                              Postcode/ZIP code{" "}
                              <span className="text-danger mr-1">*</span>:
                            </label>
                            <input
                              type="text"
                              name="postcode"
                              id="postcode"
                              value={editAddress?.postcode}
                              placeholder="Enter the postcode"
                              onChange={handleAddressChange}
                              className="inputTag w-75"
                            />
                            {editError.postcode && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.postcode}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            Postcode/ZIP code :
                          </label>
                          <p className="mb-0">
                            {packageData?.postcode || "--"}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      {isEdit && (
                        <>
                          <div className="d-flex flex-column w-75">
                            <label htmlFor="" className="mb-0">
                              Country{" "}
                              <span className="text-danger mr-1">*</span> :
                            </label>

                            <select
                              className="form-control inpCategory w-75"
                              onChange={handleAddressChange}
                              value={editAddress?.country}
                              name="country"
                              id="exampleFormControlSelect1"
                            >
                              <option value="" selected disabled>
                                Select Country
                              </option>
                              {allCountries?.map((item) => {
                                return (
                                  <>
                                    <option
                                      key={item?.reference}
                                      value={item?.reference}
                                    >
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                            {editError.country && (
                              <>
                                <p className="mb-0 text-danger">
                                  {editErrorMsg.country}
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {!isEdit && (
                        <>
                          <label htmlFor="" className="mb-0">
                            Country :
                          </label>
                          <p className="mb-0">{packageData?.country || "--"}</p>
                        </>
                      )}
                    </div>
                    <div className="mb-1 d-flex align-items-center">
                      <label htmlFor="" className="mb-0">
                        Email ID :
                      </label>

                      <p className="mb-0">{packageData?.email || "--"}</p>
                    </div>

                    {isEdit && (
                      <>
                        <button
                          className="btn btn-danger rounded-0 mr-2"
                          onClick={() => setIsEdit(false)}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn btn-info rounded-0"
                          onClick={updateCustomerAddress}
                        >
                          Update Address
                        </button>
                      </>
                    )}
                  </div>

                  <div className="w-75">
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column">
                        <label htmlFor="">Row</label>
                        <input
                          type="number"
                          className="inputTag mr-2"
                          placeholder="row"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              row: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column">
                        <label htmlFor="">Column</label>

                        <input
                          type="number"
                          className="inputTag"
                          placeholder="col"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              col: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary rounded-0 my-2"
                      onClick={generateLabelFun}
                    >
                      Generate Label
                    </button>
                  </div>
                </div>
              </div>

              {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! cta buttons !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}

              {packageData?.invoiced === "0" &&
                packageData?.transtatus === "0" && (
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-0 subHeading">
                        Order Subtotal : £ {packageData?.total}
                      </h5>

                      <table border="1" className="my-2">
                        <tbody>
                          <tr>
                            <td>Postage and Packaging charge</td>
                            <td>
                              <input
                                type="text"
                                min="0"
                                className="inputTag"
                                value={updateOrder.postage}
                                name="postage_charge"
                                id="postage_charge"
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Price Adjustment</td>
                            <td>
                              <input
                                type="text"
                                name="adjustment"
                                id="adjustment"
                                value={updateOrder.pricechange}
                                className="inputTag"
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Order Total </td>
                            <td>£ {packageData?.grand_total || "--"}</td>
                          </tr>
                          <tr>
                            <td>Payment Method</td>
                            <td>{packageData?.paymeth || "--"}</td>
                          </tr>
                          <tr>
                            <td>Note to buyer (optional) : </td>
                            <td>
                              <textarea
                                name="note"
                                id="note"
                                cols="30"
                                rows="10"
                                placeholder="Enter note from buyer"
                                className="inputTextArea"
                                value={updateOrder.note || ""}
                                onChange={handleChange}
                              ></textarea>
                            </td>
                          </tr>
                          <tr>
                            <td>Note from buyer : </td>
                            <td>{packageData?.sellnotes || "--"}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="my-2">
                        <button
                          className="btn btn-primary rounded-0"
                          onClick={addAdditionalDetails}
                        >
                          Update
                        </button>
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <button
                            className="mr-2 btn btn-info rounded-0"
                            onClick={() => setIsEdit(!isEdit)}
                          >
                            Edit Address
                          </button>
                        </div>

                        <button
                          className="btn btn-secondary rounded-0 my-2 mr-2"
                          onClick={sendInvoiceFun}
                        >
                          Send Invoice
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {packageData?.invoiced === "1" &&
                packageData?.transtatus === "0" && (
                  <>
                    <div className="my-3">
                      <div className="totalsdiv">
                        <h7>
                          <strong>Order Subtotal :</strong> £
                          {packageData?.total}
                        </h7>

                        <p>
                          <strong>Postage and packaging : </strong>
                          {/* £{packageData?.postage} */}
                          {packageData?.postage
                            ? `£${packageData?.postage}`
                            : "---"}
                        </p>
                        <p>
                          <strong>Price Adjustment : </strong>
                          {/* {packageData?.pricechange} */}
                          {packageData?.pricechange
                            ? `£${packageData?.pricechange}`
                            : "---"}
                        </p>

                        <h6>Order : £{packageData?.grand_total}</h6>

                        <h6>Payment Method : {"---"}</h6>
                      </div>
                    </div>

                    <div className="ctabuttonsdiv">
                      <button
                        className="btn btn-primary rounded-0 my-2 mr-2"
                        onClick={() => setIsEdit(!isEdit)}
                      >
                        Edit Address
                      </button>

                      <button
                        className="btn btn-primary rounded-0 my-2 mr-2"
                        onClick={markPaymentReceived}
                      >
                        Mark Payment Received
                      </button>

                      <button
                        className="btn btn-info rounded-0 my-2 mr-2"
                        onClick={sendPaymentReminderFun}
                      >
                        Send Payment Reminder
                      </button>

                      <button
                        className="btn btn-secondary rounded-0 my-2 mr-2"
                        onClick={generateInvoicePage}
                      >
                        View Invoice
                      </button>
                    </div>
                  </>
                )}

              {packageData?.invoiced === "1" &&
                packageData?.transtatus === "1" && (
                  <>
                    <div className="my-3">
                      <div className="totalsdiv">
                        <h7>
                          <strong>Order Subtotal :</strong> £
                          {packageData?.total}
                        </h7>

                        <p>
                          <strong>Postage and packaging : </strong>
                          {/* £{packageData?.postage} */}

                          {packageData?.postage
                            ? `£${packageData?.postage}`
                            : "---"}
                        </p>
                        <p>
                          <strong>Price Adjustment : </strong>
                          {/* {packageData?.pricechange} */}
                          {packageData?.pricechange
                            ? `£${packageData?.pricechange}`
                            : "---"}
                        </p>

                        <h6>Order : £{packageData?.grand_total}</h6>

                        <h6>Payment Method : {"---"}</h6>
                      </div>
                    </div>

                    <button
                      className="btn btn-secondary rounded-0 my-2 mr-2"
                      onClick={generateInvoicePage}
                    >
                      View Invoice
                    </button>
                  </>
                )}

              {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!! for paid orders packed !!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
              {packageData?.invoiced === "1" &&
                packageData?.transtatus === "1" &&
                (packageData.package_status === null ||
                  packageData.package_status === "" ||
                  packageData.package_status === "packed" ||
                  packageData.package_status === "dispatch") && (
                  <>
                    <div className="w-75">
                      <div className="paymentstatusdropdown">
                        <div className="d-flex flex-column w-75">
                          <label htmlFor="" className="mb-0">
                            Delivery Status :
                          </label>

                          <select
                            className="form-control inpCategory w-75"
                            onChange={handleDeliveryStatus}
                            defaultValue={packageData?.package_status}
                            name="package_status"
                            id="exampleFormControlSelect1"
                          >
                            <option value="" selected disabled>
                              Select Delivery Status
                            </option>

                            <option value="packed">Packed</option>
                            <option value="dispatch">Dispatch</option>
                            <option value="delivered">Delivered</option>
                          </select>
                        </div>

                        <div className="d-flex flex-column my-2 _deliverynote">
                          <label htmlFor="">Delivery Note</label>
                          <textarea
                            name="package_note"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Enter the delivery note..."
                            className="inpTextArea"
                            defaultValue={packageData?.package_note}
                            onChange={(e) => setDeliveryNote(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="my-2">
                          <button
                            className="btn btn-info rounded-0"
                            onClick={updateDeliveryStatus}
                          >
                            Update Status
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {packageData?.invoiced === "1" &&
                packageData?.transtatus === "1" &&
                (packageData.package_status === null ||
                  packageData.package_status === "" ||
                  packageData.package_status === "packed" ||
                  packageData.package_status === "dispatch" ||
                  packageData.package_status === "delivered") && (
                  <>
                    <div className="transactionTable my-4">
                      <div className="table_div">
                        <h5 className="subHeading">Transaction Reference</h5>
                        <TransactionTable
                          transactiondata={packageData?.transaction_details}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>

            {/* unpack modal */}
            <div
              className="modal fade"
              id="unpackModal"
              tabindex="-1"
              aria-labelledby="unpackModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="unpackModalLabel">
                      Unpack Orders
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to unpack the order?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary rounded-0"
                      data-dismiss="modal"
                      id="closeunpackOrderModal"
                    >
                      Close
                    </button>

                    <>
                      <button
                        type="button"
                        className="btn btn-danger rounded-0"
                        onClick={unpackOrder}
                      >
                        Unpack
                      </button>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
