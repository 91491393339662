import React, { useCallback, useEffect } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { CTAButton } from "../../../../Components/CTAButton";
import { useReactToPrint } from "react-to-print";

export const OrderInvoicePage = () => {
  const location = useLocation();

  const _orderData = location?.state?.data;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();

  const gotoback = () => {
    navigate(`/${RouteStrings.orderManagement}`);
  };

  // const toprintData = () => {
  //   console.log("clicked!!!!!!!!");
  //   // Get the content of the div with the id "toprintscreen"
  //   var printContent = document.getElementById("toprintscreen").innerHTML;

  //   // Create a new window for printing
  //   var printWindow = window.open("", "", "width=600,height=600");

  //   // Write the content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write("<html><head><title>Print</title></head><body>");
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   // Print the new window
  //   printWindow.print();

  //   // Close the new window after printing
  //   printWindow.close();
  // };

  const getOnlyDateFromDateTime = (date_) => {
    const parts = date_.split(" ");

    const datePart = parts[0];

    return datePart;
  };

  // const toprintData = () => {
  //   // Get the content of the div with the id "toprintscreen"
  //   var printContent = document.getElementById("toprintscreen").innerHTML;

  //   // Create a new window for printing
  //   var printWindow = window.open("", "", "width=600,height=600");

  //   // Write the content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write("<html><head><title>Print</title></head><body>");
  //   printWindow.document.write(printContent);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   // Print the new window
  //   printWindow.print();

  //   // Close the new window after printing
  //   printWindow.close();
  // };

  const componentRef = React.useRef();

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "invoice",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <>
      <div className="d-flex justify-content-between">
        <BreadCrumb
          routename="Order Invoice"
          isBack={true}
          gotoBackFun={gotoback}
        />

        <div className="mx-5">
          {/* <CTAButton name="Print" handleClick={toprintData} /> */}
          <CTAButton name="Print" handleClick={handlePrint} id="submitbtn" />
        </div>
      </div>

      {/* ----------------------- */}
      <div ref={componentRef} className="toprintscreen p-5">
        <div style={{ width: "100%" }}>
          <h4>Pennymead Books</h4>
          <p>1 Brewerton Street, Knaresborough, HG5 8AZ</p>
          <p>(Proprietor David Druett)</p>
          <p>Tel. 01423 865962</p>
        </div>
        <hr />

        <div className="my-3">
          <h4 className="">Order Invoice</h4>
        </div>

        <div className="orderdatediv">
          {/* <h6>Order Placed : {_orderData?.customer[0]?.ordate}</h6> */}
          <h6>
            Order Placed :
            {getOnlyDateFromDateTime(_orderData?.customer[0]?.ordate)}
          </h6>

          <h6>Order Number :{_orderData?.customer[0]?.orderno}</h6>
        </div>

        <hr />
        <div className="customerDetailsDiv mt-3">
          <h4 className="my-2">Customer Details</h4>

          <div className="details">
            <p>
              <>
                <strong>Customer Name :</strong>
              </>
              {_orderData?.customer[0]?.name || "--"}
            </p>
            <p>
              <strong>Address Line 1 : </strong>
              {_orderData?.customer[0]?.address1 || "--"}
            </p>
            <p>
              <strong>Address Line 2 :</strong>
              {_orderData?.customer[0]?.address2 || "--"}
            </p>
            <p>
              <strong>Town/City</strong> {_orderData?.customer[0]?.town || "--"}
            </p>
            <p>
              <strong>County/State :</strong>
              {_orderData?.customer[0]?.county || "--"}
            </p>
            <p>
              <strong>Postcode/ZIP Code :</strong>
              {_orderData?.customer[0]?.postcode || "--"}
            </p>
            <p>
              <strong>Country :</strong>
              {_orderData?.customer[0]?.country || "--"}
            </p>
            <p>
              <strong>Email Address :</strong>
              {_orderData?.customer[0]?.email || "--"}
            </p>
          </div>
        </div>

        <hr />

        {_orderData?.order?.orders?.length >= 2 ? (
          <>
            <div className="itemsorderediv mt-3">
              <h3 className="my-2">Orders</h3>
            </div>

            <hr />

            {_orderData?.order?.orders?.map((item) => {
              return (
                <>
                  <div>
                    <div className="d-flex flex-column">
                      <div>
                        <span>
                          <strong>Order Number : </strong>
                        </span>{" "}
                        {item?.orderno}
                      </div>
                      <div>
                        <span>
                          <strong>Order Date : </strong>
                        </span>{" "}
                        {item?.ordate}
                      </div>
                    </div>

                    {/* <span>----------------</span> */}

                    {item?.order_item?.map((subItem) => {
                      return (
                        <>
                          <h5>
                            <strong>{subItem?.title}</strong>
                          </h5>
                          {/* <h7>{subItem?.author}</h7> */}
                          <p>{subItem?.author}</p>
                          <p>{subItem?.description}</p>

                          <p>£{subItem?.price}</p>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <>
            <div className="itemsorderediv mt-3">
              <h4 className="my-2">Items Ordered</h4>
            </div>

            <div className="itemslist">
              {_orderData?.order?.map((item) => {
                return (
                  <>
                    <h5>
                      <strong>{item?.title}</strong>
                    </h5>
                    {/* <h7>{item?.author}</h7> */}
                    <p>{item?.author}</p>
                    <p>{item?.description}</p>

                    <p>£{item?.price}</p>
                  </>
                );
              })}

              <p>
                <strong>Sub Total :</strong> £{_orderData?.customer[0]?.total}
              </p>

              <p>
                <strong>Postage and Packaging :</strong> £
                {_orderData?.customer[0]?.postage}
              </p>

              <p>
                <strong>Price Adjustment :</strong>
                {_orderData?.customer[0]?.pricechange
                  ? `£${_orderData?.customer[0]?.pricechange}`
                  : `---`}
              </p>

              <p>
                <strong>Total :</strong> £{_orderData?.customer[0]?.grand_total}
              </p>

              <hr />
            </div>
          </>
        )}
      </div>
    </>
  );
};
