import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../../../Components/Breadcrumb";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { useNavigate } from "react-router-dom";
import { CTAButton } from "../../../../Components/CTAButton";
import "./styles.scss";
import {
  AddNewStockService,
  GetAllGroupsForCategoriesService,
  GetStockByIdService,
  GetSubcategoryByCategoryId,
} from "../../../../Services/StockServices";
import Swal from "sweetalert2";
import {
  GetAllCategoriesService,
  GetAllCountriesService,
  GetAllGreatBritainList,
  GetAllSubjectsList,
  GetAllWIListService,
} from "../../../../Services/CommonServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imageCompression from "browser-image-compression";
import { SyncLoader } from "react-spinners";
import { css } from "@emotion/react";
import { StockDetails } from "./StockDetails";
import { AddSimilarItem } from "../AddSimilarListing";

export const AddNewItem = () => {
  const navigate = useNavigate();
  // useEffect
  useEffect(() => {
    getAllCountriesList();
    getAllSubjectsList();
    getAllWIList();
    getAllGreatBritainList();
    getAllCategories();
  }, []);

  const delImgInpRef = useRef();
  const [categoriesList, setCategoriesList] = useState([]);
  const [imagesArr, setImageArr] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [sysid, setSysId] = useState("");
  const [stockDatatoShow, setStockDatatoShow] = useState([]);
  const [showData, setShowData] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [itemForm, setItemForm] = useState({
    author: "",
    author2: "",
    title: "",
    longtitle: "",
    publisher: "",
    pubdate: "",
    description: "",
    footnote: "",
    price: "",
    keywords: "",
    category: "",
    group: {},
    subcategory: {},
    quantity: "",
    location: "",
    vendor: "",
    isFeatured: "",
    conutry: {},
    subject: {},
    wIList: {},
    gblist: {},
    stockref: [],
  });
  const [error, setError] = useState({
    author: false,
    author2: false,
    title: false,
    longtitle: false,
    publisher: false,
    pubdate: false,
    description: false,
    footnote: false,
    price: false,
    category: false,
    keywords: false,
    quantity: false,
    location: false,
    vendor: false,
    stockref: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    author: "",
    author2: "",
    title: "",
    longtitle: "",
    publisher: "",
    pubdate: "",
    description: "",
    footnote: "",
    price: "",
    category: "",
    keywords: "",
    quantity: "",
    location: "",
    vendor: "",
    stockref: "",
  });

  const handleChange = (e) => {
    let { name, value, file } = e.target;

    if (name === "author") {
      setError({
        ...error,
        author: false,
      });
      setItemForm({
        ...itemForm,
        author: value,
      });
    }
    if (name === "author2") {
      setError({
        ...error,
        author2: false,
      });
      setItemForm({
        ...itemForm,
        author2: value,
      });
    }
    if (name === "title") {
      setError({
        ...error,
        title: false,
      });
      setItemForm({
        ...itemForm,
        title: value,
      });
    }
    if (name === "longtitle") {
      setError({
        ...error,
        longtitle: false,
      });
      setItemForm({
        ...itemForm,
        longtitle: value,
      });
    }
    if (name === "publisher") {
      setError({
        ...error,
        publisher: false,
      });
      setItemForm({
        ...itemForm,
        publisher: value,
      });
    }
    if (name === "pubdate") {
      const dateObject = new Date(value);
      const year = dateObject.getFullYear();
      setError({
        ...error,
        pubdate: false,
      });
      setItemForm({
        ...itemForm,
        pubdate: year,
      });
    }
    if (name === "description") {
      setError({
        ...error,
        description: false,
      });
      setItemForm({
        ...itemForm,
        description: value,
      });
    }
    if (name === "footnote") {
      setError({
        ...error,
        footnote: false,
      });
      setItemForm({
        ...itemForm,
        footnote: value,
      });
    }
    if (name === "price") {
      if (/^\d*\.?\d+$/.test(value) || value === "") {
        // setInputValue(value);
        setError({
          ...error,
          price: false,
        });
        setItemForm({
          ...itemForm,
          price: value,
        });
      }
    }

    if (name === "category") {
      setSelectedSubCategories([]);
      setError({
        ...error,
        category: false,
      });
      setItemForm({
        ...itemForm,
        category: value,
      });
      getAllGroupByCategoryId(value);
    }

    if (name === "keywords") {
      setError({
        ...error,
        keywords: false,
      });

      setItemForm({
        ...itemForm,
        keywords: value,
      });
    }

    if (name === "quantity") {
      if (/^\d*\.?\d+$/.test(value) || value === "") {
        setError({
          ...error,
          quantity: false,
        });
        setItemForm({
          ...itemForm,
          quantity: value,
        });
      }
    }
    if (name === "location") {
      setError({
        ...error,
        location: false,
      });
      setItemForm({
        ...itemForm,
        location: value,
      });
    }
    if (name === "vendor") {
      setError({
        ...error,
        vendor: false,
      });
      setItemForm({
        ...itemForm,
        vendor: value,
      });
    }
  };

  // !!!!!!!!!!!!!!!! show loading for uploading the image!!!!!!!!!!!!!!!!!!
  const showLoading = () => {
    Swal.fire({
      title: "Adding Image...",
      allowOutsideClick: false, // Prevent closing the dialog by clicking outside
      showConfirmButton: false, // Hide the confirm button
      showCancelButton: false, // Hide the cancel button
      onOpen: () => {
        Swal.showLoading();
      },
      html: ` <div class="loader-container">
      <div class="loader"></div>
    </div>`,
    });
  };

  const showLoaded = () => {
    Swal.fire({
      title: "Image added",
      icon: "success",
      // html: // You can use different icons or customize as needed
    });
  };

  const handleStockImage = async (e) => {
    setError({
      ...error,
      stockref: false,
    });

    const imageFile = e.target.files[0];

    // Check if any image is selected
    if (!imageFile) {
      return;
    }

    showLoading();
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const imageObj = {
        file: compressedFile,
      };
      imagesArr.push(imageObj);
      displayImages();
      showLoaded();
    } catch (error) {
      // console.log(error);
    }
  };

  function displayImages() {

    const imageContainer = document.getElementById("imageContainer");
    // Clear the existing content in the container
    imageContainer.innerHTML = "";

    // Loop through the imagesArr and create a container div for each image
    for (let i = 0; i < imagesArr.length; i++) {
      const imageObj = imagesArr[i];
      const imageDiv = document.createElement("div");
      imageDiv.classList.add("col-md-2");
      const _imageDiv = document.createElement("div");
      // Create img element for the image
      const imgElement = document.createElement("img");
      imgElement.src = URL.createObjectURL(imageObj.file);
      imgElement.classList.add("image-class");

      const deleteButton = document.createElement("button");
      deleteButton.textContent = "Delete";
      deleteButton.classList.add("delete-img-button");
      deleteButton.onclick = function () {
        deleteImage(i);
      };

      const imageName = document.createElement("div");
      imageName.textContent = imageObj.file.name;
      imageName.classList.add("image-name");

      // Add img, text, and delete button elements to the container div
      _imageDiv.appendChild(imgElement);
      _imageDiv.appendChild(imageName);
      _imageDiv.appendChild(deleteButton);
      imageDiv.appendChild(_imageDiv);

      imageContainer.appendChild(imageDiv);
    }
  }

  function deleteImage(index) {
    // to clear the inp field value
    delImgInpRef.current.value = "";
    imagesArr.splice(index, 1);
    displayImages();
  }

  const getAllGroupByCategoryId = async (data) => {
    let res = await GetAllGroupsForCategoriesService(data);

    if (res?.status === 200 || res?.status === 201) {
      setAllGroups(res?.data?.data);
    } else {
      // console.log("error getting all the gruops", res);
    }
  };




  // const handleSubcategory = (item) => (selecedOption) => {
  //   console.log("🚀 ~ file: index.js:471 ~ handleSubcategory ~ item:", item, selecedOption);


  //   let isPropertyExist = Object.hasOwn(selectedSubCategories, item);

  //   if (!isPropertyExist) {
  //     let temp = { ...selectedSubCategories };
  //     temp[item] = [selecedOption];
  //     setSelectedSubCategories({ ...temp });
  //   }
  //   // if property exist
  //   else {
  //     let t = { ...selectedSubCategories };
  //     // get the values of that property
  //     let values = t[item];
  //     // if value exist it will return true, else false
  //     let isValueExist = values.includes(selecedOption);
  //     // if not there add to that test1 array
  //     if (!isValueExist) {
  //       values.push(selecedOption);
  //     }
  //     // if already exist remove from that array
  //     else {
  //       // find the index
  //       let findedIndex = values.findIndex(
  //         (findVal) => findVal == selecedOption
  //       );
  //       values.splice(findedIndex, 1);
  //     }
  //     t[item] = values;

  //     setSelectedSubCategories({ ...t });
  //   }
  // };

  const handleSubcategory = (e) => {
    if (e.target.checked) {
      let xyz = [...selectedSubCategories]
      xyz.push(e.target.value);
      setSelectedSubCategories(xyz)
    } else {
      let _item = selectedSubCategories.filter((item) => item !== e.target.value);
      setSelectedSubCategories(_item);
    }
  };


  const gobacktoMain = () => {
    navigate(`/${RouteStrings.stockManagement}`);
  };

  const getAllCountriesList = async () => {
    let res = await GetAllCountriesService();
    const options = res?.data?.data.map((item) => ({
      label: item?.name,
      value: item?.keywords,
    }));
  };

  const getAllSubjectsList = async () => {
    let res = await GetAllSubjectsList();
    const options = res?.data?.data?.map((item) => ({
      label: item?.name,
      value: item?.keywords,
    }));
  };

  const getAllWIList = async () => {
    let res = await GetAllWIListService();

    const options = res?.data?.data?.map((item) => ({
      label: item?.name,
      value: item?.keywords,
    }));
  };

  const getAllGreatBritainList = async () => {
    let res = await GetAllGreatBritainList();

    const options = res?.data?.data?.map((item) => ({
      label: item?.name,
      value: item?.keywords,
    }));
  };

  const getAllCategories = async () => {
    let res = await GetAllCategoriesService();
    setCategoriesList(res?.data?.data);
  };

  const handleKeyPress = (e) => {
    const input = e.key;

    // Define a regular expression pattern to match allowed characters
    const allowedPattern = /^[0-9\b]+$/;

    // Test if the input matches the allowed pattern, excluding backspace
    if (
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      !allowedPattern.test(input)
    ) {
      e.preventDefault();
    }
  };

  const handleSelectYear = (date) => {
    setError({
      ...error,
      pubdate: false,
    });
    setStartDate(date);
    let _year = date.getFullYear();
    setItemForm({
      ...itemForm,
      pubdate: _year,
    });
  };

  const getStockDataById = async (id) => {
    let res = await GetStockByIdService(id);

    if (res?.status === 200 || res?.status === 201) {
      setStockDatatoShow(res?.data?.data);
    } else {
      // console.log("error getting created stock data--------------->");
    }
  };

  const [showAddSimilar, setShowAddSimilar] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(true);

  const showAddSimilarDiv = () => {
    setShowData(false);
    setShowAddSimilar(true);
    setShowAddItemForm(false);
  };

  const hideAddSimilarDiv = () => {
    setShowData(true);
    setShowAddSimilar(false);
    setShowAddItemForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      itemForm.author === "" &&
      itemForm.title === "" &&
      itemForm.description === "" &&
      itemForm.price === "" &&
      itemForm.category === "" &&
      itemForm.quantity === ""
    ) {
      setError({
        ...error,
        author: true,
        title: true,
        description: true,
        price: true,
        category: true,
        quantity: true,
      });
      setErrorMsg({
        author: "Please enter the author",
        title: "Please enter the title",
        description: "Please enter the description",
        price: "Please enter the price",
        category: "Please enter the category",
        quantity: "Please enter the quantity",
      });
    } else if (itemForm.author === "") {
      setError({
        ...error,
        author: true,
      });
      setErrorMsg({
        author: "Please enter the author",
      });
    } else if (itemForm.title === "") {
      setError({
        ...error,
        title: true,
      });
      setErrorMsg({
        title: "Please enter the title",
      });
    } else if (itemForm.description === "") {
      setError({
        ...error,
        description: true,
      });
      setErrorMsg({
        description: "Please enter the description",
      });
    } else if (itemForm.price === "") {
      setError({
        ...error,
        price: true,
      });
      setErrorMsg({
        price: "Please enter the price",
      });
    } else if (itemForm.price <= 0) {
      setError({
        ...error,
        price: true,
      });
      setErrorMsg({
        price: "Price cannot be less than 0",
      });
    } else if (itemForm.category === "") {
      setError({
        ...error,
        category: true,
      });
      setErrorMsg({
        category: "Please enter the category",
      });
    } else if (itemForm.quantity === "") {
      setError({
        ...error,
        quantity: true,
      });
      setErrorMsg({
        quantity: "Please enter the quantity",
      });
    } else if (itemForm.quantity <= 0) {
      setError({
        ...error,
        quantity: true,
      });
      setErrorMsg({
        quantity: "Quantity cannot be less than 0",
      });
    } else if (imagesArr.length === 0) {
      setError({
        ...error,
        stockref: true,
      });
      setErrorMsg({
        stockref: "Please enter the image",
      });
    } else {
      let keywordsArr = [];

      // let values = Object.values(selectedSubCategories);

      // let arr = values?.map((item) => {
      //   keywordsArr.push(item);
      //   item?.map((subitem) => {
      //     keywordsArr.push(subitem);
      //   });
      // });


      // // let arr_ = keywordsArr.join(" ").replace(/,/g, " ");

      // let arr_ = keywordsArr
      //   .map(keyword => keyword.includes("&") ? keyword.replace(/\s&\s/g, "&") : keyword)
      //   .join(" ")
      //   .replace(/,/g, " ");


      let arr_ = selectedSubCategories.join(",");
      // console.log("🚀 ~ handleSubmit ~ arr_:", arr_)



      const formData = new FormData();
      formData.append("author", itemForm.author);
      formData.append("author2", itemForm.author2);
      formData.append("title", itemForm.title);
      formData.append("longtitle", itemForm.longtitle);
      formData.append("publisher", itemForm.publisher);
      formData.append("pubdate", itemForm.pubdate);
      formData.append("description", itemForm.description);
      formData.append("footnote", itemForm.footnote);
      formData.append("price", itemForm.price);
      formData.append("keywords", arr_);
      formData.append("category", itemForm.category);
      formData.append("quantity", itemForm.quantity);
      formData.append("vendor", itemForm.vendor);
      formData.append("location", itemForm.location);

      imagesArr.forEach((item, index) => {
        formData.append("stockref[]", item.file);
      });

      let res = await AddNewStockService(formData);
      if (res?.status === 200 || res?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Stock added successfully!`,
        });

        setSysId(res?.data?.sysid);
        setShowData(true);
        setShowAddItemForm(false);
        getStockDataById(res?.data?.sysid);
        // navigate(RouteStrings.stockManagement);
      } else if (
        res?.response?.status === 400 ||
        res?.response?.status === 404 ||
        res?.response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.statusText}`,
        });
        // console.log("error", res);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.statusText}`,
        });
        // console.log("error", res);
      }
    }
  };

  const gobacktodiv = () => {
    setShowData(true);
    setShowAddSimilar(false);
    setShowAddItemForm(false);
  };

  const setNewSysId = (id) => {
    setSysId(id);
  };

  return (
    <div>
      <div>
        <BreadCrumb
          routename="Add New Item"
          gotoBackFun={gobacktoMain}
          isBack={true}
        />
      </div>

      {showData && (
        <>
          {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! show the stock detials div !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
          <div className="card p-2">
            <StockDetails sysid={sysid} getStockDataById={getStockDataById} />
          </div>
          <div className="my-2">
            <button
              id="close"
              className="btn btn-danger rounded-0"
              onClick={() => {
                navigate(`/${RouteStrings.stockManagement}`);
              }}
            >
              Close
            </button>
            <button
              id="addsimilaritem"
              className="btn btn-info rounded-0 mx-2"
              onClick={showAddSimilarDiv}
            >
              Add Similar Item
            </button>
          </div>
        </>
      )}

      {showAddSimilar && (
        <>
          <div className="p-2">
            {/* // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  show the add similar item component !!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

            <div>
              <AddSimilarItem
                isAddSimilar="addSimilar"
                data={stockDatatoShow}
                hideAddSimilarDiv={hideAddSimilarDiv}
                gobacktodiv={gobacktodiv}
                setNewSysId={setNewSysId}
              />
            </div>
          </div>
        </>
      )}

      {showAddItemForm && (
        <>
          <div className="card p-3">
            <div className="addnewitemdiv">
              <form className="newItemForm" onSubmit={handleSubmit}>
                <div className="item_">
                  <label htmlFor="">
                    Author <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="author"
                    onChange={handleChange}
                    placeholder="Enter author name"
                    className="input_"
                  />
                  {error.author && (
                    <>
                      <span className="text-danger">{errorMsg.author}</span>
                    </>
                  )}
                </div>

                <div className="item_">
                  <label htmlFor="">Author 2</label>
                  <input
                    type="text"
                    name="author2"
                    onChange={handleChange}
                    placeholder="Enter author 2 name"
                    className="input_"
                  />
                  {error.author2 && (
                    <>
                      <span className="text-danger">{errorMsg.author2}</span>
                    </>
                  )}
                </div>

                <div className="item_">
                  <label htmlFor="">
                    Title <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter title"
                    className="input_"
                    name="title"
                    onChange={handleChange}
                  />
                  {error.title && (
                    <>
                      <span className="text-danger">{errorMsg.title}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Longtitle</label>
                  <input
                    type="text"
                    name="longtitle"
                    onChange={handleChange}
                    placeholder="Enter longtitle"
                    className="input_"
                  />
                  {error.longtitle && (
                    <>
                      <span className="text-danger">{errorMsg.longtitle}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Publisher</label>
                  <input
                    type="text"
                    placeholder="Enter publisher name"
                    name="publisher"
                    onChange={handleChange}
                    className="input_"
                  />
                  {error.publisher && (
                    <>
                      <span className="text-danger">{errorMsg.publisher}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Pubdate</label>

                  <DatePicker
                    selected={startDate}
                    name="date"
                    onChange={(date) => handleSelectYear(date)}
                    className="input_"
                    dateFormat="yyyy"
                    placeholderText="Select Year"
                    showYearPicker
                    yearDropdownItemNumber={10}
                  />

                  {error.pubdate && (
                    <>
                      <span className="text-danger">{errorMsg.pubdate}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">
                    Description <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="description"
                    onChange={handleChange}
                    placeholder="Enter description"
                    className="input_"
                  />
                  {error.description && (
                    <>
                      <span className="text-danger">
                        {errorMsg.description}
                      </span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Footnote</label>
                  <input
                    type="text"
                    name="footnote"
                    onChange={handleChange}
                    placeholder="Enter footnote"
                    className="input_"
                  />
                  {error.footnote && (
                    <>
                      <span className="text-danger">{errorMsg.footnote}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">
                    Price <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter price"
                    className="input_"
                    min="0"
                    name="price"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                  {error.price && (
                    <>
                      <span className="text-danger">{errorMsg.price}</span>
                    </>
                  )}
                </div>

                <div className="my-2">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">
                      Select a category from the list below
                      <span className="_imp">*</span>
                    </label>
                    <select
                      class="form-control input_"
                      id="exampleFormControlSelect1"
                      name="category"
                      onChange={handleChange}
                    >
                      <option selected disabled>
                        Select Category
                      </option>
                      {categoriesList?.map((item) => {
                        return (
                          <>
                            <option
                              key={item?.reference}
                              value={item?.reference}
                            >
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>

                    {error.category && (
                      <>
                        <span className="text-danger">{errorMsg.category}</span>
                      </>
                    )}
                  </div>
                </div>

                {allGroups
                  .map((mapVal) => Object.keys(mapVal))
                  .flat(1)
                  .map((item) => {
                    return (
                      <>
                        <div className="my-2">
                          <p
                            onClick={(e) => {
                              e.preventDefault();
                              // showHideSubcategories(item);
                            }}
                          >
                            <strong> {item}</strong>
                          </p>
                        </div>

                        {
                          <div className="w-75">
                            {Object.values(
                              allGroups?.find((findVal) => findVal[item])
                            )[0].map((mapVal) => (
                              <label
                                key={mapVal.keywords}
                                className="mr-2 my-2"
                              >
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  value={mapVal.reference}
                                  className="mx-1"
                                  checked={
                                    selectedSubCategories.includes(mapVal.reference)
                                  }
                                  onChange={(e) => {
                                    // const selectedOption = e.target.value;
                                    // handleSubcategory(item)(selectedOption);
                                    handleSubcategory(e);
                                  }}
                                />
                                {mapVal.name}
                              </label>
                            ))}
                          </div>
                        }
                      </>
                    );
                  })}
                <div className="item_">
                  <label htmlFor="">
                    Quantity <span className="_imp">*</span>
                  </label>
                  <input
                    type="text"
                    name="quantity"
                    min="0"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Enter quatity"
                    className="input_"
                  />
                  {error.quantity && (
                    <>
                      <span className="text-danger">{errorMsg.quantity}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Location</label>
                  <input
                    type="text"
                    name="location"
                    onChange={handleChange}
                    placeholder="Enter location"
                    className="input_"
                  />
                  {error.location && (
                    <>
                      <span className="text-danger">{errorMsg.location}</span>
                    </>
                  )}
                </div>
                <div className="item_">
                  <label htmlFor="">Vendor</label>
                  <input
                    type="text"
                    name="vendor"
                    onChange={handleChange}
                    placeholder="Enter vendor"
                    className="input_"
                  />
                  {error.vendor && (
                    <>
                      <span className="text-danger">{errorMsg.vendor}</span>
                    </>
                  )}
                </div>

                <div className="addImagediv d-flex flex-column">
                  <label htmlFor="">
                    Add Image <span className="_imp">*</span>
                  </label>

                  <div
                    id="imageContainer"
                    className="imgcontainer row my-3"
                  ></div>

                  <input
                    type="file"
                    name="stockref"
                    id="stockref"
                    ref={delImgInpRef}
                    onChange={handleStockImage}
                    placeholder="Select Image"
                    className="input_image"
                  />
                  {error.stockref && (
                    <>
                      <span className="text-danger">{errorMsg.stockref}</span>
                    </>
                  )}
                </div>

                <div className="submitbtn my-2">
                  <CTAButton name="Submit" type="submit" id="submitbtn" />
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Loader = () => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  return (
    <>
      <div className="loader-container">
        <SyncLoader color={"#36D7B7"} loading={true} css={override} size={10} />
      </div>
    </>
  );
};
