import React from "react";
import "./styles.scss";

export const AdminData = ({ adminData }) => {
  return (
    <>
      <div className="adminDataDiv">
        <div
          className="header"
          dangerouslySetInnerHTML={{ __html: adminData[0]?.header }}
        ></div>
        <div className="aboutPennymead mt-3">
          <h5>About Pennymead</h5>
          <div className="row">
            <div className="col-md-8">
              <div
                dangerouslySetInnerHTML={{
                  __html: adminData[0]?.about_pennymead,
                }}
              ></div>
            </div>
            <div className="col-md-4">
              <img src={adminData[0]?.about_image} alt="" className="amdinImg" />
            </div>
          </div>
        </div>
        <div className="aboutAdmin my-3">
          <div className="row">
            <div className="col-md-8">
              {/* <p>About Admin</p> */}
              <div
                dangerouslySetInnerHTML={{ __html: adminData[0]?.admin_info }}
              ></div>

              {/* about admin */}
              <div
                className="aboutAdmindiv"
                dangerouslySetInnerHTML={{ __html: adminData[0]?.about_admin }}
              ></div>
            </div>
            <div className="col-md-4">
              {/* <div
                dangerouslySetInnerHTML={{ __html: adminData[0]?.about_image }}
              ></div> */}

              <div className="adminImage">
                <img
                  src={adminData[0]?.admin_image}
                  alt=""
                  className="amdinImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};
