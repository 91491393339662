import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import {
  UpdatePostageServices,
} from "../../../Services/PostageManagementServices";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const PostageManagement = () => {
  const [allcategories, setAllCategories] = useState([]);

  const { getRequest } = ApiResponseHook();

  const [postageData, setPostageData] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const test = useRef([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const [postageInputs, setPostageInputs] = useState({
    ukfirst: "",
    uksub: "",
    eufirst: "",
    eusub: "",
    restfirst: "",
    restsub: "",
    name: "default",
  });

  const getAllCategories = async () => {
    // let res = await GetAllCategoriesService();
    let res = await getRequest(configURL.get_all_categories_master);

    setAllCategories(res?.data?.data);
    test.current = res?.data?.data || [];
    await getPostageDetails(test.current);
  };

  const handleChange = (e) => {
    // console.log("the number is", e.target.value);

    let { name, value } = e.target;

    if (name === "inlandFS") {
      setPostageInputs({
        ...postageInputs,
        ukfirst: value,
      });
    }
    if (name === "inlandSI") {
      setPostageInputs({
        ...postageInputs,
        uksub: value,
      });
    }
    if (name === "europeFS") {
      setPostageInputs({
        ...postageInputs,
        eufirst: value,
      });
    }
    if (name === "europeSI") {
      setPostageInputs({
        ...postageInputs,
        eusub: value,
      });
    }
    if (name === "restFS") {
      setPostageInputs({
        ...postageInputs,
        restfirst: value,
      });
    }
    if (name === "restSI") {
      setPostageInputs({
        ...postageInputs,
        restsub: value,
      });
    }
  };

  const handleCategory = (data, e) => {
    let checked = e.target.checked;
    let ref = data?.reference;
    let temp = [...allcategories];
    const findIndex = temp.findIndex((item) => {
      let { rest } = item;
      if (item.reference === ref) {
        return { ...rest };
      }
    });
    // if index ! = -1
    if (findIndex != -1) {
      let modifyData = temp.at(findIndex);
      modifyData.isChecked = checked;
      temp.splice(findIndex, 1, modifyData);
    }

    setAllCategories(temp);
  };

  const getPostageDetails = async (te) => {
    try {
      // let res = await GetAllPostageDetailsService();

      let res = await getRequest(configURL.get_all_postage_details);

      if (res?.status === 200 || res?.status === 201) {
        setPostageData(res?.data?.data);
        setDefaultCategories(res?.data?.data[0]?.categories);
        setPostageInputs({
          ...postageInputs,
          ukfirst: res?.data?.data[0]?.ukfst,
          uksub: res?.data?.data[0]?.ukrest,
          eufirst: res?.data?.data[0]?.eufst,
          eusub: res?.data?.data[0]?.eurest,
          restfirst: res?.data?.data[0]?.worldfst,
          restsub: res?.data?.data[0]?.worldrst,
        });

        // get data
        let checkData = res?.data?.data[0]?.categories || [];

        let mappedData = te.map((mapVal) => {
          // destructure mapVal
          const { reference, ...rest_data } = mapVal;
          // find categeory id with allCategories
          let findData = checkData.find(
            (findVal) => findVal.catid == reference
          );
          // console.log("🚀 ~ file: index.js:96 ~ mappedData ~ findData:", findData)
          // if i have data

          return {
            ...rest_data,
            reference,
            isChecked: findData ? true : false,
          };
        });

        setAllCategories([...mappedData]);
      } else {
        // console.log("error getting the postage details", res);
      }
    } catch (error) {
      // console.log(
      //   "🚀 ~ file: index.js:142 ~ getPostageDetails ~ error:",
      //   error
      // );
    }
  };

  const updatePostage = async () => {
    // console.log("all categories", allcategories);
    let _arr = [];
    let tempCat = allcategories.filter((item) => {
      if (item.isChecked === true) {
        _arr.push(+item.reference);
        return item;
      }
    });

    let payload = {
      categories: _arr,
      ukfirst: postageInputs.ukfirst,
      uksub: postageInputs.uksub,
      eufirst: postageInputs.eufirst,
      eusub: postageInputs.eusub,
      restfirst: postageInputs.restfirst,
      restsub: postageInputs.restsub,
      name: postageInputs.name,
    };

    let res = await UpdatePostageServices(payload);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the postage rates`,
      });
      getAllCategories();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Postage Management" />
      </div>

      <div className="my-3">
        <h6 className="subHeading">
          Select which categories will allow automatic postage calculation :
        </h6>
      </div>

      <div>
        {allcategories.map((item) => {
          return (
            <>
              <div className="checkboxesDiv">
                <div class="form-check d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) => handleCategory(item, e)}
                    checked={item?.isChecked || false}
                    id={`defaultCheck_${item.reference}`}
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    {item?.name}
                  </label>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div className="ratesDiv mt-3">
        <h6 className="subHeading">Your current postage rates are below : </h6>
      </div>

      <div className="row">
        <div className="col-md-6">
          {/* <PostageRatesTable /> */}

          <div>
            <table border="1">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>Zone</th>
                  <th>First Item Ordered</th>
                  <th>Subsequent Items Ordered</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Inland UK</td>
                  <td>
                    <input
                      type="number"
                      className="inputTag"
                      name="inlandFS"
                      value={postageInputs?.ukfirst}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      name="inlandSI"
                      type="number"
                      className="inputTag"
                      value={postageInputs?.uksub}
                      onChange={handleChange}
                    />
                  </td>
                  {/* <td>
                    <button className="btn btn-info rounded-0">Edit</button>
                  </td> */}
                </tr>
                <tr>
                  <td>2</td>
                  <td>Europe</td>
                  <td>
                    <input
                      name="europeFS"
                      type="number"
                      className="inputTag"
                      value={postageInputs?.eufirst}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      name="europeSI"
                      type="number"
                      className="inputTag"
                      value={postageInputs?.eusub}
                      onChange={handleChange}
                    />
                  </td>
                  {/* <td>
                    <button className="btn btn-info rounded-0">Edit</button>
                  </td> */}
                </tr>
                <tr>
                  <td>3</td>
                  <td>Rest of World</td>
                  <td>
                    <input
                      name="restFS"
                      type="number"
                      className="inputTag"
                      value={postageInputs?.restfirst}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      name="restSI"
                      type="number"
                      className="inputTag"
                      value={postageInputs?.restsub}
                      onChange={handleChange}
                    />
                  </td>
                  {/* <td> */}
                  {/* <button className="btn btn-info rounded-0">Edit</button> */}
                  {/* </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary rounded-0 mt-3"
        onClick={updatePostage}
      >
        Update
      </button>
    </div>
  );
};
