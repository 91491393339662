import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { Stepper } from "./Stepper";
import "../../../Assets/Styles/common.scss";
import { Images } from "../../../Assets/Images/imageslist";
import "./styles.scss";
import {
  AddGroupToCategoryService,
  AddNewCategory,
  AddNewGroupService,
  AddNewSubCategoriesService,
  DeleteCategoryService,
  DeleteGroupFromCategoryService,
  DeleteSubCategoriesService,
  EditCategoryService,
  EditSubCategoryService,
  GetAllCategoriesService,
  GetAllGroupService,
  GetAllGroupsBasedOnCategories,
  GetAllHiddenSubcategories,
  GetAllListOfGroupsToAddToCategory,
  GetAllSubCategories,
  GetAllSubcategoriesBasedOnGroupCategory,
  HideSubcategoriesToCategoriesService,
  ShowSubcategoriesToCategories,
} from "../../../Services/CategoryServices";
import Swal from "sweetalert2";
import $ from "jquery";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const CategoryManagement = () => {
  const groupNameRef = useRef();
  const groupCategoryRef = useRef();

  const subCategoryNameRef = useRef();
  const subCategoryKeywordRef = useRef();
  const subCategoryGroupRef = useRef();

  const selectEditSubcategoryRef = useRef();
  const editSubcategoryNameRef = useRef();
  const editSubcategoryKeywordsRef = useRef();

  const showHiddenGroupRef = useRef();
  const removeGroupRef = useRef();

  const showSubcategoriesRef = useRef();
  const hideSubcategoriesRef = useRef();

  const { getRequest } = ApiResponseHook();

  // ! ---- states -----
  const [categoryData, setCategoryData] = useState("");

  const [filterData, setFilterData] = useState("");
  const [subcategoryData, setSubCategoryData] = useState("");
  const [dropdownData, setDropdownData] = useState("");

  const [allCategoriesList, setAllCategoriesList] = useState([]);

  const [subCategoriesList, setSubCategoriesList] = useState([]);

  const [groupsList, setGroupsList] = useState([]);

  const [hiddenSubCategoryList, setHiddenSubCategoryList] = useState([]);

  const [categoryid, setCategoryId] = useState("");

  const [categoryDeleteData, setCategoryDeleteData] = useState({});

  const [newGroupName, setNewGroupName] = useState("");

  const [categoryIdForgroup, setCategoryIdForGroup] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  const [show, setShow] = useState(false);

  const [newSubCategoryData, setNewSubCategoryData] = useState({
    subcat_name: "",
    keywords: "",
    group: "",
  });

  // edit subcategory form
  const [editSubCategoryData, setEditSubCategoryData] = useState({
    subcat_name: "",
    keywords: "",
    group: "",
    reference: "",
  });

  const [editSubCategoryError, setEditSubCategoryError] = useState({
    group: "",
    subcatname: "",
    subcatkeywords: "",
  });

  const [editSubCategoryErrorMsg, setErrorSubCategoryErrorMsg] = useState({
    group: "",
    subcatname: "",
    subcatkeywords: "",
  });

  // groups
  const [allGroupsList, setAllGroupsList] = useState([]);

  const [showSubcat, setShowSubcat] = useState("");

  const [groups, setgroups] = useState([]);

  const [allSubCategories, setAllSubCategories] = useState([]);

  // edit category form
  const [editCategoryData, setEditCategoryData] = useState({
    name: "",
    alias: "",
    reference: "",
    static: "",
  });

  // !!!!!!!!!!!!!!!!!!!!!! Errors !!!!!!!!!!!!!!!!!!!!!

  const [newCategoryError, setNewCategoryError] = useState({
    categoryName: false,
    categoryAlias: false,
  });

  const [newCategoryErrorMsg, setNewCategoryErrorMsg] = useState({
    categoryName: "",
    categoryAlias: "",
  });

  const [newGroupError, setNewGroupError] = useState({
    groupName: false,
    groupCategoryId: false,
  });

  const [newGroupErrorMsg, setNewGroupErrorMsg] = useState({
    groupName: "",
    groupCategoryId: "",
  });

  // !!!!!!!!!!!!!! New category form !!!!!!!!!!!!!!!!

  const [newCategoryData, setNewCategoryData] = useState({
    category_name: "",
    category_alias: "",
    static: "",
  });

  // !--- useEffect ---

  useEffect(() => {
    getAllGroups();
    getAllCategoriesList();
    getAllSubCategoriesList();
  }, []);

  const handleClose = () => {
    setNewCategoryData({
      ...newCategoryData,
      category_name: "",
      category_alias: "",
      static: "",
    });
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const getAllGroupsByCategoryId = async (id) => {
    let res = await GetAllGroupsBasedOnCategories(id);

    setAllGroupsList(res?.data?.data);
  };

  //! handle functions
  const handleSelect = (e) => {
    setCategoryId(e.target.value);
    setCategoryData(e.target.options[e.target.selectedIndex].text);

    // call the api to get all the groups for the categories

    getAllGroupsByCategoryId(e.target.value);
  };

  // ---------------------

  // !!! get all the subcategories based on the group/category id

  const getAllSubcategoriesbasedonCategoryId = async (data) => {
    // let res = await GetAllSubcategoriesBasedOnGroupCategory(categoryid);
    let res = await getRequest(
      `${configURL.get_allsubcategories_basedon_group}/${categoryid}/`
    );
    setSubCategoriesList(res?.data?.data);
  };

  const getAllHiddenSubcategories = async (id) => {
    // let res = await GetAllHiddenSubcategories(categoryid, id);
    var base64String = btoa(id);
    let res = await getRequest(
      `${configURL.get_all_hiddensubcategories_lsit}/${categoryid}/${base64String}/`
    );
    setHiddenSubCategoryList(res?.data?.data);
  };

  const getAllGroupsToAddCategory = async (id) => {
    // let res = await GetAllListOfGroupsToAddToCategory(categoryid);
    let res = await getRequest(
      `${configURL.get_all_groups_toadd_category_list}/${id}/`
    );
    setGroupsList(res?.data?.data);
  };

  const handleFilterSelect = (e) => {
    setFilterData(e.target.value);

    getAllSubcategoriesbasedonCategoryId(e.target.value);

    // get all hidden subcategories
    getAllHiddenSubcategories(e.target.value);

    // get all groups to add back to category
    getAllGroupsToAddCategory(categoryid);
  };
  // ---------------------
  const handleSubCategorySelect = (e) => {
    setSubCategoryData(e.target.value);
  };

  // ! subcategories list
  const handleDropDownSelect = (e) => {
    setDropdownData(e.target.value);
  };

  const handleGroupName = (e) => {
    setNewGroupError({
      ...newGroupError,
      groupName: false,
    });
    setNewGroupName(e.target.value);
  };

  const handleCreateNewGroupCategory = (e) => {
    setNewGroupError({
      ...newGroupError,
      groupCategoryId: false,
    });
    setCategoryIdForGroup(e.target.value);
  };

  const handleSelectSubCategory = (e) => {
    setEditSubCategoryError({
      group: false,
    });
    let subCat = allSubCategories?.filter((item) => {
      if (item?.reference === e.target.value) {
        return item;
      }
    });

    setEditSubCategoryData({
      ...editSubCategoryData,
      subcat_name: subCat[0]?.name,
      keywords: subCat[0]?.keywords,
      group: subCat[0]?.thegroup,
      reference: subCat[0]?.reference,
    });
  };

  const [newSubcategoryError, setNewSubcategoryError] = useState({
    subcateName: false,
    subcatekeywords: false,
    group: false,
  });
  const [newSubcategoryErrorMsg, setNewSubcategoryErrorMsg] = useState({
    subcateName: "",
    subcatekeywords: "",
    group: "",
  });

  const handleChangeSubCategory = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        subcateName: false,
      });
      setNewSubCategoryData({
        ...newSubCategoryData,
        subcat_name: value,
      });
    }

    if (name === "keyword") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        subcatekeywords: false,
      });
      setNewSubCategoryData({
        ...newSubCategoryData,
        keywords: value,
      });
    }

    if (name === "group") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        group: false,
      });
      setNewSubCategoryData({
        ...newSubCategoryData,
        group: value,
      });
    }
  };

  // !!!!!!!!!! handler for adding the new category !!!!!!!!!!!!!!!!!

  const handleNewCategory = (e) => {
    let { name, value, checked } = e.target;

    if (name === "name") {
      setNewCategoryError({
        ...newCategoryError,
        categoryName: false,
      });
      setNewCategoryData({
        ...newCategoryData,
        category_name: value,
      });
    }
    if (name === "alias") {
      setNewCategoryError({
        ...newCategoryError,
        categoryAlias: false,
      });
      setNewCategoryData({
        ...newCategoryData,
        category_alias: value,
      });
    }
    if (name === "staticpage") {
      setNewCategoryData({
        ...newCategoryData,
        static: checked,
      });
    }
  };

  // !!!!!!!!!!!!!!!!! fun to add new categories !!!!!!!!!!!

  const handleCreateCategory = async () => {
    let _static = "";

    if (newCategoryData.static === true) {
      _static = 1;
    } else {
      _static = 0;
    }

    let payload = {
      category_name: newCategoryData.category_name,
      category_alias: newCategoryData.category_alias,
      static: _static,
    };

    if (payload.category_name === "" && payload.category_alias === "") {
      setNewCategoryError({
        ...newCategoryError,
        categoryName: true,
        categoryAlias: true,
      });

      setNewCategoryErrorMsg({
        categoryName: "Please enter category name",
        categoryAlias: "Please enter category alias",
      });
    } else if (payload.category_name === "") {
      setNewCategoryError({
        ...newCategoryError,
        categoryName: true,
      });

      setNewCategoryErrorMsg({
        categoryName: "Please enter category name",
      });
    } else if (payload.category_alias === "") {
      setNewCategoryError({
        ...newCategoryError,
        categoryAlias: true,
      });

      setNewCategoryErrorMsg({
        categoryAlias: "Please enter alias",
      });
    } else {
      let res = await AddNewCategory(payload);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Added new category successfully!`,
        });
        getAllCategoriesList();
        handleClose();
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404 ||
        res?.response?.status === 500
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message || res?.response?.data?.error
            }`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  // const handleChangeSubCategoryKeyword

  // !-- all functions --

  const deleteGroup = async () => {
    var base64String = btoa(filterData);
    let res = await DeleteGroupFromCategoryService(categoryid, base64String);

    if (categoryid === "" || filterData === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select the group`,
      });
    }

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Deleted the group successfully!`,
      });
      removeGroupRef.current.value = "";
      setFilterData("");
      document.getElementById("closeDeleteGroupModal").click();
      getAllGroupsByCategoryId(categoryid);
      getAllSubCategoriesList();
      getAllGroups();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const getAllCategoriesList = async () => {
    // let response = await GetAllCategoriesService();
    let response = await getRequest(configURL.get_all_categories);

    // let response = await _getRequest(configURL._get_all_categories)

    if (response.status === 200 || response.status === 201) {
      setAllCategoriesList(response?.data?.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.statusText}`,
      });
    }
  };

  const getAllGroups = async () => {
    let res = await getRequest(configURL.get_all_groups);

    if (res.status === 201 || res.status === 200) {
      setgroups(res?.data?.data);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  // !!!!!!!!!!!!!!! handle Edit category data !!!!!!!!!!!!!!!!
  const handleChange = (event) => {
    const { name, value } = event.target;
    let obj = {};

    if (name === "name") {
      setUpdateCategoryError({
        ...updateCategoryError,
        category_name: false,
      });

      obj[name] = value;
      setEditCategoryData({ ...editCategoryData, ...obj });
    }

    if (name === "alias") {
      setUpdateCategoryError({
        ...updateCategoryError,
        category_alias: false,
      });

      obj[name] = value;
      setEditCategoryData({ ...editCategoryData, ...obj });
    }

    if (name === "static") {
      const newValue = event.target.checked ? "1" : "0"; // If checked, set to "1"; otherwise, set to "0"
      setEditCategoryData({ ...editCategoryData, [name]: newValue });
    }
  };

  const showPerticularCategoryData = async () => {
    let _data = allCategoriesList.filter((item) => {
      if (item?.reference === categoryid) {
        return item;
      }
    });

    setEditCategoryData({
      ...editCategoryData,
      name: _data[0]?.name,
      alias: _data[0]?.number,
      reference: _data[0]?.reference,
      static: _data[0]?.static,
    });
  };

  const [updateCategoryError, setUpdateCategoryError] = useState({
    category_name: false,
    category_alias: false,
  });

  const [updateCategoryErrorMsg, setUpdateCategoryErrorMsg] = useState({
    category_name: "",
    category_alias: "",
  });

  const udpateCategory = async () => {
    let payload = {
      category_name: editCategoryData.name,
      category_alias: editCategoryData.alias,
      static: editCategoryData.static,
      catid: editCategoryData.reference,
    };

    if (payload.category_name === "") {
      setUpdateCategoryError({
        ...updateCategoryError,
        category_name: true,
      });

      setUpdateCategoryErrorMsg({
        category_name: "Please enter the catogory name",
      });
    } else if (payload.category_alias === "") {
      setUpdateCategoryError({
        ...updateCategoryError,
        category_alias: true,
      });
      setUpdateCategoryErrorMsg({
        category_alias: "Please enter the catogory alias",
      });
    } else {
      let res = await EditCategoryService(payload);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated Category Successfully!`,
        });
        getAllCategoriesList();

        document.getElementById("editCategoryIcon").click();
        setCategoryData(editCategoryData?.name);
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleUpdateSubCategoryFun = async () => {
    if (
      editSubCategoryData.subcat_name === "" &&
      editSubCategoryData.keywords === "" &&
      editSubCategoryData.group === ""
    ) {
      setEditSubCategoryError({
        ...editSubCategoryError,
        group: true,
        subcatname: true,
        subcatkeywords: true,
      });

      setErrorSubCategoryErrorMsg({
        group: "Please select the subcategory",
        subcatname: "Please enter the subcategory name",
        subcatkeywords: "Please select keywords",
      });
    } else if (editSubCategoryData.group === "") {
      setEditSubCategoryError({
        group: true,
      });

      setErrorSubCategoryErrorMsg({
        group: "Please enter the group",
      });
    } else if (editSubCategoryData.subcat_name === "") {
      setEditSubCategoryError({
        ...editSubCategoryError,
        subcatname: true,
      });

      setErrorSubCategoryErrorMsg({
        subcatname: "Please enter the subcategory name",
      });
    } else if (editSubCategoryData.keywords === "") {
      setEditSubCategoryError({
        ...editSubCategoryError,
        subcatkeywords: true,
      });

      setErrorSubCategoryErrorMsg({
        subcatkeywords: "Please enter keywords",
      });
    } else {
      let res = await EditSubCategoryService(editSubCategoryData);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the sub category sucessfully!`,
        });
        setEditSubCategoryData({
          ...editSubCategoryData,
          subcat_name: "",
          group: "",
          keywords: "",
          reference: "",
        });

        selectEditSubcategoryRef.current.value = "";
        editSubcategoryNameRef.current.value = "";
        editSubcategoryKeywordsRef.current.value = "";
        getAllSubCategoriesList();
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
      } else if (res.response.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.data?.error || res?.response?.data?.error
            }`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleEditSubCategory = async (event) => {
    const { name, value } = event.target;

    let obj = {};

    if (name === "subcat_name") {
      setEditSubCategoryError({
        ...editSubCategoryError,
        subcatname: false,
      });
    }
    if (name === "keywords") {
      setEditSubCategoryError({
        ...editSubCategoryError,
        subcatkeywords: false,
      });
    }

    if (name === "subcat_name" || name === "keywords") {
      obj[name] = value;
      setEditSubCategoryData({ ...editSubCategoryData, ...obj });
    }
  };

  const handleDelete = async () => {
    let itemtodelete = allCategoriesList.filter((item) => {
      if (item.reference === categoryid) {
        return item;
      }
    });
    setCategoryDeleteData(itemtodelete[0]);
  };

  const deleteCategoryFun = async () => {
    let res = await DeleteCategoryService(categoryDeleteData.reference);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Deleted Category Successfully`,
      });
      document.getElementById("deleteCategoryModal").click();
      getAllCategoriesList();
      setCategoryId("");
      setCategoryData("");
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const addNewGroup = async () => {
    let payload = {
      group_name: newGroupName,
      category_id: categoryIdForgroup,
    };

    if (payload.group_name === "" && payload.category_id === "") {
      // console.log("error");

      setNewGroupError({
        ...newGroupError,
        groupName: true,
        groupCategoryId: true,
      });

      setNewGroupErrorMsg({
        groupName: "Please enter the group name",
        groupCategoryId: "Please select the category",
      });
    } else if (payload.group_name === "") {
      setNewGroupError({
        ...newGroupError,
        groupName: true,
      });

      setNewGroupErrorMsg({
        groupName: "Please enter the group name",
      });
    } else if (payload.category_id === "") {
      setNewGroupError({
        ...newGroupError,
        groupCategoryId: true,
      });

      setNewGroupErrorMsg({
        groupCategoryId: "Please enter the category id",
      });
    } else {
      let res = await AddNewGroupService(payload);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Added New Group`,
        });
        setNewGroupName("");
        setCategoryIdForGroup("");
        groupNameRef.current.value = "";
        groupCategoryRef.current.value = "";
        setCategoryIdForGroup("");
        getAllGroupsByCategoryId(categoryid);
        getAllGroups();
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else if (res.response.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.error}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleAddNewSubCategory = async () => {
    if (
      newSubCategoryData.subcat_name === "" &&
      newSubCategoryData.keywords === "" &&
      newSubCategoryData.group === ""
    ) {
      setNewSubcategoryError({
        ...newSubcategoryError,
        subcateName: true,
        subcatekeywords: true,
        group: true,
      });

      setNewSubcategoryErrorMsg({
        subcateName: "Please enter the name",
        subcatekeywords: "Please enter keywords",
        group: "Please select groups",
      });
    } else if (newSubCategoryData.subcat_name === "") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        subcateName: true,
      });
      setNewSubcategoryErrorMsg({
        subcateName: "Please enter name",
      });
    } else if (newSubCategoryData.keywords === "") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        subcatekeywords: true,
      });
      setNewSubcategoryErrorMsg({
        subcatekeywords: "Please enter keywords",
      });
    } else if (newSubCategoryData.group === "") {
      setNewSubcategoryError({
        ...newSubcategoryError,
        group: true,
      });
      setNewSubcategoryErrorMsg({
        group: "Please select group",
      });
    } else {
      let res = await AddNewSubCategoriesService(newSubCategoryData);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Added new subcategory`,
        });

        setNewSubCategoryData({
          subcat_name: "",
          keywords: "",
          group: "",
        });

        subCategoryNameRef.current.value = "";
        subCategoryKeywordRef.current.value = "";
        subCategoryGroupRef.current.value = "";
        getAllHiddenSubcategories(filterData);
        getAllSubCategoriesList();
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else if (res.response.status === 500) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.error}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const getAllSubCategoriesList = async () => {
    // let res = await GetAllSubCategories();
    let res = await getRequest(configURL.get_all_subCategory);

    if (res?.status === 201 || res?.status === 200) {
      setAllSubCategories(res?.data?.data);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const hideSubcategories = async () => {
    let res = await DeleteSubCategoriesService(dropdownData);
    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Subcategory sucessfully hidden!`,
      });
      getAllSubCategoriesList();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const handleDropDownSelectSubCategories = (e) => {
    setShowSubcat(e.target.value);
  };

  const [hideSubcatRef, setHideSubcatRef] = useState("");

  const handleHideSubcategoryData = (e) => {
    setHideSubcatRef(e.target.value);
  };

  const showSubCategoryfun = async () => {
    let payload = {
      catid: categoryid,
      subcatid: showSubcat,
    };

    if (payload.catid === "" || payload.subcatid === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select subcategory`,
      });
    } else {
      let res = await ShowSubcategoriesToCategories(payload);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Subcategory showed to category`,
        });
        setShowSubcat("");
        showSubcategoriesRef.current.value = "";
        getAllHiddenSubcategories(filterData);
        getAllSubcategoriesbasedonCategoryId(categoryid);
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const hideSubcategoriesFromCategory = async () => {
    let payload = {
      catid: categoryid,
      subcatid: hideSubcatRef,
    };

    if (payload.catid === "" || payload.subcatid === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select subcategory`,
      });
    } else {
      let res = await HideSubcategoriesToCategoriesService(payload);

      if (res.status === 201 || res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Successfully hidden to subcategory!`,
        });

        hideSubcategoriesRef.current.value = "";
        getAllHiddenSubcategories(filterData);
        getAllSubcategoriesbasedonCategoryId(categoryid);
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const [addGrouptoCat, setAddGrouptoCat] = useState("");

  const handleDropDownSelectGroup = (e) => {
    setAddGrouptoCat(e.target.value);
  };

  const addGroupToCategory = async () => {
    var base64string = btoa(addGrouptoCat);
    let res = await AddGroupToCategoryService(categoryid, base64string);

    if (categoryid === "" || addGrouptoCat === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select any group`,
      });
    } else if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Added to Category`,
      });

      // const showHiddenGroupRef = useRef()
      setAddGrouptoCat("");
      showHiddenGroupRef.current.value = "";

      getAllGroupsByCategoryId(categoryid);
      getAllSubCategoriesList();
      getAllGroups();
      // window.location.reload();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault(); // Prevents the input of numbers
    }
  }

  return (
    <>
      <div className="mb-3">
        <BreadCrumb routename="Category Management" />
      </div>

      {/* //! select the main categories */}
      <div className="mainCategoriesDropdown">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div class="form-group w-75">
              <label for="exampleFormControlSelect1">Select Category</label>
              <select
                class="form-control inpCategory"
                onChange={handleSelect}
                id="exampleFormControlSelect1"
              >
                <option selected disabled>
                  Select a category
                </option>

                {allCategoriesList?.map((category) => {
                  return (
                    <>
                      <option
                        key={category?.reference}
                        value={category?.reference}
                      >
                        {category?.name}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-secondary mr-2 rounded-0"
                id="addnewcategorybutton"
                onClick={handleShow}
              >
                Add New Category
              </button>
            </div>
          </div>
        </div>
      </div>

      {categoryData && (
        <>
          <div className="showCategoryDiv">
            <div className="d-flex align-items-center">
              <h5 className="mr-1">{categoryData}</h5>

              <img
                src={Images.editIcon}
                alt=""
                id="editCategoryIcon"
                onClick={() => showPerticularCategoryData()}
                className="icons mr-1"
                data-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              />

              <img
                src={Images.deleteIcon}
                className="icons mr-1"
                onClick={handleDelete}
                data-toggle="modal"
                data-target="#deleteCategoryModal"
              />
            </div>

            {/* //!!!!!!!!!!!!!!!!!!!!!!! Edit subcategory!!!!!!!!!!!!!!!!!!!!!!! */}

            <div className="mt-3 w-50">
              <div class="collapse" id="collapseExample">
                <div class="card card-body ">
                  <div className="editCategory">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="mb-0">
                        Category Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter the category name"
                        className="inpCategory mb-2"
                        onChange={handleChange}
                        value={editCategoryData?.name}
                      />

                      {updateCategoryError.category_name && (
                        <>
                          <p className="mb-0 text-danger">
                            {updateCategoryErrorMsg.category_name}
                          </p>
                        </>
                      )}
                      <label htmlFor="" className="mb-0">
                        Category Alias
                      </label>
                      <input
                        type="text"
                        name="alias"
                        id="alias"
                        placeholder="Enter the category alias"
                        className="inpCategory mb-2"
                        onChange={handleChange}
                        value={editCategoryData?.alias}
                      />
                      {updateCategoryError.category_alias && (
                        <>
                          <p className="mb-0 text-danger">
                            {updateCategoryErrorMsg.category_alias}
                          </p>
                        </>
                      )}
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="static"
                          id="defaultCheck1"
                          onChange={handleChange}
                          checked={
                            editCategoryData.static === "1" ? true : false
                          }
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Static Page?
                        </label>
                      </div>
                      <div className="my-2">
                        <button
                          className="btn btn-primary"
                          id="updatecategorybutton"
                          onClick={udpateCategory}
                        >
                          Update Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //! dropdown for the filter like ocuntries, wi islands,m subject */}

            <div className="row">
              <div className="col-md-8">
                <div className="d-flex align-items-center">
                  <div class="form-group w-50">
                    <label for="exampleFormControlSelect1">Select Group</label>
                    <select
                      class="form-control inpCategory"
                      onChange={handleFilterSelect}
                      ref={removeGroupRef}
                      id="exampleFormControlSelect1"
                    >
                      <option value="" selected disabled>
                        Select a group
                      </option>

                      {allGroupsList?.map((group) => {
                        return (
                          <>
                            <option key={group?.groups} value={group?.groups}>
                              {group?.groups}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>

                  {filterData && (
                    <button
                      className="btn btn-danger rounded-0 mx-2 mt-1"
                      data-toggle="modal"
                      id="removegrouopmodalbutton"
                      data-target="#deleteGroupModal"
                    >
                      Remove Group
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* //! Selecting the data from the filtered dropdown  */}
            {filterData && (
              <>
                <div className="row">
                  <div className="col-md-8">
                    <div className="d-flex align-items-center">
                      <div class="form-group w-50">
                        <label for="exampleFormControlSelect1">
                          Select from subcategories
                        </label>
                        <select
                          class="form-control inpCategory"
                          onChange={handleHideSubcategoryData}
                          ref={hideSubcategoriesRef}
                          id="exampleFormControlSelect1"
                        >
                          <option value="" selected disabled>
                            Select subcategory
                          </option>

                          {subCategoriesList?.map((item) => {
                            return (
                              <>
                                <option
                                  key={item?.keywords}
                                  value={item?.reference}
                                >
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {/* {dropdownData && ( */}
                      <button
                        className="btn btn-danger rounded-0 mx-2 mt-1"
                        onClick={hideSubcategoriesFromCategory}
                        id="hidesubcategorybutton"
                      >
                        Hide
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <h6 className="text-danger">
                      These subcategories are currently not shown in the list
                      above. Select one and click 'Show' to add it.
                    </h6>

                    <div className="d-flex align-items-center mt-2">
                      <div class="form-group w-50">
                        <label for="exampleFormControlSelect1">
                          Select from subcategories
                        </label>
                        <select
                          class="form-control inpCategory"
                          onChange={handleDropDownSelectSubCategories}
                          ref={showSubcategoriesRef}
                          id="exampleFormControlSelect1"
                        >
                          <option value="" selected disabled>
                            Select...
                          </option>

                          {hiddenSubCategoryList?.map((item) => {
                            return (
                              <>
                                <option
                                  key={item?.reference}
                                  value={item?.reference}
                                >
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <button
                        className="btn btn-info rounded-0 mx-2 mt-1"
                        id="showsubcategorybutton"
                        onClick={showSubCategoryfun}
                      >
                        Show
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <h6 className="">Add group to this category</h6>

                    <div className="d-flex align-items-center mt-2">
                      <div class="form-group w-50">
                        <label for="exampleFormControlSelect1">
                          Select from group
                        </label>
                        <select
                          class="form-control inpCategory"
                          ref={showHiddenGroupRef}
                          onChange={handleDropDownSelectGroup}
                          id="exampleFormControlSelect1"
                        >
                          <option value="" selected disabled>
                            Select...
                          </option>

                          {groupsList?.map((item) => {
                            return (
                              <>
                                <option
                                  key={item?.thegroup}
                                  value={item?.thegroup}
                                >
                                  {item?.thegroup}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <button
                        className="btn btn-primary rounded-0 mx-2 mt-1"
                        id="addtocategorybutton"
                        onClick={addGroupToCategory}
                      >
                        Add to category
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* //! Delete modal for the categories */}
          <div
            class="modal fade"
            id="deleteCategoryModal"
            tabindex="-1"
            aria-labelledby="deleteCategoryModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="deleteCategoryModalLabel">
                    Delete Category
                  </h5>
                  <button
                    type="button"
                    class="close"
                    id="deletecategorymodalbutton"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure you want to delete the category?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    id="deleteCategoryModal"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    id="deletecategorybutton"
                    onClick={deleteCategoryFun}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!categoryData && (
        <>
          <h6 className="text-danger">
            Please select any category from the dropdown
          </h6>
        </>
      )}

      {categoryData && (
        <>
          <hr />
          <div className="addEditDiv">
            <div className="row">
              <div className="col-md-4">
                <h6 className="">Create a new group</h6>

                <div className="newGroupForm">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="">Group Name</label>
                    <input
                      type="text"
                      name="groupName"
                      id="groupName"
                      ref={groupNameRef}
                      onChange={handleGroupName}
                      className="inputTag"
                      placeholder="Enter the group name"
                    />
                    {newGroupError.groupName && (
                      <>
                        <p className="mb-0 text-danger">
                          {newGroupErrorMsg.groupName}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div class="form-group w-75">
                      <label for="exampleFormControlSelect1">
                        Add to category
                      </label>
                      <select
                        class="form-control inpCategory"
                        ref={groupCategoryRef}
                        onChange={handleCreateNewGroupCategory}
                        id="exampleFormControlSelect1"
                      >
                        <option value="" selected disabled>
                          Select Category
                        </option>
                        {allCategoriesList?.map((item) => {
                          return (
                            <>
                              <option
                                key={item?.reference}
                                value={item?.reference}
                              >
                                {item?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>

                      {newGroupError.groupCategoryId && (
                        <>
                          <p className="mb-0 text-danger">
                            {newGroupErrorMsg.groupCategoryId}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <button
                    className="btn btn-primary rounded-0"
                    id="addnewgroupbutton"
                    onClick={addNewGroup}
                  >
                    Add
                  </button>
                </div>
              </div>

              {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!   create new subcategories !!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
              <div className="col-md-4">
                <h6 className="">Create a new subcategory</h6>

                <div className="newCategoryDiv">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-0">
                      Subcategory Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      ref={subCategoryNameRef}
                      onChange={handleChangeSubCategory}
                      id="name"
                      placeholder="Enter Subcategory Name"
                      className="inpCategory mb-2"
                    />
                    {newSubcategoryError.subcateName && (
                      <>
                        <p className="mb-0 text-danger">
                          {newSubcategoryErrorMsg.subcateName}
                        </p>
                      </>
                    )}

                    <label htmlFor="" className="mb-0">
                      Keyword
                    </label>
                    <input
                      type="text"
                      name="keyword"
                      id="keyword"
                      onChange={handleChangeSubCategory}
                      ref={subCategoryKeywordRef}
                      placeholder="Enter keyword"
                      className="inpCategory mb-2"
                    />
                    {newSubcategoryError.subcatekeywords && (
                      <>
                        <p className="mb-0 text-danger">
                          {newSubcategoryErrorMsg.subcatekeywords}
                        </p>
                      </>
                    )}
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1" className="mb-0">
                      Add to group
                    </label>
                    <select
                      class="form-control inp mb-2"
                      name="group"
                      ref={subCategoryGroupRef}
                      onChange={handleChangeSubCategory}
                      id="exampleFormControlSelect1"
                    >
                      <option value="" selected disabled>
                        Select group
                      </option>

                      {groups?.map((item) => {
                        return (
                          <>
                            <option key={item?.group} value={item?.group}>
                              {item?.group}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {newSubcategoryError.group && (
                      <>
                        <p className="mb-0 text-danger">
                          {newSubcategoryErrorMsg.group}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="d-flex my-2">
                    <button
                      className="btn btn-primary rounded-0"
                      id="addnewsubcategorybutton"
                      onClick={handleAddNewSubCategory}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Edit subcategories !!!!!!!!!!!!!!!!!!!!!!!!!! */}

              <div className="col-md-4">
                <div className="editCategory">
                  <h6>Edit Subcategory</h6>

                  <div class="form-group w-100">
                    <label for="exampleFormControlSelect1">
                      Select Subcategory
                    </label>
                    <select
                      class="form-control inpCategory"
                      onChange={handleSelectSubCategory}
                      ref={selectEditSubcategoryRef}
                      id="exampleFormControlSelect1"
                    >
                      <option value="" selected disabled>
                        Select Subcategory
                      </option>
                      {allSubCategories?.map((item) => {
                        return (
                          <>
                            <option
                              key={item?.reference}
                              value={item?.reference}
                            >
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>

                    {editSubCategoryError.group && (
                      <>
                        <p className="mb-0 text-danger">
                          {editSubCategoryErrorMsg.group}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="editSubCategoryFormDiv">
                    <div className="d-flex flex-column w-100">
                      <label htmlFor="" className="mb-0">
                        Subcategory Name
                      </label>
                      <input
                        type="text"
                        name="subcat_name"
                        id="subcat_name"
                        ref={editSubcategoryNameRef}
                        placeholder="Enter Subcategory Name"
                        onChange={handleEditSubCategory}
                        value={editSubCategoryData?.subcat_name}
                        className="inpCategory mb-2"
                      />

                      {editSubCategoryError.subcatname && (
                        <>
                          <p className="text-danger mb-0">
                            {editSubCategoryErrorMsg.subcatname}
                          </p>
                        </>
                      )}

                      <label htmlFor="" className="mb-0">
                        Keyword
                      </label>
                      <input
                        type="text"
                        name="keywords"
                        id="keywords"
                        ref={editSubcategoryKeywordsRef}
                        onChange={handleEditSubCategory}
                        value={editSubCategoryData?.keywords}
                        placeholder="Enter keyword"
                        className="inpCategory mb-2"
                      />
                      {editSubCategoryError.subcatkeywords && (
                        <>
                          <p className="text-danger mb-0">
                            {editSubCategoryErrorMsg.subcatkeywords}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="editCategory">
                    <button
                      className="btn btn-info rounded-0 mr-2"
                      id="editsubcategorybutton"
                      onClick={handleUpdateSubCategoryFun}
                    >
                      Edit
                    </button>
                    {/* <button className="btn btn-danger rounded-0">Delete</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* //!Add new category modal */}

      {/* create new category modal */}

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <Modal.Title>
            <h5 class="modal-title" id="newCategoryModalLabel">
              Add new category
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="createCategory">
            <p className="font-weight-bold my-2">Create a new category</p>

            <div className="newCategoryDiv">
              <div className="d-flex flex-column">
                <label htmlFor="" className="mb-0">
                  Category Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleNewCategory}
                  onKeyDown={validateInput}
                  placeholder="Enter Category Name"
                  className="inpCategory mb-2"
                />
                {newCategoryError.categoryName && (
                  <>
                    <p className="text-danger mb-0">
                      {newCategoryErrorMsg.categoryName}
                    </p>
                  </>
                )}

                <label htmlFor="" className="mb-0">
                  Category Alias
                </label>
                <input
                  type="text"
                  name="alias"
                  id="alias"
                  // onKeyDown={validateInput}
                  onChange={handleNewCategory}
                  placeholder="Enter Category Alias"
                  className="inpCategory mb-2"
                />
                {newCategoryError.categoryAlias && (
                  <>
                    <p className="text-danger mb-0">
                      {newCategoryErrorMsg.categoryAlias}
                    </p>
                  </>
                )}
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  onChange={handleNewCategory}
                  name="staticpage"
                  id="defaultCheck1"
                  value="1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Static Page?
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="rounded-0"
            id="closemodalbutton"
            onClick={handleClose}
          >
            Close
          </Button>
          <button
            type="button"
            class="btn btn-primary rounded-0"
            id="addnewcategorymodalbutton"
            onClick={handleCreateCategory}
          >
            Add New Category
          </button>
        </Modal.Footer>
      </Modal>

      {/* delete group modal */}

      <div
        class="modal fade"
        id="deleteGroupModal"
        tabindex="-1"
        aria-labelledby="deleteGroupModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteGroupModalLabel">
                Delete Group
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="deletegroupmodalclose"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete the group?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="closeDeleteGroupModal"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-danger"
                id="deletegroupmodalbutton"
                onClick={deleteGroup}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
