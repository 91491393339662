import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { GetAllDashboardCountService } from "../../../Services/CommonServices";
import { GraphicalSalesAnalysis } from "../OrderManagement/GraphicalSalesAnalysis";

export const Test = () => {
  const [counts, setCounts] = useState({});

  useEffect(() => {
    getallCount();
  }, []);

  const getallCount = async () => {
    let response = await GetAllDashboardCountService();

    if (response?.status === 200 || response?.status === 201) {
      setCounts(response?.data?.data);
    } else {
      console.log("error res-------------->", response);
    }
  };

  return (
    <div>
      <div className="container-fluid my-3">
        <div>
          <BreadCrumb routename="Dashboard" />
        </div>

        <div className="row">
          <div className="col-md-6 ">
            <div className="card p-3">
              <h5 className="mb-2 subHeading">Orders to be invoiced</h5>
              <p className="mb-0">{counts?.invoice_wait}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <h5 className="mb-2 subHeading">Orders awating payment</h5>
              <p className="mb-0">{counts?.payment_wait}</p>
            </div>
          </div>

          <div className="p-2 w-100">
            <GraphicalSalesAnalysis toshowBreadcrumb="false" />
          </div>
        </div>
      </div>
    </div>
  );
};
