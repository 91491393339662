import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
// import Select from "react-select";
import "./styles.scss";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../Assets/Styles/common.scss";
// import { GetAllCategoriesService } from "../../../Services/CommonServices";
// import {
//   GetAllGroupsBasedOnCategories,
//   GetAllSubCategories,
// } from "../../../Services/CategoryServices";
// import {
//   GetAllCategoriesContentService,
//   GetAllSubCategoriesPageService,
// } from "../../../Services/PageManagementServices";
// import { EditForm } from "./editForm";
import { EditFormSubCatgories } from "./EditSubcategory";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const PageManagement = () => {
  useEffect(() => {
    getAllCategories();
  }, []);
  //all categories
  const [allcategories, setAllCategories] = useState([]);

  // all groups
  const [allgroups, setAllGroups] = useState([]);

  // all subcategories
  const [allSubcategories, setAllSubcategories] = useState([]);

  const [selectedCaategory, setSelectedCategory] = useState("");

  const [selectGroup, setSelectGroup] = useState("");

  const [selectSubCategory, setSelectSubCategory] = useState("");

  // edit category data
  const [editCategoryData, setEditCategoryData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const showEditForm = () => {
    setIsEdit(!isEdit);
    // getallcategoriescontent(selectedCaategory);
  };

  const handleSelect = (e) => {
    let { name, value } = e.target;

    if (name === "category") {
      setSelectedCategory(value);
      getAllGroupsOnCategories(value);
      setSelectGroup("")
      setSelectSubCategory("")
    }
  };

  const { getRequest } = ApiResponseHook()


  const getAllCategories = async () => {
    // let res = await GetAllCategoriesService();
    let res = await getRequest(configURL.get_all_categories_master);

    if (res?.status === 200 || res?.status === 201) {
      setAllCategories(res?.data?.data);
    } else {
      // console.log("error getting all the categories", res);
    }
  };

  const getAllGroupsOnCategories = async (id) => {
    // let res = await GetAllGroupsBasedOnCategories(id);


    let res = await getRequest(
      `${configURL.get_all_groups_basedon_categories}/${id}/`
    );

    if (res?.status === 200 || res?.status === 201) {
      setAllGroups(res?.data?.data);
    } else {
      // console.log("error getting all the groups", res);
    }
  };

  const handleGroupSelect = (e) => {
    setSelectGroup(e.target.value);

    getAllSubcategories(e.target.value);
    setSelectSubCategory("")
  };

  const getAllSubcategories = async (id) => {
    // let res = await GetAllSubCategoriesPageService(selectedCaategory, id);
    var base64String = btoa(id);
    let res = await getRequest(
      `${configURL.get_all_subcategories_pm}/${selectedCaategory}/${base64String}/`
    );

    if (res?.status === 200 || res?.status === 201) {
      setAllSubcategories(res?.data?.data);
    } else {
      setAllSubcategories([])
      // console.log("error getting the subcategories", res);
    }
  };

  const handleSubcategory = (e) => {
    setSelectSubCategory(e.target.value);
  };

  return (
    <>
      <div>
        <div>
          <BreadCrumb routename="Page Management" />
        </div>

        <div className="mainPageDiv">
          <h5 className="subHeading">
            Please select the category from the dropdown below
          </h5>
        </div>

        <div className="selectCategoryDropdown my-2">
          <div className="form-group w-50 d-flex">
            <select
              className="form-control inp"
              id="exampleFormControlSelect1"
              name="category"
              onChange={handleSelect}
              value={selectedCaategory}
            >
              <option value={""} selected>
                Please select the category
              </option>

              {allcategories?.map((item) => {
                return (
                  <option key={item?.reference} value={item?.reference}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            {selectedCaategory && (
              <>
                <button
                  className="btn btn-info mx-2 rounded-0"
                  onClick={showEditForm}
                >
                  {!isEdit ? `Edit` : `Cancel`}
                </button>
              </>
            )}
          </div>
        </div>

        {/* to show the form to edit for categories */}

        {isEdit && (
          <>
            {/* <EditForm data={selectedCaategory} /> */}
            <EditFormSubCatgories
              categoryId={selectedCaategory}
              // subcategoryId={selectSubCategory}
              // group={selectGroup}
              isCategory={isEdit}
            />
          </>
        )}

        {!isEdit && selectedCaategory && (
          <>
            <div className="my-2">
              <h5 className="subHeading">Please select the group</h5>
              <div className="my-2 w-50">
                <select
                  className="form-control inp"
                  id="exampleFormControlSelect1"
                  name="category"
                  onChange={handleGroupSelect}
                  value={selectGroup}
                >
                  <option value="" selected>
                    Please select the group
                  </option>

                  {allgroups?.map((item) => {
                    return (
                      <option key={item?.groups} value={item?.groups}>
                        {item?.groups}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
      </div>

      {/* if gorup is selected then show the sub category select  */}

      {!isEdit && selectGroup && (
        <>
          <div>
            <div className="my-2">
              <h5 className="subHeading">Please select the subcategory</h5>
              <div className="my-2 w-50">
                <select
                  className="form-control inp"
                  id="exampleFormControlSelect1"
                  name="category"
                  onChange={handleSubcategory}
                  value={selectSubCategory}
                >
                  <option value="" selected>
                    Please select the subcatgory
                  </option>

                  {allSubcategories?.map((item) => {
                    return (
                      <option key={item?.reference} value={item?.reference}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </>
      )}

      {/* if the subcategory is selected then show the edit form  */}

      <>
        {!isEdit && selectSubCategory && (
          <>
            <EditFormSubCatgories
              categoryId={selectedCaategory}
              subcategoryId={selectSubCategory}
              group={selectGroup}
              isCategory={isEdit}
            />
          </>
        )}
      </>
    </>
  );
};
