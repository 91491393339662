import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./styles.scss";
import {
  AddUpdateContentService,
  GetAdminInfoService,
} from "../../../Services/CMServices";
import { AdminData } from "./AdminData";
import Swal from "sweetalert2";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const AdminInfo = () => {
  const { getRequest, postRequest } = ApiResponseHook();

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAdminImage, setSelectedAdminImage] = useState(null);
  const [adminData, setAdminData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getAdminInfo();
  }, []);

  const [infodata, setinfodata] = useState({
    header: "",
    aboutPennymead: "",
    aboutimg: "",
    aboutAdmin: "",
    admininfo: "",
    adminimg: "",
  });

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
      setinfodata({
        ...infodata,
        aboutimg: selectedFile,
      });
    }
  };

  const handleAdminImageChange = (event) => {
    const selectedAdminFile = event.target.files[0];

    if (selectedAdminFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedAdminImage(e.target.result);
      };

      reader.readAsDataURL(selectedAdminFile);
      setinfodata({
        ...infodata,
        adminimg: selectedAdminFile,
      });
    }
  };

  const getAdminInfo = async () => {
    // let res = await GetAdminInfoService();
    let res = await getRequest(configURL.get_admin_info);
    setinfodata({
      ...infodata,
      header: res?.data?.data[0]?.header,
      aboutPennymead: res?.data?.data[0]?.about_pennymead,
      aboutimg: res?.data?.data[0]?.about_image,
      aboutAdmin: res?.data?.data[0]?.about_admin,
      admininfo: res?.data?.data[0]?.admin_info,
      adminimg: res?.data?.data[0]?.admin_image,
    });

    setAdminData(res?.data?.data);
  };

  const onSubmnitInfo = async () => {
    const infoformData = new FormData();
    infoformData.append("header", infodata.header);
    infoformData.append("about_pennymead", infodata.aboutPennymead);
    infoformData.append("about_image", infodata.aboutimg);
    infoformData.append("admin_info", infodata.admininfo);
    infoformData.append("about_admin", infodata.aboutAdmin);
    infoformData.append("admin_image", infodata.adminimg);

    // let res = await AddUpdateContentService(infoformData);
    let res = await postRequest(configURL.add_update_content, infoformData);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Added Successfully!`,
      });
      getAdminInfo();
      setIsEdit(false);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
    // console.log("🚀 ~ file: index.js:89 ~ onSubmnitInfo ~ res:", res);
  };

  return (
    <div className="container">
      <div>
        <BreadCrumb routename="Admin Info" />
      </div>

      <button className="btn btn-info" onClick={() => setIsEdit(!isEdit)}>
        {isEdit ? `Cancel` : `Edit`}
      </button>

      {!isEdit ? (
        <>
          <AdminData adminData={adminData} />
        </>
      ) : (
        <>
          <div className="my-2">
            <h5 className="subHeading">About Pennymead.com</h5>

            <div className="row">
              <div className="col-md-8">
                <div className="pennymead_header mt-3">
                  <label htmlFor="">Header</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={adminData[0]?.header}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setinfodata({
                        ...infodata,
                        header: data,
                      });
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
                <div className="pennymead_info mt-3">
                  <label htmlFor="">About Pennymead</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={adminData[0]?.about_pennymead}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setinfodata({
                        ...infodata,
                        aboutPennymead: data,
                      });
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mt-3">
                  <label htmlFor="" className="mb-0">
                    Select Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />

                  {selectedImage && (
                    <div className="my-2">
                      {/* <h3>Selected Image:</h3> */}
                      <img
                        src={selectedImage}
                        className="_selectedImage"
                        alt="Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h5 className="subHeading">About Admin</h5>

            <div className="row">
              <div className="col-md-8">
                <div className="pennymead_header mt-3">
                  <label htmlFor="">Name and designation</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={adminData[0]?.admin_info}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setinfodata({
                        ...infodata,
                        admininfo: data,
                      });
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
                <div className="pennymead_info mt-3">
                  <label htmlFor="">About Admin</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={adminData[0]?.about_admin}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setinfodata({
                        ...infodata,
                        aboutAdmin: data,
                      });
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mt-3">
                  <label htmlFor="" className="mb-0">
                    Select Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAdminImageChange}
                  />

                  {selectedAdminImage && (
                    <div className="my-2">
                      {/* <h3>Selected Image:</h3> */}
                      <img
                        src={selectedAdminImage}
                        className="_selectedImage"
                        alt="Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <button className="btn btn-primary mt-2" onClick={onSubmnitInfo}>
            Submit
          </button>
        </>
      )}
    </div>
  );
};
