import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CTAButton } from "../../../Components/CTAButton";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../Routes/RouteStrings";
// import { CustomerTable } from "./CustomerTable";

// import {
//   SearchCustomerService,
//   getAllCustomerslistService,
// } from "../../../Services/CustomerServices";
import ServerSidePaginationTable from "./CustomerTable/ServerSideTable";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";

export const CustomerManagement = () => {
  const navigate = useNavigate();
  const { getRequest } = ApiResponseHook()

  // ---- States ----
  // const [customersData, setCustomersData] = useState(null);

  // const [searchText, setSearchText] = useState("");

  const gotoaddNewCustomer = () => {
    navigate(`/${RouteStrings.addNewCustomer}`);
  };

  useEffect(() => { }, []);

  const fetchCustomersData = async (pageno, noofdata) => {
    // try {
    //   let response = await getAllCustomerslistService(
    //     pageno,
    //     noofdata,
    //     "name",
    //     "asc"
    //   );
    //   console.log(
    //     "🚀 ~ file: index.js:36 ~ fetchCustomersData ~ response:",
    //     response
    //   );

    //   return response;
    // } catch (error) {
    //   return error;
    // }

    let response = await getRequest(`${configURL.get_all_customers}/${pageno}/${noofdata}/name/asc/`)
    return response
  };

  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  // };

  // const searchFuntion = async () => {
  //   console.log("searchText", searchText);
  //   let response = await getRequest(
  //     `${configURL.search_customerData}/name/${searchText}/`
  //   );
  //   // let response = await SearchCustomerService("name", searchText);
  //   setCustomersData(response?.data?.data?.customers);
  // };

  return (
    <div>
      <div>
        <BreadCrumb routename="Customer Management" />
      </div>

      <div className="d-flex justify-content-end">
        <CTAButton name="Add New Customer" handleClick={gotoaddNewCustomer} />
      </div>

      <div className="my-3">
        <ServerSidePaginationTable fetchCustomersData={fetchCustomersData} />
      </div>
    </div>
  );
};
