import React, { useEffect, useState } from "react";
import { GetStockByIdService } from "../../../../Services/StockServices";

export const StockDetails = ({ sysid, getStockDataById }) => {
  const [stockDatatoShow, setStockDatatoShow] = useState([]);

  useEffect(() => {
    getStockDetails();
  }, []);

  const getStockDetails = async () => {
    let res = await GetStockByIdService(sysid);
    // let res = await GetStockByIdService(32301);

    if (res?.status === 200 || res?.status === 201) {
      setStockDatatoShow(res?.data?.data);
    } else {
      // console.log("error getting created stock data--------------->");
    }
  };

  return (
    <div>
      <div className="stockdetailshow">
        <div className="row">
          <div className="col-md-6">
            <div className="fields">
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Author :
                </label>
                <p>{stockDatatoShow[0]?.author || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Author2 :
                </label>
                <p>{stockDatatoShow[0]?.author2 || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Title :
                </label>
                <p>{stockDatatoShow[0]?.title || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Long title :
                </label>
                <p>{stockDatatoShow[0]?.longtitle || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Publisher :
                </label>
                <p>{stockDatatoShow[0]?.publisher || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Pubdate :
                </label>
                <p>{stockDatatoShow[0]?.pubdate || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Description :
                </label>
                <p>{stockDatatoShow[0]?.description || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Footnote :
                </label>
                <p>{stockDatatoShow[0]?.footnote || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Category :
                </label>
                <p>{stockDatatoShow[0]?.category_name || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Quantity :
                </label>
                <p>{stockDatatoShow[0]?.quantity || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Location :
                </label>
                <p>{stockDatatoShow[0]?.location || "--"}</p>
              </div>
              <div className="d-flex align-items-center info__">
                <label htmlFor="" className="">
                  Vendor :
                </label>
                <p>{stockDatatoShow[0]?.vendor || "--"}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="stockimagesdiv w-100 d-flex align-items-center">
              {stockDatatoShow[0]?.images?.map((image) => {
                return (
                  <>
                    <img src={image} alt="" className="image-class-confirm" />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
