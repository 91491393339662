import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import "./styles.scss";
import ServerSideOrdersListComponent from "./OrderTable/ServerSideOrdersList";
import { configURL } from "../../../Services/configuration";
import { ApiResponseHook } from "../../../Services/MainBaseServices";

export const OrderManagement = () => {
  const { getRequest } = ApiResponseHook();

  useEffect(() => { }, []);

  // ------------------------------------------------------- all funcitons -----------------------------------------

  const fetchCustomersData = async (pageno, noofdata) => {
    // let response = await GetAllOrdersService(pageno, noofdata);
    let response = await getRequest(`${configURL.get_all_orders}/${pageno}/`);

    if (response?.status === 200 || response?.status === 201) {
      return response;
    } else {
      // console.log("error getting data", response);
    }
  };

  const fetchPackedAllOrders = async (pageno, noofdata) => {
    // let response = await GetAllOrdersPackedService(pageno);
    let response = await getRequest(
      `${configURL.get_all_packed_orders}/${pageno}/`
    );

    return response;
  };

  const fetchCompletedOrders = async (pageno) => {
    // let response = await GetAllCompletedOrdersService(pageno);
    let response = await getRequest(
      `${configURL.get_all_completedOrders}/${pageno}/`
    );

    return response;
  };

  const getAllOrdersAwatingInvoice = async (pageno) => {
    // let res = await GetAllOrdersAwatingInvoiceService(pageno);
    let res = await getRequest(
      `${configURL.get_all_ordersAwatingInvoice}/${pageno}/`
    );
    return res;
  };
  const getAllOrdersAwatingPayment = async (pageno) => {
    // let res = await GetAllOrdersAwatingPaymentService(pageno);
    let res = await getRequest(
      `${configURL.get_all_ordersAwatingPayment}/${pageno}/`
    );
    return res;
  };

  const getAllPackedOrdersAwatingInvoice = async (pageno) => {
    // let res = await GetAllPackedOrdersAwatingInvoiceService(pageno);
    let res = await getRequest(
      `${configURL.get_packed_orders_awating_invoice}/${pageno}/`
    );
    return res;
  };

  const getAllPackedOrdersAwatingPayment = async (pageno) => {
    // let res = await GetAllPackedOrdersAwatingPaymentService(pageno);
    let res = await getRequest(
      `${configURL.get_packed_orders_awating_payment}/${pageno}/`
    );
    return res;
  };

  const getAllPackedCompletedOrders = async (pageno) => {
    // let res = await GetAllPackedCompletedOrdersService(pageno);
    let res = await getRequest(
      `${configURL.get_packed_completed_orders}/${pageno}/`
    );
    return res;
  };

  const getAllUnpackedPaidOrders = async (pageno) => {
    // let res = await GetAllUnpackedPaidOrdersService(pageno);
    let res = await getRequest(
      `${configURL.get_all_unpacked_paid_orders}/${pageno}/`
    );
    return res;
  };

  const getAllPackedPaidOrders = async (pageno) => {
    // let res = await GetAllPackedPaidOrdersService(pageno);
    let res = await getRequest(
      `${configURL.get_all_packed_paid_orders}/${pageno}/`
    );
    return res;
  };

  return (
    <div>
      <>
        <div>
          <BreadCrumb routename="Order Management" />
        </div>

        <div className="mt-3">
          <ServerSideOrdersListComponent
            fetchCustomersData={fetchCustomersData}
            fetchCompletedOrders={fetchCompletedOrders}
            getAllOrdersAwatingInvoice={getAllOrdersAwatingInvoice}
            getAllOrdersAwatingPayment={getAllOrdersAwatingPayment}
            getAllPackedOrdersAwatingInvoice={getAllPackedOrdersAwatingInvoice}
            getAllPackedOrdersAwatingPayment={getAllPackedOrdersAwatingPayment}
            getAllPackedCompletedOrders={getAllPackedCompletedOrders}
            fetchPackedAllOrders={fetchPackedAllOrders}
            getAllUnpackedPaidOrders={getAllUnpackedPaidOrders}
            getAllPackedPaidOrders={getAllPackedPaidOrders}
          />
        </div>
      </>
    </div>
  );
};
