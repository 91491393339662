import {
  getRequest,
  postRequest,
  putRequest,
  deleteReqest,
  patchRequest,
} from "./MainBaseServices";

import axios from "axios";

import { configURL } from "./configuration";

export const GetAllCountriesService = async () => {
  try {
    let response = await getRequest(configURL.get_all_countries);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:19 ~ GetAllCountriesService ~ error:",
    //   error
    // );

    return error;
  }
};

export const GetAllSubjectsList = async () => {
  try {
    let response = await getRequest(configURL.get_all_subjects);

    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:31 ~ GetAllSubjectsList ~ error:",
    //   error
    // );
  }
};

export const GetAllWIListService = async () => {
  try {
    let response = await getRequest(configURL.get_all_wi_list);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:44 ~ GetAllWIListService ~ error:",
    //   error
    // );
    return error;
  }
};

export const GetAllGreatBritainList = async () => {
  try {
    let response = await getRequest(configURL.get_all_great_britain_list);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:57 ~ GetAllGreatBritainList ~ error:",
    //   error
    // );
    return error;
  }
};

export const GetAllCategoriesService = async () => {
  try {
    let response = await getRequest(configURL.get_all_categories_master);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:70 ~ GetAllCategoriesService ~ error:",
    //   error
    // );
    return error;
  }
};

export const GetAllCustomersListCommonService = async () => {
  try {
    let response = await getRequest(configURL.get_all_customers_master);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:84 ~ GetAllCustomersListCommonService ~ error:",
    //   error
    // );
    return error;
  }
};

export const GetAllDashboardCountService = async () => {
  try {
    let response = await getRequest(configURL.get_all_count);
    return response;
  } catch (error) {
    // console.log(
    //   "🚀 ~ file: CommonServices.js:84 ~ GetAllCustomersListCommonService ~ error:",
    //   error
    // );
    return error;
  }
};
