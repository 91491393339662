import React, { useState, useEffect } from "react";

export const PennymeadContext = React.createContext();

const PennymeadProvider = ({ children }) => {
  const [isPacked, setIsPacked] = useState("unpacked");

  return (
    <PennymeadContext.Provider
      value={{
        isPacked,
        setIsPacked,
      }}
    >
      {children}
    </PennymeadContext.Provider>
  );
};

export { PennymeadProvider };
