import React, { useMemo } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { Images } from "../../../../../Assets/Images/imageslist";
import "./styles.scss";
import { GlobalFilter } from "../../../../../Utils/Table/GlobalFilter";
// import Data from "./data.json";
import "../../../../../Assets/Styles/common.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteStrings } from "../../../../../Routes/RouteStrings";

export const OrdersTable = ({ orders }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Order No",
        accessor: "order_no",
      },
      {
        Header: "Items Ordered",
        accessor: "item_ordered",
      },
      {
        Header: "Items Total",
        accessor: (d) => {
          const tempspend = parseFloat(d.total); // Convert the string to a number
          if (!isNaN(tempspend)) {
            return `£${tempspend.toFixed(2)}`;
          } else {
            return `--`;
          }
        },
      },

      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Cell: (tableProps) => (
      //     <div className="d-flex flex-row align-items-center">
      //       <a
      //         className="mx-1 viewButton"
      //         onClick={() => gotoViewOrder(tableProps.cell.row.original)}
      //       >
      //         View
      //       </a>
      //     </div>
      //   ),
      // },
    ],
    []
  );
  // const data = useMemo(() => props.data);
  // const data = useMemo(() => Data);
  const data = useMemo(() => orders);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;

  const gotoViewOrder = (data) => {
    navigate(`/${RouteStrings.viewOrder}`, { state: { data: data } });
  };

  return (
    <>
      <div className="mt-3 mb-3">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div>
        <table {...getTableProps()} className="mt-3">
          <thead>
            {/* {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))} */}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Images.downarrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        ) : (
                          <img
                            src={Images.uparrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {orders?.length === 0 ? (
            <tbody>
              <tr>No orders</tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                        // onClick={() => gotoOrderDetails(row.original)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="d-md-flex align-items-center mb-3">
          <div className="mt-2 mt-md-0">
            <select
              value={pageSize}
              className="selectTag mb-3"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="" disabled>
                Select
              </option>
              {[5, 10, 15, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="w-100 text-center mt-3 mb-1">
            <button
              className="mx-1 skipToBtn"
              onClick={(e) => {
                e.preventDefault();
                gotoPage(0);
              }}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="mx-1 actionBtn"
              onClick={(e) => {
                e.preventDefault();
                previousPage();
              }}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <span className="mx-2 pageNumber">
              {/* Page{" "} */}
              <strong>
                {pageIndex + 1}
                {/* of {pageOptions.length} */}
              </strong>{" "}
            </span>
            <button
              className="mx-1 actionBtn"
              onClick={(e) => {
                e.preventDefault();
                nextPage();
              }}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="skipToBtn"
              onClick={(e) => {
                e.preventDefault();
                gotoPage(pageCount - 1);
              }}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>

      {/* Delete Modal */}

      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Customer
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete the customer?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
